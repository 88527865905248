
import { computed, defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    path: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      default: 48,
    },
    width: {
      type: Number,
      default: 48,
    },
  },

  setup(props) {
    const avatar = computed(() => props.path || require('~/assets/images/account/no-photo.svg'))

    const sizes = computed(() => ({ width: `${props.width}px`, height: `${props.height}px` }))

    return { sizes, avatar }
  },
})
