import { UserModel } from '.'
import { permissions } from '~/shared/const'
import { hasPermission } from '~/shared/utils/permission'
import { PermissionsEnum } from '~/shared/enums'
import type { PermissionId } from '~/shared/interface'

export default class AuthorizedUser extends UserModel {
  readonly allowedRoutes: Array<RegExp> = []

  constructor(data: unknown) {
    super(data)

    let routes: Array<RegExp> = [/^\/account(\/lessons)?\/?$/]
    Object.keys(permissions).forEach((p) => {
      if (hasPermission(this, PermissionsEnum[p as PermissionId])) {
        routes = routes.concat(permissions[p as PermissionId]!.allowedRoutes)
      }
    })
    this.allowedRoutes = routes
  }
}
