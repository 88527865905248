import { gql } from 'graphql-tag'
import { SubjectMainFields } from '~/entities/subject/gql/fragments'

export default gql`
  ${SubjectMainFields('LearningProgramSubjectType')}
  mutation updateLearningProgramSubject($input: UpdateLearningProgramSubjectInput!) {
    updateLearningProgramSubject(input: $input) {
      ...SubjectMainFields
    }
  }
`
