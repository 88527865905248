import { DetectResult, Parser, Regexes, ParserFunction, ParserResult, Device } from './interface'
export default class DeviceDetect {
  private static regexes: Regexes = {
    browserParsers: [
      {
        regex: '^(Opera)/(\\d+)\\.(\\d+) \\(Nintendo Wii',
        replacement: 'Wii',
        manufacturer: 'Nintendo',
      },
      {
        regex: '(SeaMonkey|Camino)/(\\d+)\\.(\\d+)\\.?([ab]?\\d+[a-z]*)',
        replacement: 'Camino',
        other: true,
      },
      {
        regex: '(Pale[Mm]oon)/(\\d+)\\.(\\d+)\\.?(\\d+)?',
        replacement: 'Pale Moon (Firefox Variant)',
        other: true,
      },
      {
        regex: '(Fennec)/(\\d+)\\.(\\d+)\\.?([ab]?\\d+[a-z]*)',
        replacement: 'Firefox Mobile',
      },
      {
        regex: '(Fennec)/(\\d+)\\.(\\d+)(pre)',
        replacement: 'Firefox Mobile',
      },
      {
        regex: '(Fennec)/(\\d+)\\.(\\d+)',
        replacement: 'Firefox Mobile',
      },
      {
        regex: 'Mobile.*(Firefox)/(\\d+)\\.(\\d+)',
        replacement: 'Firefox Mobile',
      },
      {
        regex: '(Namoroka|Shiretoko|Minefield)/(\\d+)\\.(\\d+)\\.(\\d+(?:pre)?)',
        replacement: 'Firefox ($1)',
      },
      {
        regex: '(Firefox)/(\\d+)\\.(\\d+)(a\\d+[a-z]*)',
        replacement: 'Firefox Alpha',
      },
      {
        regex: '(Firefox)/(\\d+)\\.(\\d+)(b\\d+[a-z]*)',
        replacement: 'Firefox Beta',
      },
      {
        regex: '(Firefox)-(?:\\d+\\.\\d+)?/(\\d+)\\.(\\d+)(a\\d+[a-z]*)',
        replacement: 'Firefox Alpha',
      },
      {
        regex: '(Firefox)-(?:\\d+\\.\\d+)?/(\\d+)\\.(\\d+)(b\\d+[a-z]*)',
        replacement: 'Firefox Beta',
      },
      {
        regex: '(Namoroka|Shiretoko|Minefield)/(\\d+)\\.(\\d+)([ab]\\d+[a-z]*)?',
        replacement: 'Firefox ($1)',
      },
      {
        regex: '(Firefox).*Tablet browser (\\d+)\\.(\\d+)\\.(\\d+)',
        replacement: 'MicroB',
        tablet: true,
      },
      {
        regex: '(MozillaDeveloperPreview)/(\\d+)\\.(\\d+)([ab]\\d+[a-z]*)?',
      },
      {
        regex: '(Flock)/(\\d+)\\.(\\d+)(b\\d+?)',
        replacement: 'Flock',
        other: true,
      },
      {
        regex: '(RockMelt)/(\\d+)\\.(\\d+)\\.(\\d+)',
        replacement: 'Rockmelt',
        other: true,
      },
      {
        regex: '(Navigator)/(\\d+)\\.(\\d+)\\.(\\d+)',
        replacement: 'Netscape',
      },
      {
        regex: '(Navigator)/(\\d+)\\.(\\d+)([ab]\\d+)',
        replacement: 'Netscape',
      },
      {
        regex: '(Netscape6)/(\\d+)\\.(\\d+)\\.(\\d+)',
        replacement: 'Netscape',
      },
      {
        regex: '(MyIBrow)/(\\d+)\\.(\\d+)',
        replacement: 'My Internet Browser',
        other: true,
      },
      {
        regex: '(Opera Tablet).*Version/(\\d+)\\.(\\d+)(?:\\.(\\d+))?',
        replacement: 'Opera Tablet',
        tablet: true,
      },
      {
        regex: '(Opera)/.+Opera Mobi.+Version/(\\d+)\\.(\\d+)',
        replacement: 'Opera Mobile',
      },
      {
        regex: 'Opera Mobi',
        replacement: 'Opera Mobile',
      },
      {
        regex: '(Opera Mini)/(\\d+)\\.(\\d+)',
        replacement: 'Opera Mini',
      },
      {
        regex: '(Opera Mini)/att/(\\d+)\\.(\\d+)',
        replacement: 'Opera Mini',
      },
      {
        regex: '(Opera)/9.80.*Version/(\\d+)\\.(\\d+)(?:\\.(\\d+))?',
        replacement: 'Opera',
      },
      {
        regex: '(OPR)/(\\d+)\\.(\\d+)(?:\\.(\\d+))?',
        replacement: 'Opera',
      },
      {
        regex: '(webOSBrowser)/(\\d+)\\.(\\d+)',
        replacement: 'webOS',
      },
      {
        regex: '(webOS)/(\\d+)\\.(\\d+)',
        replacement: 'webOS',
      },
      {
        regex: '(wOSBrowser).+TouchPad/(\\d+)\\.(\\d+)',
        replacement: 'webOS TouchPad',
      },
      {
        regex: '(luakit)',
        replacement: 'LuaKit',
        other: true,
      },
      {
        regex: '(Lightning)/(\\d+)\\.(\\d+)([ab]?\\d+[a-z]*)',
        replacement: 'Lightning',
        other: true,
      },
      {
        regex: '(Firefox)/(\\d+)\\.(\\d+)\\.(\\d+(?:pre)?) \\(Swiftfox\\)',
        replacement: 'Swiftfox',
        other: true,
      },
      {
        regex: '(Firefox)/(\\d+)\\.(\\d+)([ab]\\d+[a-z]*)? \\(Swiftfox\\)',
        replacement: 'Swiftfox',
        other: true,
      },
      {
        regex: 'rekonq',
        replacement: 'Rekonq',
        other: true,
      },
      {
        regex: '(conkeror|Conkeror)/(\\d+)\\.(\\d+)\\.?(\\d+)?',
        replacement: 'Conkeror',
        other: true,
      },
      {
        regex: '(konqueror)/(\\d+)\\.(\\d+)\\.(\\d+)',
        replacement: 'Konqueror',
        other: true,
      },
      {
        regex: '(WeTab)-Browser',
        replacement: 'WeTab',
        other: true,
      },
      {
        regex: '(Comodo_Dragon)/(\\d+)\\.(\\d+)\\.(\\d+)',
        replacement: 'Comodo Dragon',
        other: true,
      },
      {
        regex: '(YottaaMonitor)',
        replacement: 'Yottaa Monitor',
        other: true,
      },
      {
        regex: '(Kindle)/(\\d+)\\.(\\d+)',
        replacement: 'Kindle',
      },
      {
        regex: '(Symphony) (\\d+).(\\d+)',
        replacement: 'Symphony',
        other: true,
      },
      {
        regex: 'Minimo',
        replacement: 'Minimo',
        other: true,
      },
      {
        regex: '(Edge)/(\\d+)\\.(\\d+)',
        replacement: 'Edge',
      },
      {
        regex: '(CrMo)/(\\d+)\\.(\\d+)\\.(\\d+)\\.(\\d+)',
        replacement: 'Chrome Mobile',
      },
      {
        regex: '(CriOS)/(\\d+)\\.(\\d+)\\.(\\d+)\\.(\\d+)',
        replacement: 'Chrome Mobile iOS',
      },
      {
        regex: '(Chrome)/(\\d+)\\.(\\d+)\\.(\\d+)\\.(\\d+) Mobile',
        replacement: 'Chrome Mobile',
      },
      {
        regex: '(chromeframe)/(\\d+)\\.(\\d+)\\.(\\d+)',
        replacement: 'Chrome Frame',
      },
      {
        regex: '(UC Browser)(\\d+)\\.(\\d+)\\.(\\d+)',
        replacement: 'UC Browser',
        other: true,
      },
      {
        regex: '(SLP Browser)/(\\d+)\\.(\\d+)',
        replacement: 'Tizen Browser',
        other: true,
      },
      {
        regex: '(Epiphany)/(\\d+)\\.(\\d+).(\\d+)',
        replacement: 'Epiphany',
        other: true,
      },
      {
        regex: '(SE 2\\.X) MetaSr (\\d+)\\.(\\d+)',
        replacement: 'Sogou Explorer',
        other: true,
      },
      {
        regex: '(Pingdom.com_bot_version_)(\\d+)\\.(\\d+)',
        replacement: 'PingdomBot',
        other: true,
      },
      {
        regex: '(facebookexternalhit)/(\\d+)\\.(\\d+)',
        replacement: 'FacebookBot',
      },
      {
        regex: '(Twitterbot)/(\\d+)\\.(\\d+)',
        replacement: 'TwitterBot',
      },
      {
        regex:
          '(AdobeAIR|Chromium|FireWeb|Jasmine|ANTGalio|Midori|Fresco|Lobo|PaleMoon|Maxthon|Lynx|OmniWeb|Dillo|Camino|Demeter|Fluid|Fennec|Shiira|Sunrise|Chrome|Flock|Netscape|Lunascape|WebPilot|NetFront|Netfront|Konqueror|SeaMonkey|Kazehakase|Vienna|Iceape|Iceweasel|IceWeasel|Iron|K-Meleon|Sleipnir|Galeon|GranParadiso|Opera Mini|iCab|NetNewsWire|ThunderBrowse|Iron|Iris|UP\\.Browser|Bunjaloo|Google Earth|Raven for Mac)/(\\d+)\\.(\\d+)\\.(\\d+)',
      },
      {
        regex:
          '(Bolt|Jasmine|IceCat|Skyfire|Midori|Maxthon|Lynx|Arora|IBrowse|Dillo|Camino|Shiira|Fennec|Phoenix|Chrome|Flock|Netscape|Lunascape|Epiphany|WebPilot|Opera Mini|Opera|NetFront|Netfront|Konqueror|Googlebot|SeaMonkey|Kazehakase|Vienna|Iceape|Iceweasel|IceWeasel|Iron|K-Meleon|Sleipnir|Galeon|GranParadiso|iCab|NetNewsWire|Iron|Space Bison|Stainless|Orca|Dolfin|BOLT|Minimo|Tizen Browser|Polaris)/(\\d+)\\.(\\d+)',
      },
      {
        regex: '(iRider|Crazy Browser|SkipStone|iCab|Lunascape|Sleipnir|Maemo Browser) (\\d+)\\.(\\d+)\\.(\\d+)',
      },
      {
        regex: '(iCab|Lunascape|Opera|Android|Jasmine|Polaris|BREW) (\\d+)\\.(\\d+)\\.?(\\d+)?',
      },
      {
        regex: '(Android) Donut',
      },
      {
        regex: '(Android) Eclair',
      },
      {
        regex: '(Android) Froyo',
      },
      {
        regex: '(Android) Gingerbread',
      },
      {
        regex: '(Android) Honeycomb',
      },
      {
        regex: '(IEMobile)[ /](\\d+)\\.(\\d+)',
        replacement: 'IE Mobile',
      },
      {
        regex: '(MSIE) (\\d+)\\.(\\d+).*XBLWP7',
        replacement: 'IE Large Screen',
      },
      {
        regex: '(Firefox)/(\\d+)\\.(\\d+)\\.(\\d+)',
      },
      {
        regex: '(Firefox)/(\\d+)\\.(\\d+)(pre|[ab]\\d+[a-z]*)?',
      },
      {
        regex: '(Obigo)InternetBrowser',
        other: true,
      },
      {
        regex: '(Obigo)\\-Browser',
        other: true,
      },
      {
        regex: '(Obigo|OBIGO)[^\\d]*(\\d+)(?:.(\\d+))?',
        other: true,
      },
      {
        regex: '(MAXTHON|Maxthon) (\\d+)\\.(\\d+)',
        replacement: 'Maxthon',
        other: true,
      },
      {
        regex: '(Maxthon|MyIE2|Uzbl|Shiira)',
        other: true,
      },
      {
        regex: '(PLAYSTATION) (\\d+)',
        replacement: 'PlayStation',
        manufacturer: 'Sony',
      },
      {
        regex: '(PlayStation Portable)[^\\d]+(\\d+).(\\d+)',
        manufacturer: 'Sony',
      },
      {
        regex: '(BrowseX) \\((\\d+)\\.(\\d+)\\.(\\d+)',
        other: true,
      },
      {
        regex: '(POLARIS)/(\\d+)\\.(\\d+)',
        replacement: 'Polaris',
        other: true,
      },
      {
        regex: '(Embider)/(\\d+)\\.(\\d+)',
        replacement: 'Polaris',
        other: true,
      },
      {
        regex: '(BonEcho)/(\\d+)\\.(\\d+)\\.(\\d+)',
        replacement: 'Bon Echo',
        other: true,
      },
      {
        regex: '(iPod).+Version/(\\d+)\\.(\\d+)\\.(\\d+)',
        replacement: 'Mobile Safari',
        manufacturer: 'Apple',
      },
      {
        regex: '(iPod).*Version/(\\d+)\\.(\\d+)',
        replacement: 'Mobile Safari',
        manufacturer: 'Apple',
      },
      {
        regex: '(iPod)',
        replacement: 'Mobile Safari',
        manufacturer: 'Apple',
      },
      {
        regex: '(iPhone).*Version/(\\d+)\\.(\\d+)\\.(\\d+)',
        replacement: 'Mobile Safari',
        manufacturer: 'Apple',
      },
      {
        regex: '(iPhone).*Version/(\\d+)\\.(\\d+)',
        replacement: 'Mobile Safari',
        manufacturer: 'Apple',
      },
      {
        regex: '(iPhone)',
        replacement: 'Mobile Safari',
        manufacturer: 'Apple',
      },
      {
        regex: '(iPad).*Version/(\\d+)\\.(\\d+)\\.(\\d+)',
        replacement: 'Mobile Safari',
        tablet: true,
        manufacturer: 'Apple',
      },
      {
        regex: '(iPad).*Version/(\\d+)\\.(\\d+)',
        replacement: 'Mobile Safari',
        tablet: true,
        manufacturer: 'Apple',
      },
      {
        regex: '(iPad)',
        replacement: 'Mobile Safari',
        tablet: true,
        manufacturer: 'Apple',
      },
      {
        regex: '(AvantGo) (\\d+).(\\d+)',
        other: true,
      },
      {
        regex: '(Avant)',
        other: true,
      },
      {
        regex: '^(Nokia)',
        replacement: 'Nokia Services (WAP) Browser',
        manufacturer: 'Nokia',
      },
      {
        regex: '(NokiaBrowser)/(\\d+)\\.(\\d+).(\\d+)\\.(\\d+)',
        manufacturer: 'Nokia',
      },
      {
        regex: '(NokiaBrowser)/(\\d+)\\.(\\d+).(\\d+)',
        manufacturer: 'Nokia',
      },
      {
        regex: '(NokiaBrowser)/(\\d+)\\.(\\d+)',
        manufacturer: 'Nokia',
      },
      {
        regex: '(BrowserNG)/(\\d+)\\.(\\d+).(\\d+)',
        replacement: 'NokiaBrowser',
        manufacturer: 'Nokia',
      },
      {
        regex: '(Series60)/5\\.0',
        replacement: 'NokiaBrowser',
        manufacturer: 'Nokia',
      },
      {
        regex: '(Series60)/(\\d+)\\.(\\d+)',
        replacement: 'Nokia OSS Browser',
        manufacturer: 'Nokia',
      },
      {
        regex: '(S40OviBrowser)/(\\d+)\\.(\\d+)\\.(\\d+)\\.(\\d+)',
        replacement: 'Nokia Series 40 Ovi Browser',
        manufacturer: 'Nokia',
      },
      {
        regex: '(Nokia)[EN]?(\\d+)',
        manufacturer: 'Nokia',
      },
      {
        regex: '(PlayBook).+RIM Tablet OS (\\d+)\\.(\\d+)\\.(\\d+)',
        replacement: 'Blackberry WebKit',
        tablet: true,
        manufacturer: 'Nokia',
      },
      {
        regex: '(Black[bB]erry).+Version/(\\d+)\\.(\\d+)\\.(\\d+)',
        replacement: 'Blackberry WebKit',
        manufacturer: 'RIM',
      },
      {
        regex: '(Black[bB]erry)\\s?(\\d+)',
        replacement: 'Blackberry',
        manufacturer: 'RIM',
      },
      {
        regex: '(OmniWeb)/v(\\d+)\\.(\\d+)',
        other: true,
      },
      {
        regex: '(Blazer)/(\\d+)\\.(\\d+)',
        replacement: 'Palm Blazer',
        manufacturer: 'Palm',
      },
      {
        regex: '(Pre)/(\\d+)\\.(\\d+)',
        replacement: 'Palm Pre',
        manufacturer: 'Palm',
      },
      {
        regex: '(Links) \\((\\d+)\\.(\\d+)',
        other: true,
      },
      {
        regex: '(QtWeb) Internet Browser/(\\d+)\\.(\\d+)',
        other: true,
      },
      {
        regex: '(Silk)/(\\d+)\\.(\\d+)(?:\\.([0-9\\-]+))?',
        other: true,
        tablet: true,
      },
      {
        regex: '(AppleWebKit)/(\\d+)\\.?(\\d+)?\\+ .* Version/\\d+\\.\\d+.\\d+ Safari/',
        replacement: 'WebKit Nightly',
      },
      {
        regex: '(Version)/(\\d+)\\.(\\d+)(?:\\.(\\d+))?.*Safari/',
        replacement: 'Safari',
      },
      {
        regex: '(Safari)/\\d+',
      },
      {
        regex: '(OLPC)/Update(\\d+)\\.(\\d+)',
        other: true,
      },
      {
        regex: '(OLPC)/Update()\\.(\\d+)',
        other: true,
      },
      {
        regex: '(SEMC\\-Browser)/(\\d+)\\.(\\d+)',
        other: true,
      },
      {
        regex: '(Teleca)',
        replacement: 'Teleca Browser',
        other: true,
      },
      {
        regex: 'Trident(.*)rv.(\\d+)\\.(\\d+)',
        replacement: 'IE',
      },
      {
        regex: '(MSIE) (\\d+)\\.(\\d+)',
        replacement: 'IE',
      },
    ],
    osParsers: [
      {
        regex: '(Android) (\\d+)\\.(\\d+)(?:[.\\-]([a-z0-9]+))?',
      },
      {
        regex: '(Android)\\-(\\d+)\\.(\\d+)(?:[.\\-]([a-z0-9]+))?',
      },
      {
        regex: '(Android) Donut',
      },
      {
        regex: '(Android) Eclair',
      },
      {
        regex: '(Android) Froyo',
      },
      {
        regex: '(Android) Gingerbread',
      },
      {
        regex: '(Android) Honeycomb',
      },
      {
        regex: '(Silk-Accelerated=[a-z]{4,5})',
        replacement: 'Android',
      },
      {
        regex: '(Windows Phone 6\\.5)',
      },
      {
        regex: '(Windows (?:NT 5\\.2|NT 5\\.1))',
        replacement: 'Windows XP',
      },
      {
        regex: '(XBLWP7)',
        replacement: 'Windows Phone OS',
      },
      {
        regex: '(Windows NT 6\\.1)',
        replacement: 'Windows 7',
      },
      {
        regex: '(Windows NT 6\\.0)',
        replacement: 'Windows Vista',
      },
      {
        regex:
          '(Windows 98|Windows XP|Windows ME|Windows 95|Windows CE|Windows 7|Windows NT 4\\.0|Windows Vista|Windows 2000)',
      },
      {
        regex: '(Windows NT 6\\.4|Windows NT 10\\.0)',
        replacement: 'Windows 10',
      },
      {
        regex: '(Windows NT 6\\.2)',
        replacement: 'Windows 8',
      },
      {
        regex: '(Windows Phone 8)',
        replacement: 'Windows Phone 8',
      },
      {
        regex: '(Windows NT 5\\.0)',
        replacement: 'Windows 2000',
      },
      {
        regex: '(Windows Phone OS) (\\d+)\\.(\\d+)',
      },
      {
        regex: '(Windows ?Mobile)',
        replacement: 'Windows Mobile',
      },
      {
        regex: '(WinNT4.0)',
        replacement: 'Windows NT 4.0',
      },
      {
        regex: '(Win98)',
        replacement: 'Windows 98',
      },
      {
        regex: '(Tizen)/(\\d+)\\.(\\d+)',
        other: true,
      },
      {
        regex: '(Mac OS X) (\\d+)[_.](\\d+)(?:[_.](\\d+))?',
        manufacturer: 'Apple',
      },
      {
        regex: '(?:PPC|Intel) (Mac OS X)',
        manufacturer: 'Apple',
      },
      {
        regex: '(CPU OS|iPhone OS) (\\d+)_(\\d+)(?:_(\\d+))?',
        replacement: 'iOS',
        manufacturer: 'Apple',
      },
      {
        regex: '(iPhone|iPad|iPod); Opera',
        replacement: 'iOS',
        manufacturer: 'Apple',
      },
      {
        regex: '(iPad); Opera',
        tablet: true,
        manufacturer: 'Apple',
      },
      {
        regex: '(iPhone|iPad|iPod).*Mac OS X.*Version/(\\d+)\\.(\\d+)',
        replacement: 'iOS',
        manufacturer: 'Apple',
      },
      {
        regex: '(CrOS) [a-z0-9_]+ (\\d+)\\.(\\d+)(?:\\.(\\d+))?',
        replacement: 'Chrome OS',
      },
      {
        regex: '(Debian)-(\\d+)\\.(\\d+)\\.(\\d+)(?:\\.(\\d+))?',
        other: true,
      },
      {
        regex: '(Linux Mint)(?:/(\\d+))?',
        other: true,
      },
      {
        regex: '(Mandriva)(?: Linux)?/(\\d+)\\.(\\d+)\\.(\\d+)(?:\\.(\\d+))?',
        other: true,
      },
      {
        regex: '(Symbian[Oo][Ss])/(\\d+)\\.(\\d+)',
        replacement: 'Symbian OS',
      },
      {
        regex: '(Symbian/3).+NokiaBrowser/7\\.3',
        replacement: 'Symbian^3 Anna',
      },
      {
        regex: '(Symbian/3).+NokiaBrowser/7\\.4',
        replacement: 'Symbian^3 Belle',
      },
      {
        regex: '(Symbian/3)',
        replacement: 'Symbian^3',
      },
      {
        regex: '(Series 60|SymbOS|S60)',
        replacement: 'Symbian OS',
      },
      {
        regex: '(MeeGo)',
        other: true,
      },
      {
        regex: 'Symbian [Oo][Ss]',
        replacement: 'Symbian OS',
      },
      {
        regex: '(Black[Bb]erry)[0-9a-z]+/(\\d+)\\.(\\d+)\\.(\\d+)(?:\\.(\\d+))?',
        replacement: 'BlackBerry OS',
        manufacturer: 'RIM',
      },
      {
        regex: '(Black[Bb]erry).+Version/(\\d+)\\.(\\d+)\\.(\\d+)(?:\\.(\\d+))?',
        replacement: 'BlackBerry OS',
        manufacturer: 'RIM',
      },
      {
        regex: '(RIM Tablet OS) (\\d+)\\.(\\d+)\\.(\\d+)',
        replacement: 'BlackBerry Tablet OS',
        tablet: true,
        manufacturer: 'RIM',
      },
      {
        regex: '(Play[Bb]ook)',
        replacement: 'BlackBerry Tablet OS',
        tablet: true,
        manufacturer: 'RIM',
      },
      {
        regex: '(Black[Bb]erry)',
        replacement: 'Blackberry OS',
        manufacturer: 'RIM',
      },
      {
        regex: '(webOS|hpwOS)/(\\d+)\\.(\\d+)(?:\\.(\\d+))?',
        replacement: 'webOS',
      },
      {
        regex: '(SUSE|Fedora|Red Hat|PCLinuxOS)/(\\d+)\\.(\\d+)\\.(\\d+)\\.(\\d+)',
        other: true,
      },
      {
        regex: '(SUSE|Fedora|Red Hat|Puppy|PCLinuxOS|CentOS)/(\\d+)\\.(\\d+)\\.(\\d+)',
        other: true,
      },
      {
        regex: '(Ubuntu|Kindle|Bada|Lubuntu|BackTrack|Red Hat|Slackware)/(\\d+)\\.(\\d+)',
      },
      {
        regex: '(Windows|OpenBSD|FreeBSD|NetBSD|Ubuntu|Kubuntu|Android|Arch Linux|CentOS|WeTab|Slackware)',
      },
      {
        regex: '(Linux|BSD)',
        other: true,
      },
    ],
    deviceParsers: [
      {
        regex: 'HTC ([A-Z][a-z0-9]+) Build',
        replacement: 'HTC $1',
        manufacturer: 'HTC',
      },
      {
        regex: 'HTC ([A-Z][a-z0-9 ]+) \\d+\\.\\d+\\.\\d+\\.\\d+',
        replacement: 'HTC $1',
        manufacturer: 'HTC',
      },
      {
        regex: 'HTC_Touch_([A-Za-z0-9]+)',
        replacement: 'HTC Touch ($1)',
        manufacturer: 'HTC',
      },
      {
        regex: 'USCCHTC(\\d+)',
        replacement: 'HTC $1 (US Cellular)',
        manufacturer: 'HTC',
      },
      {
        regex: 'Sprint APA(9292)',
        replacement: 'HTC $1 (Sprint)',
        manufacturer: 'HTC',
      },
      {
        regex: 'HTC ([A-Za-z0-9]+ [A-Z])',
        replacement: 'HTC $1',
        manufacturer: 'HTC',
      },
      {
        regex: 'HTC-([A-Za-z0-9]+)',
        replacement: 'HTC $1',
        manufacturer: 'HTC',
      },
      {
        regex: 'HTC_([A-Za-z0-9]+)',
        replacement: 'HTC $1',
        manufacturer: 'HTC',
      },
      {
        regex: 'HTC ([A-Za-z0-9]+)',
        replacement: 'HTC $1',
        manufacturer: 'HTC',
      },
      {
        regex: '(ADR[A-Za-z0-9]+)',
        replacement: 'HTC $1',
        manufacturer: 'HTC',
      },
      {
        regex: '(HTC)',
        manufacturer: 'HTC',
      },
      {
        regex: 'SonyEricsson([A-Za-z0-9]+)/',
        replacement: 'Ericsson $1',
        other: true,
        manufacturer: 'Sony',
      },
      {
        regex: 'Android[\\- ][\\d]+\\.[\\d]+\\; [A-Za-z]{2}\\-[A-Za-z]{2}\\; WOWMobile (.+) Build',
      },
      {
        regex: 'Android[\\- ][\\d]+\\.[\\d]+\\.[\\d]+; [A-Za-z]{2}\\-[A-Za-z]{2}\\; (.+) Build',
      },
      {
        regex: 'Android[\\- ][\\d]+\\.[\\d]+\\-update1\\; [A-Za-z]{2}\\-[A-Za-z]{2}\\; (.+) Build',
      },
      {
        regex: 'Android[\\- ][\\d]+\\.[\\d]+\\; [A-Za-z]{2}\\-[A-Za-z]{2}\\; (.+) Build',
      },
      {
        regex: 'Android[\\- ][\\d]+\\.[\\d]+\\.[\\d]+; (.+) Build',
      },
      {
        regex: 'NokiaN([0-9]+)',
        replacement: 'Nokia N$1',
        manufacturer: 'Nokia',
      },
      {
        regex: 'Nokia([A-Za-z0-9\\v-]+)',
        replacement: 'Nokia $1',
        manufacturer: 'Nokia',
      },
      {
        regex: 'NOKIA ([A-Za-z0-9\\-]+)',
        replacement: 'Nokia $1',
        manufacturer: 'Nokia',
      },
      {
        regex: 'Nokia ([A-Za-z0-9\\-]+)',
        replacement: 'Nokia $1',
        manufacturer: 'Nokia',
      },
      {
        regex: 'Lumia ([A-Za-z0-9\\-]+)',
        replacement: 'Lumia $1',
        manufacturer: 'Nokia',
      },
      {
        regex: 'Symbian',
        replacement: 'Nokia',
        manufacturer: 'Nokia',
      },
      {
        regex: '(PlayBook).+RIM Tablet OS',
        replacement: 'Blackberry Playbook',
        tablet: true,
        manufacturer: 'RIM',
      },
      {
        regex: '(Black[Bb]erry [0-9]+);',
        manufacturer: 'RIM',
      },
      {
        regex: 'Black[Bb]erry([0-9]+)',
        replacement: 'BlackBerry $1',
        manufacturer: 'RIM',
      },
      {
        regex: '(Pre)/(\\d+)\\.(\\d+)',
        replacement: 'Palm Pre',
        manufacturer: 'Palm',
      },
      {
        regex: '(Pixi)/(\\d+)\\.(\\d+)',
        replacement: 'Palm Pixi',
        manufacturer: 'Palm',
      },
      {
        regex: '(Touchpad)/(\\d+)\\.(\\d+)',
        replacement: 'HP Touchpad',
        manufacturer: 'HP',
      },
      {
        regex: 'HPiPAQ([A-Za-z0-9]+)/(\\d+).(\\d+)',
        replacement: 'HP iPAQ $1',
        manufacturer: 'HP',
      },
      {
        regex: 'Palm([A-Za-z0-9]+)',
        replacement: 'Palm $1',
        manufacturer: 'Palm',
      },
      {
        regex: 'Treo([A-Za-z0-9]+)',
        replacement: 'Palm Treo $1',
        manufacturer: 'Palm',
      },
      {
        regex: 'webOS.*(P160UNA)/(\\d+).(\\d+)',
        replacement: 'HP Veer',
        manufacturer: 'HP',
      },
      {
        regex: '(Kindle Fire)',
        manufacturer: 'Amazon',
      },
      {
        regex: '(Kindle)',
        manufacturer: 'Amazon',
      },
      {
        regex: '(Silk)/(\\d+)\\.(\\d+)(?:\\.([0-9\\-]+))?',
        replacement: 'Kindle Fire',
        tablet: true,
        manufacturer: 'Amazon',
      },
      {
        regex: '(iPad) Simulator;',
        manufacturer: 'Apple',
      },
      {
        regex: '(iPad);',
        manufacturer: 'Apple',
      },
      {
        regex: '(iPod);',
        manufacturer: 'Apple',
      },
      {
        regex: '(iPhone) Simulator;',
        manufacturer: 'Apple',
      },
      {
        regex: '(iPhone);',
        manufacturer: 'Apple',
      },
      {
        regex: 'Nexus\\ ([A-Za-z0-9\\-]+)',
        replacement: 'Nexus $1',
      },
      {
        regex: 'acer_([A-Za-z0-9]+)_',
        replacement: 'Acer $1',
        manufacturer: 'Acer',
      },
      {
        regex: 'acer_([A-Za-z0-9]+)_',
        replacement: 'Acer $1',
        manufacturer: 'Acer',
      },
      {
        regex: 'Amoi\\-([A-Za-z0-9]+)',
        replacement: 'Amoi $1',
        other: true,
        manufacturer: 'Amoi',
      },
      {
        regex: 'AMOI\\-([A-Za-z0-9]+)',
        replacement: 'Amoi $1',
        other: true,
        manufacturer: 'Amoi',
      },
      {
        regex: 'Asus\\-([A-Za-z0-9]+)',
        replacement: 'Asus $1',
        manufacturer: 'Asus',
      },
      {
        regex: 'ASUS\\-([A-Za-z0-9]+)',
        replacement: 'Asus $1',
        manufacturer: 'Asus',
      },
      {
        regex: 'BIRD\\-([A-Za-z0-9]+)',
        replacement: 'Bird $1',
        other: true,
      },
      {
        regex: 'BIRD\\.([A-Za-z0-9]+)',
        replacement: 'Bird $1',
        other: true,
      },
      {
        regex: 'BIRD ([A-Za-z0-9]+)',
        replacement: 'Bird $1',
        other: true,
      },
      {
        regex: 'Dell ([A-Za-z0-9]+)',
        replacement: 'Dell $1',
        manufacturer: 'Dell',
      },
      {
        regex: 'DoCoMo/2\\.0 ([A-Za-z0-9]+)',
        replacement: 'DoCoMo $1',
        other: true,
      },
      {
        regex: '([A-Za-z0-9]+)\\_W\\;FOMA',
        replacement: 'DoCoMo $1',
        other: true,
      },
      {
        regex: '([A-Za-z0-9]+)\\;FOMA',
        replacement: 'DoCoMo $1',
        other: true,
      },
      {
        regex: 'vodafone([A-Za-z0-9]+)',
        replacement: 'Huawei Vodafone $1',
        other: true,
      },
      {
        regex: 'i\\-mate ([A-Za-z0-9]+)',
        replacement: 'i-mate $1',
        other: true,
      },
      {
        regex: 'Kyocera\\-([A-Za-z0-9]+)',
        replacement: 'Kyocera $1',
        other: true,
      },
      {
        regex: 'KWC\\-([A-Za-z0-9]+)',
        replacement: 'Kyocera $1',
        other: true,
      },
      {
        regex: 'Lenovo\\-([A-Za-z0-9]+)',
        replacement: 'Lenovo $1',
        manufacturer: 'Lenovo',
      },
      {
        regex: 'Lenovo\\_([A-Za-z0-9]+)',
        replacement: 'Lenovo $1',
        manufacturer: 'Levovo',
      },
      {
        regex: 'LG/([A-Za-z0-9]+)',
        replacement: 'LG $1',
        manufacturer: 'LG',
      },
      {
        regex: 'LG-LG([A-Za-z0-9]+)',
        replacement: 'LG $1',
        manufacturer: 'LG',
      },
      {
        regex: 'LGE-LG([A-Za-z0-9]+)',
        replacement: 'LG $1',
        manufacturer: 'LG',
      },
      {
        regex: 'LGE VX([A-Za-z0-9]+)',
        replacement: 'LG $1',
        manufacturer: 'LG',
      },
      {
        regex: 'LG ([A-Za-z0-9]+)',
        replacement: 'LG $1',
        manufacturer: 'LG',
      },
      {
        regex: 'LGE LG\\-AX([A-Za-z0-9]+)',
        replacement: 'LG $1',
        manufacturer: 'LG',
      },
      {
        regex: 'LG\\-([A-Za-z0-9]+)',
        replacement: 'LG $1',
        manufacturer: 'LG',
      },
      {
        regex: 'LGE\\-([A-Za-z0-9]+)',
        replacement: 'LG $1',
        manufacturer: 'LG',
      },
      {
        regex: 'LG([A-Za-z0-9]+)',
        replacement: 'LG $1',
        manufacturer: 'LG',
      },
      {
        regex: '(KIN)\\.One (\\d+)\\.(\\d+)',
        replacement: 'Microsoft $1',
      },
      {
        regex: '(KIN)\\.Two (\\d+)\\.(\\d+)',
        replacement: 'Microsoft $1',
      },
      {
        regex: '(Motorola)\\-([A-Za-z0-9]+)',
        manufacturer: 'Motorola',
      },
      {
        regex: 'MOTO\\-([A-Za-z0-9]+)',
        replacement: 'Motorola $1',
        manufacturer: 'Motorola',
      },
      {
        regex: 'MOT\\-([A-Za-z0-9]+)',
        replacement: 'Motorola $1',
        manufacturer: 'Motorola',
      },
      {
        regex: 'Philips([A-Za-z0-9]+)',
        replacement: 'Philips $1',
        manufacturer: 'Philips',
      },
      {
        regex: 'Philips ([A-Za-z0-9]+)',
        replacement: 'Philips $1',
        manufacturer: 'Philips',
      },
      {
        regex: 'SAMSUNG-([A-Za-z0-9\\-]+)',
        replacement: 'Samsung $1',
        manufacturer: 'Samsung',
      },
      {
        regex: 'SAMSUNG\\; ([A-Za-z0-9\\-]+)',
        replacement: 'Samsung $1',
        manufacturer: 'Samsung',
      },
      {
        regex: 'Softbank/1\\.0/([A-Za-z0-9]+)',
        replacement: 'Softbank $1',
        other: true,
      },
      {
        regex: 'Softbank/2\\.0/([A-Za-z0-9]+)',
        replacement: 'Softbank $1',
        other: true,
      },
      {
        regex:
          '(hiptop|avantgo|plucker|xiino|blazer|elaine|up.browser|up.link|mmp|smartphone|midp|wap|vodafone|o2|pocket|mobile|pda)',
        replacement: 'Generic Smartphone',
      },
      {
        regex:
          '^(1207|3gso|4thp|501i|502i|503i|504i|505i|506i|6310|6590|770s|802s|a wa|acer|acs\\-|airn|alav|asus|attw|au\\-m|aur |aus |abac|acoo|aiko|alco|alca|amoi|anex|anny|anyw|aptu|arch|argo|bell|bird|bw\\-n|bw\\-u|beck|benq|bilb|blac|c55/|cdm\\-|chtm|capi|comp|cond|craw|dall|dbte|dc\\-s|dica|ds\\-d|ds12|dait|devi|dmob|doco|dopo|el49|erk0|esl8|ez40|ez60|ez70|ezos|ezze|elai|emul|eric|ezwa|fake|fly\\-|fly\\_|g\\-mo|g1 u|g560|gf\\-5|grun|gene|go.w|good|grad|hcit|hd\\-m|hd\\-p|hd\\-t|hei\\-|hp i|hpip|hs\\-c|htc |htc\\-|htca|htcg)',
        replacement: 'Generic Feature Phone',
      },
      {
        regex:
          '^(htcp|htcs|htct|htc\\_|haie|hita|huaw|hutc|i\\-20|i\\-go|i\\-ma|i230|iac|iac\\-|iac/|ig01|im1k|inno|iris|jata|java|kddi|kgt|kgt/|kpt |kwc\\-|klon|lexi|lg g|lg\\-a|lg\\-b|lg\\-c|lg\\-d|lg\\-f|lg\\-g|lg\\-k|lg\\-l|lg\\-m|lg\\-o|lg\\-p|lg\\-s|lg\\-t|lg\\-u|lg\\-w|lg/k|lg/l|lg/u|lg50|lg54|lge\\-|lge/|lynx|leno|m1\\-w|m3ga|m50/|maui|mc01|mc21|mcca|medi|meri|mio8|mioa|mo01|mo02|mode|modo|mot |mot\\-|mt50|mtp1|mtv |mate|maxo|merc|mits|mobi|motv|mozz|n100|n101|n102|n202|n203|n300|n302|n500|n502|n505|n700|n701|n710|nec\\-|nem\\-|newg|neon)',
        replacement: 'Generic Feature Phone',
      },
      {
        regex:
          '^(netf|noki|nzph|o2 x|o2\\-x|opwv|owg1|opti|oran|ot\\-s|p800|pand|pg\\-1|pg\\-2|pg\\-3|pg\\-6|pg\\-8|pg\\-c|pg13|phil|pn\\-2|pt\\-g|palm|pana|pire|pock|pose|psio|qa\\-a|qc\\-2|qc\\-3|qc\\-5|qc\\-7|qc07|qc12|qc21|qc32|qc60|qci\\-|qwap|qtek|r380|r600|raks|rim9|rove|s55/|sage|sams|sc01|sch\\-|scp\\-|sdk/|se47|sec\\-|sec0|sec1|semc|sgh\\-|shar|sie\\-|sk\\-0|sl45|slid|smb3|smt5|sp01|sph\\-|spv |spv\\-|sy01|samm|sany|sava|scoo|send|siem|smar|smit|soft|sony|t\\-mo|t218|t250|t600|t610|t618|tcl\\-|tdg\\-|telm|tim\\-|ts70|tsm\\-|tsm3|tsm5|tx\\-9|tagt)',
        replacement: 'Generic Feature Phone',
      },
      {
        regex:
          '^(talk|teli|topl|tosh|up.b|upg1|utst|v400|v750|veri|vk\\-v|vk40|vk50|vk52|vk53|vm40|vx98|virg|vite|voda|vulc|w3c |w3c\\-|wapj|wapp|wapu|wapm|wig |wapi|wapr|wapv|wapy|wapa|waps|wapt|winc|winw|wonu|x700|xda2|xdag|yas\\-|your|zte\\-|zeto|aste|audi|avan|blaz|brew|brvw|bumb|ccwa|cell|cldc|cmd\\-|dang|eml2|fetc|hipt|http|ibro|idea|ikom|ipaq|jbro|jemu|jigs|keji|kyoc|kyok|libw|m\\-cr|midp|mmef|moto|mwbp|mywa|newt|nok6|o2im|pant|pdxg|play|pluc|port|prox|rozo|sama|seri|smal|symb|treo|upsi|vx52|vx53|vx60|vx61|vx70|vx80|vx81|vx83|vx85|wap\\-|webc|whit|wmlb|xda\\-|xda\\_)',
        replacement: 'Generic Feature Phone',
      },
      {
        regex:
          '(bot|borg|google(^tv)|yahoo|slurp|msnbot|msrbot|openbot|archiver|netresearch|lycos|scooter|altavista|teoma|gigabot|baiduspider|blitzbot|oegp|charlotte|furlbot|http%20client|polybot|htdig|ichiro|mogimogi|larbin|pompos|scrubby|searchsight|seekbot|semanticdiscovery|silk|snappy|speedy|spider|voila|vortex|voyager|zao|zeal|fast\\-webcrawler|converacrawler|dataparksearch|findlinks)',
        replacement: 'Spider',
      },
    ],
    mobileOsFamilies: ['Windows Phone 6.5', 'Windows CE', 'Symbian OS'],
    mobileBrowserFamilies: [
      'Firefox Mobile',
      'Opera Mobile',
      'Opera Mini',
      'Mobile Safari',
      'webOS',
      'IE Mobile',
      'Playstation Portable',
      'Nokia',
      'Blackberry',
      'Palm',
      'Silk',
      'Android',
      'Maemo',
      'Obigo',
      'Netfront',
      'AvantGo',
      'Teleca',
      'SEMC-Browser',
      'Bolt',
      'Iris',
      'UP.Browser',
      'Symphony',
      'Minimo',
      'Bunjaloo',
      'Jasmine',
      'Dolfin',
      'Polaris',
      'BREW',
      'Chrome Mobile',
      'Chrome Mobile iOS',
      'UC Browser',
      'Tizen Browser',
    ],
  }

  private static find(ua: string, parsers: Array<ParserFunction>): ParserResult | null {
    for (const parser of parsers) {
      const result = parser(ua)
      if (result) {
        return result
      }
    }
    return null
  }

  private static toVersionString(major: number, minor: number, patch: number): string {
    let output = ''
    if (!isNaN(major)) {
      output += major
      if (!isNaN(minor)) {
        output += '.' + minor
        if (!isNaN(patch)) {
          output += '.' + patch
        }
      }
    }
    return output
  }

  private static getParsers(type: 'browser' | 'os' | 'device'): Array<ParserFunction> {
    const keyParser = (type + 'Parsers') as keyof Regexes
    const parsers = this.regexes[keyParser] as Array<Parser>
    return parsers.map((obj: Parser) => {
      const regexp = new RegExp(obj.regex)

      return (ua: string): ParserResult | null => {
        const m = ua.match(regexp)
        if (!m) {
          return null
        }

        const major = parseInt(m[2])
        const minor = m[3] ? parseInt(m[3]) : NaN
        const patch = m[4] ? parseInt(m[4]) : NaN
        const version = this.toVersionString(major, minor, patch)
        const family = (obj.replacement ? obj.replacement.replace('$1', m[1]) : m[1]) || 'other'

        return {
          family,
          tablet: obj.tablet ?? false,
          manufacturer: obj.manufacturer || null,
          name: version ? `${family} ${version}` : family,
          version,
        }
      }
    })
  }

  static parse(ua: string): DetectResult {
    const browserParsers = this.getParsers('browser')
    const osParsers = this.getParsers('os')
    const deviceParsers = this.getParsers('device')

    const browser = this.find(ua, browserParsers)
    const os = this.find(ua, osParsers)
    const deviceParsed = this.find(ua, deviceParsers)

    let device: Device = {
      family: 'Other',
      name: 'Other',
      version: '',
      tablet: false,
      manufacturer: null,
      type: 'desktop',
    }

    if (deviceParsed !== null) {
      device = { ...deviceParsed, type: 'desktop' }
    }

    const mobileFamilies = this.regexes.mobileBrowserFamilies.concat(this.regexes.mobileOsFamilies)

    if (browser?.family === 'Spider') {
      device.type = 'spider'
    } else if (browser?.tablet || os?.tablet || device?.tablet) {
      device.type = 'tablet'
    } else if (browser !== null && mobileFamilies.includes(browser.family)) {
      device.type = 'mobile'
    }

    device.manufacturer = browser?.manufacturer || os?.manufacturer || device?.manufacturer || null

    return {
      source: ua,
      browser,
      os,
      device,
    }
  }
}
