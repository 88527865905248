import { gql } from 'graphql-tag'

export default (on: string) => gql`
  fragment LessonMainFields on ${on} {
    id
    title
    description
    number
    status
    createdAt
    updatedAt
  }
`
