import { gql } from 'graphql-tag'
import { BlockMainFields } from '.'

export default (on: string) => gql`
  ${BlockMainFields(on)}
  fragment BlockFields on ${on} {
    ...BlockMainFields

    educationalProgram {
      id
      value
    }
    subject {
      id
      title
    }
  }
`
