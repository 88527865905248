import { gql } from 'graphql-tag'
import { StatusMainFields } from '~/entities/workflow/gql/fragments'

export default gql`
  ${StatusMainFields('WorkflowStatusType')}
  query getStatuses($filter: WorkflowSchemaStatusesFilterInput) {
    workflowSchemaStatuses(filter: $filter) {
      ...StatusMainFields
    }
  }
`
