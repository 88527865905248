import { requiredRule, onlyCyrillicRule, phoneRule } from '~/shared/utils/validation'
import type { ProfileFullInput } from '~/entities/user/interfaces'
import type { UserModel } from '~/entities/user/model'
import { ProfileSubjectModel } from '~/entities/user/model'

export default class ProfileFullForm {
  private _user: UserModel
  name: string = ''
  surName: string = ''
  middleName: string = ''
  birthDate: string = ''
  phone: string = ''
  experience: string = ''
  profileSubject: ProfileSubjectModel | null = null

  constructor(user: UserModel) {
    this._user = user
    this.fillForm()
  }

  setUser(user: UserModel) {
    this._user = user
    this.fillForm()
  }

  private fillForm() {
    this.name = this._user.profile.name
    this.surName = this._user.profile.surName
    this.middleName = this._user.profile.middleName
    this.birthDate = this._user.profile.dateOfBirth
    this.phone = this._user.phone
    this.experience = this._user.profile.experience
    this.profileSubject = this._user.profile.profileSubjects[0] ?? null
  }

  reset() {
    this.fillForm()
  }

  getInputData(): ProfileFullInput {
    return {
      phone: this.phone,
      profile: {
        id: this._user.profile.id,
        name: this.name,
        surName: this.surName,
        dateOfBirth: this.birthDate,
        middleName: this.middleName,
        experience: this.experience,
        profileSubjects: this.profileSubject ? [this.profileSubject.id] : [],
      },
    }
  }

  static rules() {
    return {
      name: { ...requiredRule, ...onlyCyrillicRule },
      surName: { ...requiredRule, ...onlyCyrillicRule },
      middleName: onlyCyrillicRule,
      birthDate: requiredRule,
      phone: { ...requiredRule, ...phoneRule },
      experience: [],
      profileSubject: [],
    }
  }

  errorFieldsMap(): Map<string, keyof typeof ProfileFullForm.prototype> {
    return new Map(
      Object.entries({
        phone: 'phone',
        'profile.name': 'name',
        'profile.surName': 'surName',
        'profile.middleName': 'middleName',
        'profile.birthDate': 'birthDate',
        'profile.experience': 'experience',
        'profile.profileSubjects': 'profileSubject',
      })
    )
  }
}
