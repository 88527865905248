import { useContext, useRouter } from '@nuxtjs/composition-api'
import { RegisterForm, RegisterTeacherForm } from '~/entities/user/api'
import { DEFAULT_ACCOUNT_ROUTE } from '~/shared/const'

export const useSubmitRegisterForm = () => {
  const ctx = useContext()
  const router = useRouter()
  const submit = (form: RegisterForm | RegisterTeacherForm) => {
    return ctx.$services.user
      .register(form)
      .then((token) => ctx.$services.apollo.onLogin(token))
      .then(() => ctx.$services.user.getAuthorizedUser())
      .then((user) => {
        ctx.$tstore.user.setAuthorizedUser(user)
        router.push(DEFAULT_ACCOUNT_ROUTE)
      })
  }

  return { submit }
}
