import { gql } from 'graphql-tag'
import { ArticleFieldsForCard } from '~/entities/article/gql/fragments'
import { FileFields, PaginationFields } from '~/shared/gql/fragments'
import type { ArticleListType } from '~/entities/article/interface'

export default (type: ArticleListType) => {
  let data, sort

  switch (type) {
    case 'my': {
      data = 'myPublications'
      sort = 'QueryMyPublicationsSortOrderByClause'
      break
    }
    case 'moderation': {
      data = 'publications'
      sort = 'QueryPublicationsSortOrderByClause'
      break
    }
    default: {
      data = 'activePublications'
      sort = 'QueryActivePublicationsSortOrderByClause'
      break
    }
  }

  return gql`
  ${ArticleFieldsForCard}
  ${PaginationFields('PublicationTypePaginator')}
  ${FileFields('MediaType')}
    query getArticles(
      $first: Int = 9999
      $page: Int
      $filter: PublicationsFilterInput
      $sort: [${sort}!]
    ) {
      ${data}(first: $first, page: $page, filter: $filter, sort: $sort) {
        data {
          ...ArticleFieldsForCard
        }

        ...PaginationFields
      }
    }
  `
}
