import { ClassifierEntryModel } from '~/shared/model'

export default class Theme extends ClassifierEntryModel {
  static isTextError(data: Theme): boolean {
    return data.alias === 'feedback_messages_type_text_errors'
  }

  static isPersonal(data: Theme): boolean {
    return data.alias === 'feedback_messages_type_personal'
  }
}
