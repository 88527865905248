export const validationMessages = {
  required: 'Поле обязательно для заполнения',
  onlyCyrillic: 'Содержатся некорректные символы',
  email: 'Некорректный email',
  phone: 'Неверный формат номера телефона',
  password: 'Пароль не может быть менее 8 символов',
  passportSeries: 'Неверный формат серии паспорта',
  passportNumber: 'Неверный формат номера паспорта',
  passportDepartment: 'Неверный формат кода подразделения',
  websiteUrl: 'Неверный формат ссылки вебсайта',
  maxLengthList: 'Количество элементов в списке не должно превышать ${max}', // eslint-disable-line no-template-curly-in-string
}
