import { UserProfileModel, ApplicationModel } from '.'
import { BaseModel, convertToArrayModels } from '~/shared/model'
import { ClassModel } from '~/entities/class/model'
import type { HasPermissions } from '~/shared/interface'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject, isString } from '~/shared/utils/guards'

export default class User extends BaseModel implements HasPermissions {
  id: string = ''
  email: string = ''
  phone: string = ''
  photo: string = ''
  readonly permissions: Set<string> = new Set()
  profile: UserProfileModel = new UserProfileModel(null)
  applications: Array<ApplicationModel> = []
  classes: Array<ClassModel> = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['profile', 'permissions', 'roles', 'classJoinRequests', 'classes'])

      if (Array.isArray(data.roles) && data.roles.length > 0) {
        data.roles.forEach((r) => {
          if (isUnknownObject(r) && Array.isArray(r.permissions)) {
            r.permissions.forEach((p) => {
              if (isUnknownObject(p) && isString(p.name)) {
                this.permissions.add(p.name)
              }
            })
          }
        })
      }

      if (Array.isArray(data.permissions) && data.permissions.length > 0) {
        data.permissions.forEach((p) => {
          if (isUnknownObject(p) && isString(p.name)) {
            this.permissions.add(p.name)
          }
        })
      }

      if (isUnknownObject(data.profile)) {
        this.profile = new UserProfileModel(data.profile)
      }

      this.applications = convertToArrayModels(data.classJoinRequests, ApplicationModel)
      this.classes = convertToArrayModels(data.classes, ClassModel)
    }
  }
}
