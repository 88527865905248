
import { computed, defineComponent, ref, useContext } from '@nuxtjs/composition-api'
import { SvoiNotificationsList } from '@svoi-ui/components/list'
import { SvoiBreadcrumbs } from '@svoi-ui/components/breadcrumbs'
import { Header } from '~/widgets/header'
import { Footer } from '~/widgets/footer'
import { ConfirmModal, InformationModal } from '~/shared/ui/modal'
import type { ModalConfirmData, ModalInformationData } from '~/shared/interface'
import { useFeedback, useGlobalState, useEmitter } from '~/composables'
import { FeedbackModal } from '~/widgets/feedback'
import { events } from '~/shared/const'

export default defineComponent({
  components: { InformationModal, FeedbackModal, Header, Footer, SvoiNotificationsList, ConfirmModal, SvoiBreadcrumbs },
  setup() {
    const ctx = useContext()
    const { breadcrumbsState } = useGlobalState()
    const breadcrumbs = ref(breadcrumbsState)
    const { listenerFeedbackModal } = useFeedback()
    const { componentFeedbackListeners, componentFeedbackProps } = listenerFeedbackModal()
    const { activateMountedListener } = useEmitter()

    const notifications = computed({
      get: () => ctx.$tstore.notifications.notifications,
      set: (val) => ctx.$tstore.notifications.setNotifications(val),
    })

    const emptyConfirmData: ModalConfirmData = {
      show: false,
      title: '',
      description: '',
      successCallback: () => null,
      rejectCallback: () => null,
    }
    const modalConfirmData = ref<ModalConfirmData>(emptyConfirmData)

    const emptyInformationData: ModalInformationData = {
      show: false,
      title: '',
      description: '',
    }
    const modalInformationData = ref<ModalInformationData>(emptyInformationData)

    activateMountedListener(events.layout.showConfirm, (params: ModalConfirmData) => {
      modalConfirmData.value = Object.assign({ ...emptyConfirmData }, params)
    })
    activateMountedListener(events.layout.showInformation, (params: ModalInformationData) => {
      modalInformationData.value = Object.assign({ ...emptyInformationData }, params)
    })

    return {
      notifications,
      modalConfirmData,
      breadcrumbs,
      componentFeedbackProps,
      componentFeedbackListeners,
      modalInformationData,
    }
  },
})
