import { requiredRule } from '~/shared/utils/validation'
import type { EditSubjectInput } from '~/entities/subject/interface'
import { SubjectModel } from '~/entities/subject/model'
import { MediaModel } from '~/shared/model'
import { EducationProgramModel } from '~/entities/education-program/model'

export default class EditForm {
  private subject: SubjectModel
  id: string = ''
  title: string = ''
  description: string = ''
  educationPrograms: Array<EducationProgramModel> = []
  preview: Array<File | MediaModel> = []

  constructor(data: SubjectModel) {
    this.subject = data
    this.id = data.id
    this.title = data.title
    this.description = data.description
    this.preview = [...data.media]
    this.educationPrograms = [...data.educationPrograms]
  }

  getInputData(): EditSubjectInput {
    const input: EditSubjectInput = {
      educationalProgram: this.educationPrograms.map((i) => i.id),
      title: this.title,
      description: this.description,
      id: this.id,
    }

    if (this.preview.length && this.preview[0] instanceof File) {
      const mediaModel = this.subject.media[0]
      input.preview = {
        action: mediaModel ? 'REPLACE' : 'CREATE',
        file: this.preview[0],
      }
      if (mediaModel) {
        input.preview.id = mediaModel.id
      }
    }

    return input
  }

  static rules() {
    return {
      id: requiredRule,
      title: requiredRule,
      educationPrograms: requiredRule,
      preview: requiredRule,
      description: [],
    }
  }
}
