
import { computed, defineComponent, ref } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'

export default defineComponent({
  components: { SvoiIcon },
  props: {
    closedWidth: {
      type: Number,
      default: 60
    },
    showActivator: {
      type: Boolean,
      default: true
    },
    value: {
      type: Boolean,
      default: false
    }
  },

  emits: ['input'],

  setup(props, { emit }) {
    const isOpen = ref<boolean>(props.value)

    const styles = computed(() => ({ width: isOpen.value ? '100%' : `${props.closedWidth}px` }))

    const activatorClasses = computed(() => ({
      icon: true,
      'icon-active': isOpen.value
    }))

    const toggleOpen = (value = !isOpen.value) => {
      isOpen.value = value

      emit('input', value)
    }

    return { isOpen, activatorClasses, styles, toggleOpen }
  }
})
