import { Plugin, Context } from '@nuxt/types'
import { useGlobalState } from '~/composables'
import { events } from '~/shared/const'

const routerBeforeEachClient: Plugin = (context: Context) => {
  const { leavePageState, breadcrumbsState } = useGlobalState()
  context.app?.router?.beforeEach((to, from, next) => {
    if (leavePageState.check && to.name !== from.name) {
      context.$emitter.emit(events.layout.showConfirm, {
        show: true,
        title: leavePageState.message,
        successCallback: () => {
          leavePageState.reset()
          next()
        },
      })
      return
    }

    if (breadcrumbsState.changed && to.path !== from.path) {
      breadcrumbsState.reset()
    }

    next()
  })
}

export default routerBeforeEachClient
