import { UserModel } from '~/entities/user/model'
import { BaseModel } from '~/shared/model'
import { isString, isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'

export default class PublicationFeedbackable extends BaseModel {
  author: UserModel | null = null
  slug: string = ''

  constructor(data: unknown) {
    super()

    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['author', 'slug'])

      if (isString(data.slug)) {
        this.slug = data.slug
      }

      if (isUnknownObject(data.author)) {
        this.author = new UserModel(data.author)
      }
    }
  }
}
