import { requiredRule } from '~/shared/utils/validation'
import { CopyLessonInput } from '~/entities/lesson/interface'
import { SubjectModel } from '~/entities/subject/model'
import { LearningBlockModel } from '~/entities/learning-block/model'

export default class CopyForm {
  templateId: string = ''
  subject: SubjectModel | null = null
  learningBlock: LearningBlockModel | null = null

  getInputData(): CopyLessonInput {
    return {
      templateId: this.templateId,
      blockId: this.learningBlock?.id ?? '',
    }
  }

  static rules() {
    return {
      templateId: requiredRule,
      subject: requiredRule,
      learningBlock: requiredRule,
    }
  }
}
