
import { computed, defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api'
import { SvoiButton } from '@svoi-ui/components/button'
import { SvoiHint } from '@svoi-ui/components/hint'
import { uuid } from '@svoi-ui/shared/utils/helpers'
import { isRefObject } from '@svoi-ui/shared/utils/guards'
import { BaseFilePickerComponent, useBaseFilePicker } from './BaseFilePicker'

export default defineComponent({
  name: 'SvoiFilePickerButton',
  components: { SvoiButton, SvoiHint },
  props: {
    ...BaseFilePickerComponent.props,
    value: {
      type: Array as PropType<Array<File>>,
      default: [] as Array<File>
    },
    buttonText: {
      type: String,
      default: 'Загрузить файлы'
    }
  },
  setup(props, { emit }) {
    const { syncElementFiles, checkFiles } = useBaseFilePicker(props)

    const error = ref('')
    const complexError = computed(() => `${props.error} ${error.value}`)
    const id = uuid()

    const classes = computed(() => ({
      '-error': isRefObject(complexError) && complexError.value.trim().length > 0
    }))

    const onClick = () => document.getElementById(id)?.click()

    const fileAdded = (e: Event) => {
      error.value = ''
      let files = Array.from((e.target as HTMLInputElement).files ?? [])

      if (files.length > props.maxCount) {
        error.value = `Максимальное количество загруженных файлов - ${props.maxCount}`
        files = []
      }

      const checkResult = checkFiles(files)
      if (checkResult !== true) {
        error.value = checkResult
        files = []
      }

      emit('input', files)
    }

    watch(
      () => props.value,
      () => syncElementFiles(id)
    )

    return { id, complexError, classes, fileAdded, onClick }
  }
})
