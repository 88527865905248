import { requiredRule } from '~/shared/utils/validation'
import { singleton } from '~/shared/utils/decorators' // eslint-disable-line @typescript-eslint/no-unused-vars

@singleton
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class BirthCertificateForm {
  serial: string = ''
  number: string = ''
  dateOfIssue: string = ''
  issuedBy: string = ''
  _invalid: boolean = true

  clear() {
    this.serial = ''
    this.number = ''
    this.issuedBy = ''
    this.dateOfIssue = ''
    this._invalid = true
  }

  toAgreementsData() {
    return {
      birthCertificate: {
        birthCertificateSerial: this.serial,
        birthCertificateNumber: this.number,
        birthCertificateIssuer: this.issuedBy,
        birthCertificateIssueDate: this.dateOfIssue,
      },
    }
  }

  static getInstance() {
    return new BirthCertificateForm()
  }

  static rules() {
    return {
      serial: requiredRule,
      number: requiredRule,
      dateOfIssue: requiredRule,
      issuedBy: requiredRule,
    }
  }
}
