import { gql } from 'graphql-tag'
import { ClassMainFields } from '~/entities/class/gql/fragments'

export default gql`
  ${ClassMainFields('SchoolsCuratorClassType')}
  mutation createClass($input: CreateSchoolsClassInput!) {
    createSchoolsClass(input: $input) {
      ...ClassFields
    }
  }
`
