import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _079375d0 = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _a4d25392 = () => interopDefault(import('../pages/favorites.vue' /* webpackChunkName: "pages/favorites" */))
const _3b55f71a = () => interopDefault(import('../pages/forgot.vue' /* webpackChunkName: "pages/forgot" */))
const _5e75dbe8 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _2d88e25a = () => interopDefault(import('../pages/publications/index.vue' /* webpackChunkName: "pages/publications/index" */))
const _47873394 = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _22abdc3e = () => interopDefault(import('../pages/account/agroclasses.vue' /* webpackChunkName: "pages/account/agroclasses" */))
const _03c43c15 = () => interopDefault(import('../pages/account/applications.vue' /* webpackChunkName: "pages/account/applications" */))
const _5113f072 = () => interopDefault(import('../pages/account/articles/index.vue' /* webpackChunkName: "pages/account/articles/index" */))
const _3c1ac6b7 = () => interopDefault(import('../pages/account/articles-moderation/index.vue' /* webpackChunkName: "pages/account/articles-moderation/index" */))
const _3e700fba = () => interopDefault(import('../pages/account/feedback/index.vue' /* webpackChunkName: "pages/account/feedback/index" */))
const _ab38ec96 = () => interopDefault(import('../pages/account/knowledge-base/index.vue' /* webpackChunkName: "pages/account/knowledge-base/index" */))
const _6a0551a7 = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _17fe3e05 = () => interopDefault(import('../pages/account/program/index.vue' /* webpackChunkName: "pages/account/program/index" */))
const _0631a85c = () => interopDefault(import('../pages/account/scheduled-lessons/index.vue' /* webpackChunkName: "pages/account/scheduled-lessons/index" */))
const _3e50665a = () => interopDefault(import('../pages/publications/search/index.vue' /* webpackChunkName: "pages/publications/search/index" */))
const _f772593c = () => interopDefault(import('../pages/register/student/index.vue' /* webpackChunkName: "pages/register/student/index" */))
const _12e37849 = () => interopDefault(import('../pages/register/teacher/index.vue' /* webpackChunkName: "pages/register/teacher/index" */))
const _46362b19 = () => interopDefault(import('../pages/account/agreements/generate.vue' /* webpackChunkName: "pages/account/agreements/generate" */))
const _973553e2 = () => interopDefault(import('../pages/account/articles/create/index.vue' /* webpackChunkName: "pages/account/articles/create/index" */))
const _6eef3894 = () => interopDefault(import('../pages/login/oauth/callback/index.vue' /* webpackChunkName: "pages/login/oauth/callback/index" */))
const _85fa0f3c = () => interopDefault(import('../pages/register/student/children/index.vue' /* webpackChunkName: "pages/register/student/children/index" */))
const _5f3cf119 = () => interopDefault(import('../pages/account/knowledge-base/lesson/create/index.vue' /* webpackChunkName: "pages/account/knowledge-base/lesson/create/index" */))
const _529dd969 = () => interopDefault(import('../pages/account/program/lesson/create/index.vue' /* webpackChunkName: "pages/account/program/lesson/create/index" */))
const _8cbf9466 = () => interopDefault(import('../pages/register/student/children/0-14/step/1.vue' /* webpackChunkName: "pages/register/student/children/0-14/step/1" */))
const _8ca36564 = () => interopDefault(import('../pages/register/student/children/0-14/step/2.vue' /* webpackChunkName: "pages/register/student/children/0-14/step/2" */))
const _8c873662 = () => interopDefault(import('../pages/register/student/children/0-14/step/3.vue' /* webpackChunkName: "pages/register/student/children/0-14/step/3" */))
const _32cf19e8 = () => interopDefault(import('../pages/register/student/children/14-18/step/1.vue' /* webpackChunkName: "pages/register/student/children/14-18/step/1" */))
const _32b2eae6 = () => interopDefault(import('../pages/register/student/children/14-18/step/2.vue' /* webpackChunkName: "pages/register/student/children/14-18/step/2" */))
const _3296bbe4 = () => interopDefault(import('../pages/register/student/children/14-18/step/3.vue' /* webpackChunkName: "pages/register/student/children/14-18/step/3" */))
const _1d650c26 = () => interopDefault(import('../pages/register/student/children/18/step/1.vue' /* webpackChunkName: "pages/register/student/children/18/step/1" */))
const _1d7323a7 = () => interopDefault(import('../pages/register/student/children/18/step/2.vue' /* webpackChunkName: "pages/register/student/children/18/step/2" */))
const _69de7d4f = () => interopDefault(import('../pages/account/knowledge-base/lesson/edit/_id.vue' /* webpackChunkName: "pages/account/knowledge-base/lesson/edit/_id" */))
const _7e6d022c = () => interopDefault(import('../pages/account/knowledge-base/lesson/view/_id.vue' /* webpackChunkName: "pages/account/knowledge-base/lesson/view/_id" */))
const _74200d9f = () => interopDefault(import('../pages/account/program/lesson/edit/_id.vue' /* webpackChunkName: "pages/account/program/lesson/edit/_id" */))
const _69e9e18c = () => interopDefault(import('../pages/account/program/lesson/view/_id.vue' /* webpackChunkName: "pages/account/program/lesson/view/_id" */))
const _5f5c94e0 = () => interopDefault(import('../pages/account/articles-moderation/edit/_id.vue' /* webpackChunkName: "pages/account/articles-moderation/edit/_id" */))
const _0ebbcd45 = () => interopDefault(import('../pages/account/articles/edit/_id.vue' /* webpackChunkName: "pages/account/articles/edit/_id" */))
const _7a490adf = () => interopDefault(import('../pages/account/articles-moderation/_id.vue' /* webpackChunkName: "pages/account/articles-moderation/_id" */))
const _4531314c = () => interopDefault(import('../pages/account/articles/_id.vue' /* webpackChunkName: "pages/account/articles/_id" */))
const _4f5a8ebc = () => interopDefault(import('../pages/account/feedback/_id.vue' /* webpackChunkName: "pages/account/feedback/_id" */))
const _7d7b1546 = () => interopDefault(import('../pages/account/knowledge-base/_id.vue' /* webpackChunkName: "pages/account/knowledge-base/_id" */))
const _579dfdad = () => interopDefault(import('../pages/account/program/_id.vue' /* webpackChunkName: "pages/account/program/_id" */))
const _c0f3dc78 = () => interopDefault(import('../pages/account/scheduled-lessons/_id.vue' /* webpackChunkName: "pages/account/scheduled-lessons/_id" */))
const _2bd24112 = () => interopDefault(import('../pages/publications/_slug.vue' /* webpackChunkName: "pages/publications/_slug" */))
const _e542f99c = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _079375d0,
    name: "account"
  }, {
    path: "/favorites",
    component: _a4d25392,
    name: "favorites"
  }, {
    path: "/forgot",
    component: _3b55f71a,
    name: "forgot"
  }, {
    path: "/login",
    component: _5e75dbe8,
    name: "login"
  }, {
    path: "/publications",
    component: _2d88e25a,
    name: "publications"
  }, {
    path: "/register",
    component: _47873394,
    name: "register"
  }, {
    path: "/account/agroclasses",
    component: _22abdc3e,
    name: "account-agroclasses"
  }, {
    path: "/account/applications",
    component: _03c43c15,
    name: "account-applications"
  }, {
    path: "/account/articles",
    component: _5113f072,
    name: "account-articles"
  }, {
    path: "/account/articles-moderation",
    component: _3c1ac6b7,
    name: "account-articles-moderation"
  }, {
    path: "/account/feedback",
    component: _3e700fba,
    name: "account-feedback"
  }, {
    path: "/account/knowledge-base",
    component: _ab38ec96,
    name: "account-knowledge-base"
  }, {
    path: "/account/profile",
    component: _6a0551a7,
    name: "account-profile"
  }, {
    path: "/account/program",
    component: _17fe3e05,
    name: "account-program"
  }, {
    path: "/account/scheduled-lessons",
    component: _0631a85c,
    name: "account-scheduled-lessons"
  }, {
    path: "/publications/search",
    component: _3e50665a,
    name: "publications-search"
  }, {
    path: "/register/student",
    component: _f772593c,
    name: "register-student"
  }, {
    path: "/register/teacher",
    component: _12e37849,
    name: "register-teacher"
  }, {
    path: "/account/agreements/generate",
    component: _46362b19,
    name: "account-agreements-generate"
  }, {
    path: "/account/articles/create",
    component: _973553e2,
    name: "account-articles-create"
  }, {
    path: "/login/oauth/callback",
    component: _6eef3894,
    name: "login-oauth-callback"
  }, {
    path: "/register/student/children",
    component: _85fa0f3c,
    name: "register-student-children"
  }, {
    path: "/account/knowledge-base/lesson/create",
    component: _5f3cf119,
    name: "account-knowledge-base-lesson-create"
  }, {
    path: "/account/program/lesson/create",
    component: _529dd969,
    name: "account-program-lesson-create"
  }, {
    path: "/register/student/children/0-14/step/1",
    component: _8cbf9466,
    name: "register-student-children-0-14-step-1"
  }, {
    path: "/register/student/children/0-14/step/2",
    component: _8ca36564,
    name: "register-student-children-0-14-step-2"
  }, {
    path: "/register/student/children/0-14/step/3",
    component: _8c873662,
    name: "register-student-children-0-14-step-3"
  }, {
    path: "/register/student/children/14-18/step/1",
    component: _32cf19e8,
    name: "register-student-children-14-18-step-1"
  }, {
    path: "/register/student/children/14-18/step/2",
    component: _32b2eae6,
    name: "register-student-children-14-18-step-2"
  }, {
    path: "/register/student/children/14-18/step/3",
    component: _3296bbe4,
    name: "register-student-children-14-18-step-3"
  }, {
    path: "/register/student/children/18/step/1",
    component: _1d650c26,
    name: "register-student-children-18-step-1"
  }, {
    path: "/register/student/children/18/step/2",
    component: _1d7323a7,
    name: "register-student-children-18-step-2"
  }, {
    path: "/account/knowledge-base/lesson/edit/:id?",
    component: _69de7d4f,
    name: "account-knowledge-base-lesson-edit-id"
  }, {
    path: "/account/knowledge-base/lesson/view/:id?",
    component: _7e6d022c,
    name: "account-knowledge-base-lesson-view-id"
  }, {
    path: "/account/program/lesson/edit/:id?",
    component: _74200d9f,
    name: "account-program-lesson-edit-id"
  }, {
    path: "/account/program/lesson/view/:id?",
    component: _69e9e18c,
    name: "account-program-lesson-view-id"
  }, {
    path: "/account/articles-moderation/edit/:id",
    component: _5f5c94e0,
    name: "account-articles-moderation-edit-id"
  }, {
    path: "/account/articles/edit/:id?",
    component: _0ebbcd45,
    name: "account-articles-edit-id"
  }, {
    path: "/account/articles-moderation/:id",
    component: _7a490adf,
    name: "account-articles-moderation-id"
  }, {
    path: "/account/articles/:id",
    component: _4531314c,
    name: "account-articles-id"
  }, {
    path: "/account/feedback/:id",
    component: _4f5a8ebc,
    name: "account-feedback-id"
  }, {
    path: "/account/knowledge-base/:id?",
    component: _7d7b1546,
    name: "account-knowledge-base-id"
  }, {
    path: "/account/program/:id",
    component: _579dfdad,
    name: "account-program-id"
  }, {
    path: "/account/scheduled-lessons/:id?",
    component: _c0f3dc78,
    name: "account-scheduled-lessons-id"
  }, {
    path: "/publications/:slug",
    component: _2bd24112,
    name: "publications-slug"
  }, {
    path: "/",
    component: _e542f99c,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
