import { BaseModel, CityModel, SettlementModel } from '.'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'

export default class Region extends BaseModel {
  id: string = ''
  title: string = ''
  withType: string = ''
  settlements: Array<SettlementModel> = []
  cities: Array<CityModel> = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['settlements', 'cities'])

      this.title = String(data.withType)

      if (Array.isArray(data.cities) && data.cities.length > 0) {
        this.cities = data.cities.map((i) => new CityModel(i))
      }

      if (Array.isArray(data.settlements) && data.settlements.length > 0) {
        this.settlements = data.settlements.map((i) => new SettlementModel(i))
      }
    }
  }
}
