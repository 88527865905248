// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Medium.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Golos;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:400;font-style:normal}@font-face{font-family:Golos;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:500;font-style:normal}.svoi-icon[data-v-0116ee64]{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex}.svoi-icon.-size-xs[data-v-0116ee64]{width:12px;min-width:12px;height:12px;min-height:12px}.svoi-icon.-size-s[data-v-0116ee64]{width:16px;min-width:16px;height:16px;min-height:16px}.svoi-icon.-size-m[data-v-0116ee64]{width:24px;min-width:24px;height:24px;min-height:24px}.svoi-icon.-size-l[data-v-0116ee64]{width:32px;min-width:32px;height:32px;min-height:32px}.svoi-icon.-size-full[data-v-0116ee64]{width:100%;height:100%}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
