export enum PermissionsEnum {
  // ------- profile ----- //
  editPersonalData = 'edit personal data',
  editShortPersonalData = 'edit short personal data',
  editFullPersonalData = 'edit full personal data',
  changePassword = 'change password',
  uploadProfilePhoto = 'upload profile photo',
  uploadAgreementsDocuments = 'upload agreements documents',
  generateAgreementsDocuments = 'generate agreements documents',
  // ------- learning base ----- //
  viewLearningBaseActiveSubject = 'view learning knowledge base active subject',
  viewAnyLearningBaseActiveSubject = 'view any learning knowledge base active subject',
  viewAnyLearningBaseBlock = 'view any learning knowledge base block',
  createLearningBaseBlock = 'create learning knowledge base block',
  updateLearningBaseBlock = 'update learning knowledge base block',
  deleteLearningBaseBlock = 'delete learning knowledge base block',
  viewAnyLearningBaseLesson = 'view any learning knowledge base lesson',
  viewLearningKnowledgeBaseLesson = 'view learning knowledge base lesson',
  viewLearningKnowledgeBaseActiveLesson = 'view learning knowledge base active lesson',
  createLearningKnowledgeBaseLesson = 'create learning knowledge base lesson',
  updateLearningKnowledgeBaseLesson = 'update learning knowledge base lesson',
  // ------- learning program ----- //
  viewLearningProgramSubject = 'view learning program subject',
  viewAnyLearningProgramSubject = 'view any learning program subject',
  createLearningProgramSubject = 'create learning program subject',
  updateLearningProgramSubject = 'update learning program subject',
  deleteLearningProgramSubject = 'delete learning program subject',
  viewAnyLearningProgramBlock = 'view any learning program block',
  createLearningProgramBlock = 'create learning program block',
  updateLearningProgramBlock = 'update learning program block',
  deleteLearningProgramBlock = 'delete learning program block',
  viewAnyLearningProgramLesson = 'view any learning program lesson',
  viewLearningProgramLesson = 'view learning program lesson',
  createLearningProgramLesson = 'create learning program lesson',
  updateLearningProgramLesson = 'update learning program lesson',
  // ------- learning class ----- //
  assignLearningProgramLessonToClass = 'assign learning program lesson to class',
  viewAnyLearningClassLesson = 'view any learning class lesson',
  viewLearningClassLesson = 'view learning class lesson',
  withdrawLearningProgramLessonFromClass = 'withdraw learning program lesson from class',
  createSchoolsClass = 'create schools class',
  updateSchoolsClass = 'update schools class',
  deleteSchoolsClass = 'delete schools class',
  // ------- comments ----- //
  commentEntity = 'comment entity',
  // ------- rating ----- //
  rateEntity = 'rate entity',
  // ------- schools ----- //
  viewAnySchoolsStudentClass = 'view any schools student class',
  viewAnySchoolsCuratorClass = 'view any schools curator class',
  viewAnySchoolsClassJoinRequest = 'view any schools class join request',
  approveSchoolsClassJoinRequest = 'approve schools class join request',
  rejectSchoolsClassJoinRequest = 'reject schools class join request',
  createClassJoinRequest = 'create class join request',
  // ------- articles ----- //
  viewAnyMyPublications = 'view any my publications',
  viewAnyPublication = 'view any publication',
  createPublication = 'create publication',
  updatePublication = 'update publication',
  updateAnyPublication = 'update any publication',
  // ------- feedback ----- //
  viewAnyFeedback = 'view any feedback',
}
