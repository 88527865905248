import { useContext } from '@nuxtjs/composition-api'

export const useClipboard = () => {
  const ctx = useContext()

  const copyToClipboard = (value: string) => {
    if (navigator && navigator.clipboard) {
      navigator.clipboard.writeText(value).then(
        () => ctx.$tstore.notifications.pushInfo('Данные успешно скопированы в буфер обмена.'),
        () => ctx.$tstore.notifications.pushError('Произошла ошибка при копировании в буфер обмена.')
      )
    } else {
      ctx.$tstore.notifications.pushError('Ваш браузер не поддерживает работу с буфером обмена.')
    }
  }

  return { copyToClipboard }
}
