
import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api'
import { SvoiPagination } from '~/packages/svoi-ui/components/pagination'

export default defineComponent({
  name: 'PaginationWrapper',
  components: { SvoiPagination },
  props: {
    baseUrl: {
      type: String,
      required: true,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
    activePage: {
      type: Number,
      default: 1,
    },
    width: {
      type: String,
      default: '100%',
    },
  },
  setup() {
    const maxPoints = ref(9)

    onMounted(() => {
      if (window.innerWidth < 768) {
        maxPoints.value = 7
      }
    })

    return { maxPoints }
  },
})
