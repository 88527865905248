import { gql } from 'graphql-tag'
import { PaginationFields } from '~/shared/gql/fragments'

export default gql`
  ${PaginationFields(`SchoolsClassTypePaginator`)}
  query getPostfixClasses(
    $first: Int = 100
    $page: Int
    $filter: SchoolsClassesFilterInput
    $sort: [QuerySchoolsClassesSortOrderByClause!]
  ) {
    schoolsClasses(first: $first, page: $page, filter: $filter, sort: $sort) {
      data {
        id
        postfix
      }
      ...PaginationFields
    }
  }
`
