import { gql } from 'graphql-tag'
import { ArticleFieldsForCard } from '~/entities/article/gql/fragments'

export default gql`
  ${ArticleFieldsForCard}
  query mainPage {
    activePublicationsMainPage {
      top {
        ...ArticleFieldsForCard
      }
      bottomLeft {
        ...ArticleFieldsForCard
      }
      bottomCenter {
        ...ArticleFieldsForCard
      }
      bottomRight {
        ...ArticleFieldsForCard
      }
      interesting {
        ...ArticleFieldsForCard
      }
      fresh {
        ...ArticleFieldsForCard
      }
      news {
        ...ArticleFieldsForCard
      }
    }
  }
`
