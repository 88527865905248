import { render, staticRenderFns } from "./SvoiButtonLink.vue?vue&type=template&id=44b0cb03&"
import script from "./SvoiButtonLink.vue?vue&type=script&lang=ts&"
export * from "./SvoiButtonLink.vue?vue&type=script&lang=ts&"
import style0 from "./SvoiButtonLink.vue?vue&type=style&index=0&id=44b0cb03&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports