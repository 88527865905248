
import { computed, defineComponent, reactive, ref, useContext, useFetch, useRouter } from '@nuxtjs/composition-api'
import { ThemesEnum } from '@svoi-ui/interfaces/label'
import { SvoiLabel } from '@svoi-ui/components/label'
import { SvoiTextarea } from '@svoi-ui/components/textarea'
import { useVuelidate } from '@vuelidate/core'
import { SvoiButton } from '@svoi-ui/components/button'
import type { FeedbackModel } from '~/entities/feedback/model'
import type { UserProfileModel } from '~/entities/user/model'
import { previewUserFIO } from '~/shared/utils/helpers'
import { toDateFormat } from '~/shared/utils/date'
import { requiredRule } from '~/shared/utils/validation'
import { TransitionTypeEnum } from '~/entities/workflow/enum'
import type { TransitionModel } from '~/entities/workflow/model'
import { useErrors } from '~/composables'
import type { CloseFeedbackInput } from '~/entities/feedback/interface'

export default defineComponent({
  components: { SvoiButton, SvoiTextarea, SvoiLabel },

  props: {
    feedbackId: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const ctx = useContext()
    const router = useRouter()
    const { ignoreError, displayError } = useErrors()

    const feedback = ref<FeedbackModel>()

    const loading = ref<boolean>(false)
    const availableTransitions = ref<Array<TransitionModel>>([])

    const form = useVuelidate({ comment: requiredRule }, reactive({ comment: '' }))

    const attachment = computed(() => feedback.value?.attachments[0] || null)

    const getFeedback = () => {
      ctx.$services.feedback.getFeedback(props.feedbackId).then((data) => {
        feedback.value = data
      })
    }

    const comment = computed(() => feedback.value?.comments.data?.[0]?.message ?? '')

    const getThemeLabel = (type: string): ThemesEnum => {
      switch (type) {
        case 'feedback_new':
          return ThemesEnum.chilli
        case 'feedback_processed':
          return ThemesEnum.figs
        default:
          return ThemesEnum.qiwi
      }
    }

    const getFIOUser = (profile: UserProfileModel) => {
      return previewUserFIO(profile.surName, profile.name, profile.middleName)
    }

    const getAvailableTransitions = () => {
      ignoreError(
        ctx.$services.workflow.getAvailableTransitions(TransitionTypeEnum.feedback, props.feedbackId).then((data) => {
          availableTransitions.value = data
        })
      )
    }

    const onSubmit = (transitionId: string) => {
      form.value.$validate().then((isValid) => {
        if (isValid) {
          loading.value = true

          const input: CloseFeedbackInput = {
            id: props.feedbackId,
            transitionId,
            message: form.value.comment.$model,
          }

          displayError(
            ctx.$services.feedback
              .closeFeedbackMessage(input)
              .then(() => {
                router.push('/account/feedback')
              })
              .finally(() => (loading.value = false))
          )
        }
      })
    }

    useFetch(() => getAvailableTransitions())
    useFetch(() => getFeedback())

    return {
      comment,
      availableTransitions,
      form,
      loading,
      attachment,
      feedback,
      getThemeLabel,
      getFIOUser,
      toDateFormat,
      onSubmit,
    }
  },
})
