import { SectionForm, TestForm } from '.'
import { requiredContentEditor, requiredRule } from '~/shared/utils/validation'
import type { SubjectModel } from '~/entities/subject/model'
import type { LearningBlockModel } from '~/entities/learning-block/model'
import { CreateFormSections, CreateLessonInput, SectionDataInput } from '~/entities/lesson/interface'
import { LessonSectionTypesEnum } from '~/entities/lesson/enums'
import type { MediaModel } from '~/shared/model'

export default class CreateForm {
  title: string = ''
  description: string = ''
  subject: SubjectModel | null = null
  learningBlock: LearningBlockModel | null = null
  preview: Array<File | MediaModel> = []
  sections: CreateFormSections = {
    [LessonSectionTypesEnum.student]: new SectionForm(),
    [LessonSectionTypesEnum.teacher]: new SectionForm(),
    [LessonSectionTypesEnum.quiz]: new TestForm(),
  }

  getInputData(): CreateLessonInput {
    const preview = this.toInputPreview()

    const sections = Object.keys(this.sections).map((k) => {
      const key = k as LessonSectionTypesEnum
      const content = this.sections[key].content

      return {
        sectionType: k,
        content: content ? JSON.stringify(content) : '',
        documents: this.toInputDocuments(key),
        items: key === LessonSectionTypesEnum.quiz ? this.toInputQuizItemsSection() : {},
      }
    })

    const data: CreateLessonInput = {
      title: this.title,
      description: this.description,
      blockId: this.learningBlock?.id ?? '',
      sections: { create: sections },
    }

    if (preview) {
      data.preview = preview
    }

    return data
  }

  toInputQuizItemsSection() {
    const quiz = this.sections[LessonSectionTypesEnum.quiz]
    const quizData = quiz.toInputData()

    return {
      quizzes: {
        [quizData.id ? 'update' : 'create']: [quizData],
      },
    }
  }

  toInputDocuments(section: LessonSectionTypesEnum): SectionDataInput['documents'] {
    const result: SectionDataInput['documents'] = []

    for (const [order, document] of this.sections[section].documents.entries()) {
      if (document instanceof File) {
        result.push({ action: 'CREATE', file: document, order })
      }
    }

    return result
  }

  toInputPreview(): CreateLessonInput['preview'] | undefined {
    const firstFile = this.preview[0]
    if (firstFile instanceof File) {
      return { action: 'CREATE', file: firstFile }
    }

    return undefined
  }

  static rules() {
    return {
      title: requiredRule,
      subject: requiredRule,
      learningBlock: requiredRule,
      description: requiredRule,
      preview: [],
      sections: {
        [LessonSectionTypesEnum.student]: {
          content: requiredContentEditor,
          documents: [],
        },
        [LessonSectionTypesEnum.teacher]: {
          content: [],
          documents: [],
        },
        [LessonSectionTypesEnum.quiz]: [],
      },
    }
  }
}
