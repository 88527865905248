
import { computed, defineComponent, reactive } from '@nuxtjs/composition-api'
import DefaultLayout from './default.vue'
import { Sidebar as SidebarClass } from '~/shared/class'
import { Sidebar } from '~/widgets/sidebar'

export default defineComponent({
  name: 'SidebarLayout',
  components: { Sidebar, DefaultLayout },

  setup() {
    const sidebar = reactive(SidebarClass.getInstance())

    const classStatus = computed(() => (sidebar.isOpen ? 'opened' : 'closed'))

    return { classStatus }
  },
})
