import { BaseModel } from '.'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'

export default class City extends BaseModel {
  id: string = ''
  value: string = ''
  withType: string = ''
  title: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      this.title = String(data.withType)
    }
  }
}
