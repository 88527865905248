
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { SvoiNotification } from '@svoi-ui/components/notification'
import { Notification, NotificationItem, Positions, PositionsEnum, TypesEnum } from '@svoi-ui/interfaces/notification'

export default defineComponent({
  name: 'SvoiNotificationsList',
  components: { SvoiNotification },
  props: {
    value: {
      type: Array as PropType<Array<Notification>>,
      default: () => []
    },
    position: {
      type: String as PropType<Positions>,
      default: PositionsEnum.rightTop,
      validator: (value: string) => {
        return [
          PositionsEnum.rightTop.toString(),
          PositionsEnum.rightBottom.toString(),
          PositionsEnum.leftTop.toString(),
          PositionsEnum.leftBottom.toString()
        ].includes(value)
      }
    }
  },
  emits: ['input'],
  setup(props, { emit }) {
    const timers: Map<string, ReturnType<typeof setTimeout>> = new Map()

    const computedValue = computed<Array<NotificationItem>>(() => {
      return props.value?.map(i => {
        if (i.timeout && !timers.has(i.id)) {
          const timeout = setTimeout(() => excludeNotification(i), i.timeout)
          timers.set(i.id, timeout)
        }

        return {
          id: i.id,
          title: i.title,
          type: i.type ?? TypesEnum.info,
          text: i.text ?? '',
          collapse: i.collapse ?? false,
          buttons: i.buttons ?? [],
          componentContent: i.componentContent
        }
      })
    })

    const classes = computed(() => ({
      [`-position-${props.position}`]: true
    }))

    const excludeNotification = (notification: Notification) => {
      emit(
        'input',
        props.value?.filter(i => i.id !== notification.id)
      )
      timers.delete(notification.id)
    }

    const onClose = (notification: Notification) => excludeNotification(notification)

    return { classes, computedValue, onClose }
  }
})
