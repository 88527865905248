import { gql } from 'graphql-tag'
import { ArticleMainFields } from '.'
import { FileFields } from '~/shared/gql/fragments'

export default gql`
  ${ArticleMainFields}
  ${FileFields('MediaType')}
  fragment ArticleFieldsForCard on PublicationType {
    ...ArticleMainFields
    rating
    preview {
      ...FileFields
    }
    author {
      id
      profile {
        name
        surName
        middleName
      }
    }
    tags {
      id
      name
    }
    comments(first: 9999) {
      paginatorInfo {
        total
      }
    }
  }
`
