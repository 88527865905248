import { gql } from 'graphql-tag'
import { ProfileFields } from '~/entities/user/gql/fragments'

export default gql`
  ${ProfileFields('User')}
  query GetUserProfile {
    me {
      ...ProfileFields
    }
  }
`
