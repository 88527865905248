import { UnknownObject } from '~/shared/interface'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { SocialProviderEnum } from '~/entities/user/enums'
import { BaseModel } from '~/shared/model'

export default class SocialRedirect extends BaseModel {
  link: string = ''
  provider: SocialProviderEnum | null = null

  constructor(data: UnknownObject) {
    super()

    fillOwnProperties(this, data)
  }
}
