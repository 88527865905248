import { UserModel } from '.'
import { BaseModel } from '~/shared/model'
import type { ApplicationStatus } from '~/entities/user/interfaces'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'
import { ApplicationStatusesEnum } from '~/shared/enums'
import { ClassInstitutionModel } from '~/entities/class/model'

export default class Application extends BaseModel {
  id: string = ''
  student: UserModel = new UserModel(null)
  status: ApplicationStatus = ApplicationStatusesEnum.REVIEW
  class: ClassInstitutionModel = new ClassInstitutionModel(null)

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['student', 'class'])

      if (isUnknownObject(data.student)) {
        this.student = new UserModel(data.student)
      }

      if (isUnknownObject(data.class)) {
        this.class = new ClassInstitutionModel(data.class)
      }
    }
  }
}
