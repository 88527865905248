import { onMounted, onUnmounted, useContext } from '@nuxtjs/composition-api'
import type { ListenerFn, event, eventNS } from 'eventemitter2'

export const useEmitter = () => {
  const ctx = useContext()

  const activateMountedListener = (event: event | eventNS, fn: ListenerFn) => {
    onMounted(() => ctx.$emitter.on(event, fn))
    onUnmounted(() => ctx.$emitter.off(event, fn))
  }

  return { activateMountedListener }
}
