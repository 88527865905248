import { CreateClassNameForm } from '../../class/api'
import { getUIUniqId } from '~/shared/utils/helpers'
import type { UIUniqId } from '~/shared/types'
import { requiredRule } from '~/shared/utils/validation'

export default class RegisterTeacherClassNameForm extends CreateClassNameForm {
  classId: string | UIUniqId = getUIUniqId()

  static rules() {
    return {
      ...CreateClassNameForm.rules(),
      classId: requiredRule,
    }
  }
}
