import { ApolloService } from '~/shared/api/index'
import { isUnknownObject } from '~/shared/utils/guards'
import { UploadTemporaryFile } from '~/shared/gql/mutations'

class FileService {
  protected apolloService: ApolloService

  constructor(apolloService: ApolloService) {
    this.apolloService = apolloService
  }

  public uploadFile(file: File): Promise<string> {
    return this.apolloService.executeMutation(UploadTemporaryFile, { file }).then(({ data }) => {
      if (isUnknownObject(data) && typeof data.uploadTemporaryFile === 'string') {
        return data.uploadTemporaryFile
      }

      throw new Error('Upload file error')
    })
  }
}

export default FileService
