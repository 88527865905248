export enum EntityEnum {
  article = 'PUBLICATION',
}

export const isEntityKey = (value: unknown): value is keyof typeof EntityEnum => {
  return typeof value === 'string' && ['article'].includes(value)
}

export const keyOfEntity = (value: EntityEnum): keyof typeof EntityEnum => {
  switch (value) {
    case EntityEnum.article:
      return 'article'
  }
}
