import { gql } from 'graphql-tag'

export default (on: string) => gql`
  fragment ClassFields on ${on} {
    id
    name
    title
    description
    postfix

    educationalProgram {
      id
      value
    }

    institution {
      id
      organization {
        value
        name {
          short
          full
        }
      }
    }
  }
`
