import { gql } from 'graphql-tag'
import { ArticleFull } from '~/entities/article/gql/fragments'
import type { ArticleListType } from '~/entities/article/interface'

export default (type: ArticleListType) => {
  let data

  switch (type) {
    case 'my': {
      data = 'myPublication'
      break
    }
    case 'moderation': {
      data = 'publication'
      break
    }
    default: {
      data = 'activePublication'
      break
    }
  }

  return gql`
    ${ArticleFull}
    query getArticleBySlug($slug: String) {
      ${data}(slug: $slug) {
        ...ArticleFull
      }
    }
  `
}
