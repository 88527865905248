import type { CacheOptions, CacheEngine, CacheTypes, CacheValues } from '~/shared/interface'
import { LRU } from '~/shared/cache'

function cacheFactory<V extends CacheValues, K = string>(
  type: CacheTypes,
  options: CacheOptions = {}
): CacheEngine<V, K> {
  switch (type) {
    case 'LRU':
    default:
      return new LRU(options)
  }
}

export function cache<T extends { new (...args: any[]): any }>(type: CacheTypes = 'LRU', options: CacheOptions = {}) {
  return function innerCache<V extends CacheValues, K = string>(target: T) {
    return class extends target {
      protected _cacheInstance: CacheEngine<V, K>

      constructor(...args: any[]) {
        super(...args)

        this._cacheInstance = cacheFactory(type, options)
      }
    }
  }
}
