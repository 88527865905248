import type { Context, Middleware } from '@nuxt/types'
import type { Route } from 'vue-router'

export const isPrivateRoute = (route: Route): boolean => {
  return typeof route.name === 'string' && /^account/.test(route.name)
}

/**
 * Проверка доступа к роуту на основании текущих прав
 */
const checkAccess: Middleware = ({ $tstore, route, redirect }: Context) => {
  const user = $tstore.user.authorizedUser
  if (isPrivateRoute(route)) {
    if (user === null) {
      redirect('/')
      return
    }

    const hasAccess = user.allowedRoutes.reduce((initial, rgx) => {
      return initial || rgx.test(route.path)
    }, false)

    if (!hasAccess) {
      redirect('/')
    }
  }
}

export default checkAccess
