import { Context } from '@nuxt/types'

export default function (context: Context) {
  if (context.$tstore.user.authorizedUser === null) {
    return true
  }

  const routePath = context.route.path
  const verifyRoutes: Array<RegExp> = [/^\/login/, /^\/forgot/, /^\/register/]
  const isUnavailable = verifyRoutes.reduce((initial, i) => {
    return initial || i.test(routePath)
  }, false)

  if (isUnavailable) {
    return context.redirect('/account')
  }
}
