import { isApolloError } from 'apollo-client/errors/ApolloError'
import { isNumber, isString, isUnknownObject } from '~/shared/utils/guards'
import { UnknownObject } from '~/shared/interface'
import { codesResponse, errorApiTypePrefix } from '~/shared/const/response'
import { createErrorApiType } from '~/shared/utils/errors'
import { DeepImmutable, DeepMutable } from '~/shared/utils/generics'
import type { ErrorApiType } from '~/shared/types'

export default class ErrorApi extends Error {
  readonly message: string = ''
  readonly data: DeepImmutable<UnknownObject> = {}
  readonly code: number = 0
  readonly type: ErrorApiType = createErrorApiType()
  readonly validation: DeepImmutable<Record<string, string[]>> = {}

  constructor(error: Error, options?: ErrorOptions) {
    super(undefined, options)

    if (isApolloError(error)) {
      const { message, graphQLErrors } = error
      const e = graphQLErrors[0]

      this.message = message

      if (e) {
        const { extensions } = e

        if (isNumber(extensions.code)) {
          this.code = extensions.code
        }

        if (isUnknownObject(extensions.data)) {
          this.data = <DeepMutable<UnknownObject>>extensions.data
        }

        if (isUnknownObject(extensions.validation)) {
          const validation: Record<string, string[]> = {}
          Object.keys(extensions.validation).forEach((key) => {
            if (/^input\./.test(key)) {
              const value = extensions.validation[key]
              const field = key.replace(/^input\./, '')
              if (Array.isArray(value) && value.length) {
                validation[field] = value.map((v) => String(v))
              }
            }
          })

          this.validation = validation
        }
      }
    }
  }

  equalCode(targetCode: keyof typeof codesResponse) {
    return this.code === codesResponse[targetCode]
  }

  static isErrorApi(error: unknown): error is ErrorApi {
    return isUnknownObject(error) && isString(error.type) && error.type.includes(errorApiTypePrefix)
  }
}
