import { helpers } from '@vuelidate/validators'
import { fillOwnProperties, getUIUniqId } from '~/shared/utils/helpers'
import { AtLeastOne, WithoutPrivate } from '~/shared/utils/generics'
import { requiredRule } from '~/shared/utils/validation'
import { AnswerForm } from '~/entities/lesson/api'
import { LessonSectionQuizQuestionType } from '~/entities/lesson/enums'
import { TestQuestionModel } from '~/entities/lesson/model'
import { isNonEmptyArray, isUIUniqid } from '~/shared/utils/guards'
import type { SectionDataQuizQuestionInput, TestAnswer, TestQuestion } from '~/entities/lesson/interface'
import type { UIUniqId } from '~/shared/types'

export default class QuestionForm {
  id: string | UIUniqId = getUIUniqId()
  title: string = ''
  description: string = ''
  content: string = ''
  answers: Map<string | UIUniqId, WithoutPrivate<AnswerForm>> = new Map()
  questionType: LessonSectionQuizQuestionType = LessonSectionQuizQuestionType.single
  order: number = 1

  constructor(question?: AtLeastOne<TestQuestion>) {
    fillOwnProperties(this, { ...question }, ['answers'])

    if (question) {
      const entityAnswers = Array.from(question.answers?.values() || [])

      if (isNonEmptyArray(entityAnswers)) {
        for (const answer of entityAnswers) {
          this.addAnswer(answer)
        }
      }
    } else {
      this.setInitialAnswers()
    }
  }

  setInitialAnswers() {
    this.addAnswer({ order: this.answers.size + 1 })
    this.addAnswer({ order: this.answers.size + 1 })
  }

  toInputData(existQuestion?: TestQuestionModel): SectionDataQuizQuestionInput {
    const newAnswers: Required<SectionDataQuizQuestionInput['answers']> = {
      create: [],
      update: [],
      delete: [],
    }

    if (existQuestion) {
      for (const existAnswer of Array.from(existQuestion.answers.values())) {
        if (!this.answers.has(existAnswer.id)) {
          newAnswers.delete.push(existAnswer.id)
        }
      }
    }

    for (const [id, answer] of this.answers) {
      const existAnswer = existQuestion?.answers.get(id)
      const data = answer.toInputData()

      if (existAnswer && !isUIUniqid(id)) {
        newAnswers.update.push(data)
      } else {
        newAnswers.create.push(data)
      }
    }

    return {
      ...(this.id && !isUIUniqid(this.id) ? { id: this.id } : {}),
      title: this.title,
      description: this.description,
      content: this.content,
      order: this.order,
      questionType: this.questionType,
      answers: newAnswers,
    }
  }

  private addAnswer(answer?: AtLeastOne<TestAnswer>) {
    const answerModel = new AnswerForm(answer)

    this.answers.set(answerModel.id, answerModel)
  }

  static rules() {
    return {
      id: [],
      title: requiredRule,
      questionType: [],
      answers: {
        atLeastOne: helpers.withMessage(
          'Укажите хотя бы 1 правильный вариант ответа',
          (answers: Map<string, TestAnswer>) => Array.from(answers.values()).filter((a) => a.points).length > 0
        ),
      },
      order: [],
    }
  }
}
