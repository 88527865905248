
import { defineComponent, ref } from 'vue'
import { computed, PropType } from '@nuxtjs/composition-api'

const __default__ = defineComponent({
  props: {
    closeOnClickOutside: {
      type: Boolean,
      default: true,
    },
    closeOnClick: {
      type: Boolean,
      default: true,
    },
    position: {
      type: Object as PropType<{
        vertical?: 'top' | 'bottom'
        horizontal?: 'left' | 'right' | 'center'
      }>,
      default: () => ({ vertical: 'bottom', horizontal: 'center' }),
    },
    preventAutoToggle: {
      type: Boolean,
      default: false,
    },
    transitionDuration: {
      type: Number,
      default: 0.3,
    },
  },
  emits: ['input', 'clickOutside'],

  setup(props, { emit }) {
    const dropdownOpen = ref<boolean>(false)

    const toggleDropdown = (value = !dropdownOpen.value) => {
      dropdownOpen.value = value

      emit('input', value)
    }

    const onClickOutside = () => {
      if (props.closeOnClickOutside) {
        if (dropdownOpen.value) {
          emit('clickOutside')
        }

        toggleDropdown(false)
      }
    }

    const onClickDropdown = () => {
      if (props.closeOnClick) {
        toggleDropdown(false)
      }
    }

    const dropdownPosition = computed(() => {
      return Object.values(props.position).map((position) => `content_${position}`)
    })

    return {
      duration: `${props.transitionDuration}s`,
      dropdownOpen,
      dropdownPosition,
      toggleDropdown,
      onClickOutside,
      onClickDropdown,
    }
  },
})

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars((_vm, _setup) => ({
  "6d58b5bc": (_vm.duration)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__