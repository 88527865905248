import { useContext, useRouter } from '@nuxtjs/composition-api'
import type { ModalConfirmData } from '~/shared/interface'
import { events, LOGIN_ROUTE, REDIRECT_KEY } from '~/shared/const'

export const useAuthorization = () => {
  const ctx = useContext()
  const router = useRouter()

  const showAuthorizationRedirect = () => {
    const data: ModalConfirmData = {
      show: true,
      title: 'Авторизация',
      description: 'Данный функционал доступен только авторизованным пользователям. Перейти на страницу авторизации ?',
      successCallback: () => router.push(LOGIN_ROUTE + `?${REDIRECT_KEY}=${window.location.pathname}`),
    }
    ctx.$emitter.emit(events.layout.showConfirm, data)
  }

  return { showAuthorizationRedirect }
}
