import { ClassInstitutionModel } from '.'
import { BaseModel } from '~/shared/model'
import { isNonEmptyArray, isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { EducationProgramModel } from '~/entities/education-program/model'

export default class Class extends BaseModel {
  id: string = ''
  name: string = ''
  title: string = ''
  description: string = ''
  postfix: string = ''
  institution: ClassInstitutionModel = new ClassInstitutionModel(null)
  educationalProgram: EducationProgramModel = new EducationProgramModel(null)

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['institution', 'educationalProgram'])

      if (isUnknownObject(data.institution)) {
        this.institution = new ClassInstitutionModel(data.institution)
      }

      if (Array.isArray(data.educationalProgram) && isNonEmptyArray(data.educationalProgram)) {
        this.educationalProgram = new EducationProgramModel(data.educationalProgram[0])
      }
    }
  }
}
