import Vue from 'vue'
import { Plugin } from '@nuxt/types'
import DOMPurify from 'isomorphic-dompurify'

const config = { ADD_ATTR: ['target'] }

const saneHtml: Plugin = () => {
  Vue.directive('sane-html', (el, binding) => {
    el.innerHTML = DOMPurify.sanitize(binding.value, config)
  })
}

export default saneHtml
