import type { ApolloClient } from 'apollo-client'
import type { ApolloHelpers } from '@nuxtjs/apollo'
import type { DocumentNode } from 'graphql/language/ast'
import { ErrorApi } from './'

export default class ApolloService {
  private apolloClient: ApolloClient<any>
  private apolloHelpers: ApolloHelpers

  constructor(client: ApolloClient<any>, helpers: ApolloHelpers) {
    this.apolloClient = client
    this.apolloHelpers = helpers
  }

  getQuery(query: DocumentNode, variables: object = {}) {
    return this.apolloClient
      .query({ query, variables, fetchPolicy: 'no-cache', errorPolicy: 'all' })
      .then((res) => res.data)
      .catch((rej) => {
        throw new ErrorApi(rej, { cause: { variables } })
      })
  }

  executeMutation(mutation: DocumentNode, variables: object = {}) {
    return this.apolloClient
      .mutate({ mutation, variables })
      .then((res) => ({
        data: res.data,
      }))
      .catch((rej) => {
        throw new ErrorApi(rej, { cause: { variables } })
      })
  }

  onLogin(token: string) {
    return this.apolloHelpers.onLogin(token)
  }

  onLogout() {
    return this.apolloHelpers.onLogout()
  }

  getToken() {
    return this.apolloHelpers.getToken()
  }
}
