import { gql } from 'graphql-tag'
import { LessonMainFields } from '~/entities/lesson/gql/fragments'
import { FileFields, PaginationFields } from '~/shared/gql/fragments'

export default gql`
  ${LessonMainFields('LearningClassLessonType')}
  ${FileFields('MediaType')}
  ${PaginationFields('LearningClassLessonTypePaginator')}
  query getScheduledLessons(
    $first: Int = 9999
    $page: Int
    $filter: LearningClassLessonsFilterInput
    $sort: [QueryLearningClassLessonsSortOrderByClause!]
  ) {
    learningClassLessons(first: $first, page: $page, filter: $filter, sort: $sort) {
      data {
        ...LessonMainFields
        dateEnd
        dateStart

        preview {
          ...FileFields
        }

        author {
          profile {
            middleName
            name
            surName
          }
        }

        comments(first: 9999) {
          paginatorInfo {
            count
          }
        }
      }
      ...PaginationFields
    }
  }
`
