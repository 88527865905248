
import { defineComponent, ref, useContext, useFetch, useRouter, watch } from '@nuxtjs/composition-api'
import { FeedbackTable, FeedbackFilters } from '.'
import type { FeedbackListFilters, FeedbackListParams } from '~/entities/feedback/interface'
import type { FeedbackModel } from '~/entities/feedback/model'
import { prepareFilters } from '~/shared/utils/helpers'
import { useErrors } from '~/composables'

export default defineComponent({
  components: { FeedbackTable, FeedbackFilters },

  setup() {
    const ctx = useContext()
    const router = useRouter()
    const { displayError } = useErrors()

    const filters = ref<FeedbackListFilters>({})
    const items = ref<Array<FeedbackModel>>([])
    const paginationInfo = ref({
      activePage: 1,
      totalPages: 1,
      baseUrl: ctx.route.value.fullPath,
    })
    const loading = ref<boolean>(false)

    const getQueryStatus = (): Array<string> | undefined => {
      const queryParams = ctx.route.value.query
      if (queryParams.status) {
        const statuses = Array.isArray(queryParams.status)
          ? (queryParams.status.filter((i) => !!i) as Array<string>)
          : [String(queryParams.status)]
        return statuses.length ? statuses : undefined
      }
    }

    const initFilters = () => {
      const queryParams = ctx.route.value.query

      filters.value = {
        status: getQueryStatus(),
        search: queryParams.search ? String(queryParams.search) : '',
        classifiers: queryParams.theme ? String(queryParams.theme) : undefined,
      }
    }

    const onChangeFilters = (updatedFilters: FeedbackListFilters) => {
      const params = { ...ctx.route.value.query }

      if (!updatedFilters.status?.length) {
        delete params.status
      } else {
        params.status = updatedFilters.status
      }

      if (!updatedFilters.search) {
        delete params.search
      } else {
        params.search = updatedFilters.search
      }

      if (!updatedFilters.classifiers) {
        delete params.theme
      } else {
        params.theme = updatedFilters.classifiers
      }

      if ('p' in params) {
        delete params.p
      }

      router.push({
        path: ctx.route.value.path,
        query: params,
      })
    }

    const getFeedbacks = () => {
      const page = Number(ctx.route.value.query.p)

      const params: FeedbackListParams = {
        first: 8,
        page: isNaN(page) || page < 1 ? 1 : page,
        filter: prepareFilters(filters.value),
        sort: [
          { column: 'STATUS', order: 'ASC' },
          { column: 'CREATED_AT', order: 'DESC' },
        ],
      }

      loading.value = true

      return displayError(
        ctx.$services.feedback
          .feedbackList(params)
          .then((data) => {
            items.value = data.models
            paginationInfo.value.activePage = data.pagination.currentPage
            paginationInfo.value.totalPages = data.pagination.lastPage
          })
          .finally(() => {
            loading.value = false
          })
      )
    }

    watch(
      () => ctx.route.value.query,
      () => {
        initFilters()
        getFeedbacks()
        paginationInfo.value.baseUrl = ctx.route.value.fullPath
      }
    )

    initFilters()
    useFetch(async () => await getFeedbacks())

    return { filters, items, loading, paginationInfo, onChangeFilters }
  },
})
