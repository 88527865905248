
import { computed, defineComponent, ref, useContext } from '@nuxtjs/composition-api'
import { useErrors, usePermissions } from '~/composables'
import { PermissionsEnum } from '~/shared/enums'

export default defineComponent({
  props: {
    isOpen: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const ctx = useContext()
    const { displayError } = useErrors()
    const { hasPermission } = usePermissions()

    const fileInput = ref<HTMLInputElement | null>(null)
    const errorPhoto = ref('')

    const hasEdit = computed(
      () => ctx.route.value.name === 'account-profile' && hasPermission(PermissionsEnum.uploadProfilePhoto)
    )
    const userPhoto = computed(() => ctx.$tstore.user.userPhoto || require('~/assets/images/account/no-photo.svg'))
    const avatarClasses = computed(() => (props.isOpen ? 'avatar-full' : 'avatar-scaled'))

    const handleUploadClick = () => {
      if (fileInput.value) {
        fileInput.value.click()
      }
    }

    const handleFileChange = (e: Event) => {
      const files = (e.target as HTMLInputElement).files ?? []
      if (files.length) {
        const file = files[0]

        if (file.size > 10 * 1024 * 1024) {
          errorPhoto.value = 'Размер фото превышает 10мб'
          return
        }

        onChangePhoto(file)
      }
    }

    const onChangePhoto = (file: File) => {
      displayError(
        ctx.$services.user.uploadPhoto(file).then((data) => {
          ctx.$tstore.user.authorizedUser!.profile.photo = data
        })
      )
    }

    return {
      userPhoto,
      avatarClasses,
      fileInput,
      hasEdit,
      errorPhoto,
      handleUploadClick,
      handleFileChange,
    }
  },
})
