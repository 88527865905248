import { isUnknownObject } from '~/shared/utils/guards'
import { BaseModel, PaginationDataModel } from '~/shared/model'
import { messages } from '~/shared/const'

export function unwrapResponse(unwrapData: unknown): unknown {
  if (isUnknownObject(unwrapData)) {
    const raw = isUnknownObject(unwrapData.data) ? unwrapData.data : unwrapData

    const firstKey = Object.keys(raw).shift()
    if (firstKey !== undefined) {
      return raw[firstKey]
    }
  }

  return null
}

export function toBooleanResponse(errorMessage?: string) {
  return (data: unknown) => {
    const booleanStatus = unwrapResponse(data)

    if (typeof booleanStatus === 'boolean') {
      return booleanStatus
    }

    throw new Error(errorMessage ?? messages.errors.request)
  }
}

export function toModel<T extends BaseModel>(constructor: new (...args: any[]) => T, errorMessage?: string) {
  return (data: unknown) => {
    const resp = unwrapResponse(data)

    if (resp && isUnknownObject(resp)) {
      return new constructor(resp)
    }

    throw new Error(errorMessage ?? messages.errors.request)
  }
}

export function toPaginatedModels<T extends BaseModel>(constructor: new (...args: any[]) => T) {
  return (data: unknown): { models: Array<T>; pagination: PaginationDataModel } => {
    let models: Array<T> = []
    let pagination: PaginationDataModel | null = null

    const resp = unwrapResponse(data)

    if (isUnknownObject(resp)) {
      if (isUnknownObject(resp.paginatorInfo)) {
        pagination = new PaginationDataModel(resp.paginatorInfo)
      }
      if (Array.isArray(resp.data)) {
        models = resp.data.map((i: unknown) => new constructor(i))
      }
    }

    return { models, pagination: pagination ?? new PaginationDataModel(null) }
  }
}

export function toModels<T extends BaseModel>(constructor: new (...args: any[]) => T) {
  return (data: unknown): Array<T> => {
    const resp = unwrapResponse(data)

    if (Array.isArray(resp)) {
      return resp.map((i: unknown) => new constructor(i))
    }

    if (isUnknownObject(resp) && Array.isArray(resp.data)) {
      return resp.data.map((i: unknown) => new constructor(i))
    }

    return []
  }
}
