import { ApolloService, toModel, unwrapResponse } from '~/shared/api'
import { RatingModel } from '~/entities/rating/model'
import { RateEntity, unRateEntity } from '~/entities/rating/gql/mutation'
import { messages } from '~/shared/const'
import type { RatingEntityEnum, RatingTypeEnum } from '~/entities/rating/enums'
import type { InputRateEntity, InputUnRateEntity } from '~/entities/rating/interface'

export class RatingService {
  private apolloService: ApolloService

  constructor(apolloService: ApolloService) {
    this.apolloService = apolloService
  }

  rate(entity: RatingEntityEnum, id: string, type: RatingTypeEnum): Promise<RatingModel> {
    const input: InputRateEntity = {
      type: entity,
      id,
      rating: type,
    }

    return this.apolloService
      .executeMutation(RateEntity(entity), { input })
      .then(unwrapResponse)
      .then(toModel(RatingModel, messages.errors.create))
  }

  unRate(entity: RatingEntityEnum, id: string): Promise<RatingModel> {
    const input: InputUnRateEntity = {
      type: entity,
      id,
    }

    return this.apolloService
      .executeMutation(unRateEntity(entity), { input })
      .then(unwrapResponse)
      .then(toModel(RatingModel, messages.errors.delete))
  }
}
