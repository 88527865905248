import { ProfileSubjectModel } from '.'
import { BaseModel, MediaModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'

export default class UserProfile extends BaseModel {
  id: string = ''
  middleName: string = ''
  name: string = ''
  surName: string = ''
  dateOfBirth: string = ''
  experience: string = ''
  photo: MediaModel = new MediaModel(null)
  profileSubjects: Array<ProfileSubjectModel> = []
  documents: Array<MediaModel> = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['photo', 'profileSubjects', 'documents', 'dateOfBirth'])

      this.dateOfBirth = data.dateOfBirth ? String(data.dateOfBirth) : ''

      if (isUnknownObject(data.photo)) {
        this.photo = new MediaModel(data.photo)
      }

      if (Array.isArray(data.profileSubjects) && data.profileSubjects.length > 0) {
        this.profileSubjects = data.profileSubjects.map((i) => new ProfileSubjectModel(i))
      }

      if (Array.isArray(data.documents) && data.documents.length > 0) {
        this.documents = data.documents.map((i) => new MediaModel(i))
      }
    }
  }
}
