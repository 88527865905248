import { CallableMask, ParsedMask, PartMask } from './interfaces'

const getStringPartFromMask = (mask: ParsedMask, startIdx: number) => {
  const result: Array<string> = []
  while (true) {
    const partMask = mask[startIdx++]
    if (typeof partMask === 'string') {
      result.push(partMask)
    } else {
      break
    }
  }

  return { result: result.join(''), lastIdx: --startIdx }
}

const checkValue = (partValue: string, partMask: PartMask): boolean => {
  return partMask instanceof RegExp ? partMask.test(partValue) : partMask === partValue
}

export const conformMask = (value: string, mask: ParsedMask | CallableMask): string => {
  const inputMask = typeof mask === 'function' ? mask() : mask

  const result: Array<string> = []
  let valueIdx = 0
  let maskIdx = 0

  for (valueIdx; valueIdx < value.length; valueIdx++) {
    let partMask = inputMask[maskIdx]
    const partValue = value[valueIdx]

    if (checkValue(partValue, partMask)) {
      result.push(partValue)
      maskIdx++
      continue
    } else {
      const { result: partMaskStr, lastIdx } = getStringPartFromMask(inputMask, maskIdx)
      if (partMaskStr) {
        result.push(partMaskStr)
        maskIdx = lastIdx
        partMask = inputMask[maskIdx]

        if (checkValue(partValue, partMask)) {
          result.push(partValue)
          maskIdx++
          continue
        }
      }
    }

    break
  }

  return result.join('')
}
