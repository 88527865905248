import { DOCUMENTS_PARENTS_AGREEMENT, DOCUMENTS_USER_AGREEMENT } from '~/shared/const'
import { MediaModel } from '~/shared/model'
import type { UserModel } from '~/entities/user/model'
import type { AgreementsInput } from '~/entities/user/interfaces'

export default class DocumentsForm {
  private _user: UserModel | undefined = undefined
  userAgreement: Array<File | MediaModel> = []
  parentsAgreement: Array<File | MediaModel> = []

  constructor(user?: UserModel) {
    this._user = user
    this.fillForm()
  }

  setUser(user: UserModel) {
    this._user = user
    this.fillForm()
  }

  private fillForm() {
    if (this._user) {
      const userAgreement = this._user.profile.documents.find((d) => d.name === DOCUMENTS_USER_AGREEMENT)
      if (userAgreement) {
        const media = new MediaModel(userAgreement)
        media.name = 'Согласие ученика'
        this.userAgreement = [media]
      } else {
        this.userAgreement = []
      }

      const parentsAgreement = this._user.profile.documents.find((d) => d.name === DOCUMENTS_PARENTS_AGREEMENT)
      if (parentsAgreement) {
        const media = new MediaModel(parentsAgreement)
        media.name = 'Согласие родителя'
        this.parentsAgreement = [media]
      } else {
        this.parentsAgreement = []
      }
    }
  }

  getInputData(): AgreementsInput {
    const data: AgreementsInput = { documents: {} }
    if (this.userAgreement.length && this.userAgreement[0] instanceof File) {
      data.documents.userAgreement = this.userAgreement[0]
    }
    if (this.parentsAgreement.length && this.parentsAgreement[0] instanceof File) {
      data.documents.parentsAgreement = this.parentsAgreement[0]
    }
    return data
  }

  reset() {
    this.fillForm()
  }
}
