
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import type { Sizes } from '@svoi-ui/interfaces/loader'
import { SvoiLoader } from '~/packages/svoi-ui/components/loader'

export default defineComponent({
  name: 'LoaderWrapper',
  components: { SvoiLoader },
  props: {
    label: {
      type: String,
      default: undefined,
    },
    size: {
      type: String as PropType<Sizes>,
      default: undefined,
    },
    width: {
      type: String,
      default: undefined,
    },
  },
})
