
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'EmptyResult',
  props: {
    title: {
      type: String,
      default: 'По запросу ничего не найдено',
    },
    description: {
      type: String,
      default: 'Попробуйте ввести другие параметры',
    },
  },
})
