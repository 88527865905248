import { gql } from 'graphql-tag'
import { ClassMainFields } from '~/entities/class/gql/fragments'

export default gql`
  ${ClassMainFields('SchoolsCuratorClassType')}
  mutation updateClass($input: UpdateSchoolsClassInput!) {
    updateSchoolsClass(input: $input) {
      ...ClassFields
    }
  }
`
