import { CreateClassNameForm } from './'
import { requiredRule } from '~/shared/utils/validation'
import type { CreateClassInput } from '~/entities/class/interface'
import { ClassLitterValue } from '~/entities/class/enums'

export default class CreateForm extends CreateClassNameForm {
  institutionId: string = ''

  getInputData(): CreateClassInput {
    return {
      institutionId: this.institutionId,
      postfix: this.litter?.postfix || ClassLitterValue.emptyLitter,
      educationalProgramLevel: this.educationalProgram ? [this.educationalProgram.id] : [],
      name: this.name,
    }
  }

  static rules() {
    return {
      ...CreateClassNameForm.rules(),
      institutionId: requiredRule,
    }
  }

  errorFieldsMap(): Map<string, keyof typeof CreateClassNameForm.prototype> {
    return new Map(
      Object.entries({
        postfix: 'litter',
      })
    )
  }
}
