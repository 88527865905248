import { defineComponent, computed } from '@nuxtjs/composition-api'

/* eslint-disable-next-line @typescript-eslint/naming-convention */
export const BaseFilePickerComponent = defineComponent({
  name: 'BaseFilePicker',
  props: {
    accept: {
      type: String,
      default: '*/*'
    },
    multiple: {
      type: Boolean,
      default: false
    },
    /* в килобайтах */
    maxSizePerFile: {
      type: Number,
      default: Infinity
    },
    maxCount: {
      type: Number,
      default: Infinity
    },
    icon: {
      type: String,
      default: 'content-edit/document-upload'
    },
    description: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    tabindex: {
      type: Number,
      default: 0
    }
  }
})

export const useBaseFilePicker = (
  props: Readonly<{
    value: Array<File>
    accept: string
    maxSizePerFile: number
  }>
) => {
  const acceptedTypes = computed(() => props.accept.split(','))
  const requiresTypeCheck = computed(() => props.accept !== '*/*')

  const syncElementFiles = (id: string) => {
    const el = document.getElementById(id)
    if (el instanceof HTMLElement && Array.isArray(props.value)) {
      const dt = new DataTransfer()
      for (const file of props.value) {
        dt.items.add(file)
      }

      ;(el as HTMLInputElement).files = dt.files
    }
  }

  const checkFile = (file: File): true | string => {
    if (file.size / 1024 > props.maxSizePerFile) {
      return `Максимальный размер загруженного файла должен быть не более ${props.maxSizePerFile} Kb`
    }

    if (requiresTypeCheck.value && !acceptedTypes.value.includes(file.type)) {
      return `Загружены неподдерживаемые типы файлов`
    }

    return true
  }

  const checkFiles = (files: Array<File>): true | string => {
    for (const file of files) {
      const check = checkFile(file)
      if (check !== true) {
        return check
      }
    }
    return true
  }

  return { syncElementFiles, checkFiles }
}
