
import { defineComponent, useContext, computed, ref } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { HeaderMenu, HeaderMenuMobile } from '.'
import { DEFAULT_ACCOUNT_ROUTE, LOGIN_ROUTE, FAVORITES_ROUTE } from '~/shared/const'
import { useDevices, useGlobalState } from '~/composables'

export default defineComponent({
  name: 'Header',
  components: { SvoiIcon, HeaderMenu, HeaderMenuMobile },
  setup() {
    const ctx = useContext()
    const { isMobile } = useDevices()
    const { statisticState } = useGlobalState()

    const statistic = ref(statisticState)
    const isAuthorized = computed(() => !!ctx.$tstore.user.authorizedUser)
    const userPhoto = computed(() => ctx.$tstore.user.userPhoto)
    const linkProfile = computed(() => (ctx.$tstore.user.authorizedUser ? DEFAULT_ACCOUNT_ROUTE : LOGIN_ROUTE))

    return { userPhoto, linkProfile, isMobile, isAuthorized, statistic, FAVORITES_ROUTE }
  },
})
