import {
  requiredRule,
  emailRule,
  passwordRule,
  sameAsRule,
  onlyCyrillicRule,
  phoneRule,
  checkedRule,
  requiredIfRule,
} from '~/shared/utils/validation'
import { singleton } from '~/shared/utils/decorators' // eslint-disable-line @typescript-eslint/no-unused-vars
import { ClassInstitutionModel } from '~/entities/class/model'
import type { Nullable } from '~/shared/interface'

@singleton
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class RegisterForm {
  readonly constructorName = 'RegisterForm'
  email: string = ''
  password: string = ''
  passwordConfirmation: string = ''
  name: string = ''
  surname: string = ''
  middleName: string = ''
  birthDate: string = ''
  phone: string = ''
  schoolClass: Nullable<ClassInstitutionModel> = null
  isAgroClass: boolean = false
  isConfirm: boolean = false
  documents: { parentsAgreement: Array<File>; userAgreement: Array<File> } = {
    parentsAgreement: [],
    userAgreement: [],
  }

  _invalidBase: boolean = true
  _invalidPersonal: boolean = true

  getInputData() {
    const documents: Partial<{ parentsAgreement: File; userAgreement: File }> = {}

    if (this.documents.parentsAgreement.length > 0) {
      documents.parentsAgreement = this.documents.parentsAgreement[0]
    }
    if (this.documents.userAgreement.length > 0) {
      documents.userAgreement = this.documents.userAgreement[0]
    }

    return {
      email: this.email.toLowerCase(),
      phone: this.phone,
      password: this.password,
      password_confirmation: this.passwordConfirmation,
      classId: this.schoolClass?.id ?? '',
      profile: {
        name: this.name,
        surName: this.surname,
        dateOfBirth: this.birthDate,
        middleName: this.middleName,
        documents,
      },
    }
  }

  toAgreementsData() {
    return {
      surName: this.surname,
      name: this.name,
      email: this.email,
      middleName: this.middleName,
      dateOfBirth: this.birthDate,
      phone: this.phone,
    }
  }

  static getInstance() {
    return new RegisterForm()
  }

  static rules() {
    const self = this.getInstance()
    return {
      base: {
        email: { ...requiredRule, ...emailRule },
        password: { ...requiredRule, ...passwordRule },
        passwordConfirmation: { ...requiredRule, ...sameAsRule(self, 'password', 'Пароли не совпадают') },
      },
      personal: {
        name: { ...requiredRule, ...onlyCyrillicRule },
        surname: { ...requiredRule, ...onlyCyrillicRule },
        middleName: onlyCyrillicRule,
        birthDate: requiredRule,
        phone: { ...requiredRule, ...phoneRule },
        schoolClass: requiredIfRule(self, 'isAgroClass'),
        isAgroClass: checkedRule,
        isConfirm: checkedRule,
      },
    }
  }
}
