import { gql } from 'graphql-tag'
import { SubjectMainFields } from '~/entities/subject/gql/fragments'
import { FileFields, PaginationFields } from '~/shared/gql/fragments'

export default gql`
  ${SubjectMainFields('LearningKnowledgeBaseActiveSubjectType')}
  ${PaginationFields('LearningKnowledgeBaseActiveSubjectTypePaginator')}
  ${FileFields('MediaType')}
  query getSubjectsBase($first: Int = 9999, $page: Int, $filter: LearningKnowledgeBaseActiveSubjectsFilterInput) {
    learningKnowledgeBaseActiveSubjects(first: $first, page: $page, filter: $filter) {
      data {
        ...SubjectMainFields

        subjectType {
          id
          value
        }

        media(collection: "preview") {
          ...FileFields
        }
      }
      ...PaginationFields
    }
  }
`
