import { gql, disableFragmentWarnings } from 'graphql-tag'

disableFragmentWarnings()

export default (on: string) => gql`
  fragment PaginationFields on ${on} {
    paginatorInfo {
      lastPage
      currentPage
      total
    }
  }
`
