// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Medium.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Golos;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:400;font-style:normal}@font-face{font-family:Golos;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:500;font-style:normal}.svoi-pagination{font-family:Golos,sans-serif;display:-webkit-inline-box;display:-webkit-inline-flex;display:-ms-inline-flexbox;display:inline-flex;-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center;-webkit-box-pack:center;-webkit-justify-content:center;-ms-flex-pack:center;justify-content:center;-webkit-flex-wrap:wrap;-ms-flex-wrap:wrap;flex-wrap:wrap;grid-gap:4px;gap:4px}.svoi-pagination a{display:block;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;text-decoration:none;color:inherit}.svoi-pagination>.pagination-arrow{border-radius:8px;padding:8px}.svoi-pagination>.pagination-arrow:hover{background:#edf8ed}.svoi-pagination>.page-wrapper{border-radius:8px;max-width:-webkit-fit-content;max-width:-moz-fit-content;max-width:fit-content;overflow:hidden}.svoi-pagination>.page-wrapper>.page{display:block;padding:4px 12px}.svoi-pagination>.page-wrapper a:hover{background:#edf8ed}.svoi-pagination .-active{background-color:#42ab44!important}.svoi-pagination .-active:hover{background:#328033!important}.svoi-pagination .-disabled{color:#7d7e7f!important}.svoi-pagination .-disabled:hover{background:inherit}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
