import { gql } from 'graphql-tag'
import { FileFields } from '~/shared/gql/fragments'

export default gql`
  ${FileFields('MediaType')}
  mutation uploadProfilePhoto($image: Upload!) {
    uploadProfilePhoto(image: $image) {
      ...FileFields
    }
  }
`
