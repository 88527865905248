import { singleton } from '~/shared/utils/decorators' // eslint-disable-line @typescript-eslint/no-unused-vars

@singleton
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class LeavePageState {
  private _check: boolean = false
  private _message: string = ''

  static getInstance() {
    return new LeavePageState()
  }

  get check() {
    return this._check
  }

  get message() {
    return this._message
  }

  confirm(message: string) {
    this._check = true
    this._message = message
  }

  reset() {
    this._check = false
    this._message = ''
  }
}
