import { defaultMaskReplacers } from './const'
import type { ParsedMask } from './interfaces'

const normalize = (mask: Array<string | RegExp>): ParsedMask => {
  const maskReplacers = defaultMaskReplacers
  const result: Array<RegExp | string> = []
  const replacersKeys = Object.getOwnPropertyNames(maskReplacers)

  mask.forEach(item => {
    if (item instanceof RegExp) {
      result.push(item)
    } else if (replacersKeys.includes(item)) {
      result.push(maskReplacers[item])
    } else {
      result.push(item)
    }
  })

  return result
}

export const stringMaskToRegExpMask = (stringMask: string): ParsedMask => {
  return normalize([...stringMask])
}

export const arrayMaskToRegExpMask = (arrayMask: Array<string | RegExp>): ParsedMask => {
  const flattenedMask: Array<string | RegExp> = []

  arrayMask.forEach(part => {
    if (part instanceof RegExp) {
      flattenedMask.push(part)
    }
    if (typeof part === 'string' && part.length > 0) {
      flattenedMask.push(...part)
    }
  })

  return normalize(flattenedMask)
}
