
import { defineComponent, ref, PropType } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import type { FooterMenuGroup } from '~/shared/interface'

export default defineComponent({
  name: 'FooterMenuGroup',
  components: { SvoiIcon },
  props: {
    menu: {
      type: Object as PropType<FooterMenuGroup>,
      required: true,
    },
  },
  setup() {
    const opened = ref(false)

    const isExternal = (url: string) => /^(http(s)|mailto:)/.test(url)

    return { opened, isExternal }
  },
})
