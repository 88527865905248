import { BaseModel, convertToArrayModels, CounterModel, MediaModel, PaginationDataModel } from '~/shared/model'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'
import type { JSONContent } from '~/interfaces/editor'
import { CategoryModel } from '~/entities/category/model'
import { TagModel } from '~/entities/tag/model'
import { LessonModel } from '~/entities/lesson/model'
import { UserModel } from '~/entities/user/model'
import { RatingModel } from '~/entities/rating/model'
import { StatusModel } from '~/entities/workflow/model'
import { CommentModel } from '~/entities/comments/model'
import { PositionEnum, isPosition } from '~/entities/article/enums'
import type { Commentable } from '~/entities/comments/interface'
import { EntityType as CommentableEntityType } from '~/entities/comments/enums'

export default class Article extends BaseModel implements Commentable {
  id: string = ''
  commentableType = CommentableEntityType.publication
  slug: string = ''
  title: string = ''
  description: string = ''
  publishedAt: string = ''
  status: StatusModel | null = null
  content: JSONContent | null = null
  preview: MediaModel | null = null
  mainPreview: MediaModel | null = null
  categories: Array<CategoryModel> = []
  tags: Array<TagModel> = []
  counters: Array<CounterModel> = []
  articles: Array<Article> = []
  lessons: Array<LessonModel> = []
  rating: RatingModel = new RatingModel(null)
  author: UserModel = new UserModel(null)
  position: PositionEnum | null = null
  isFavorited: boolean = false
  comments: Commentable['comments'] = {
    data: [],
    pagination: null,
  }

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, [
        'content',
        'preview',
        'mainPreview',
        'categories',
        'tags',
        'author',
        'status',
        'position',
        'comments',
      ])

      if (typeof data.content === 'string') {
        this.content = JSON.parse(data.content) ?? null
      } else if (isUnknownObject(data.content)) {
        this.content = data.content
      }

      if (Array.isArray(data.preview) && data.preview.length && isUnknownObject(data.preview[0])) {
        this.preview = new MediaModel(data.preview[0])
      }

      if (Array.isArray(data.mainPreview) && data.mainPreview.length && isUnknownObject(data.mainPreview[0])) {
        this.mainPreview = new MediaModel(data.mainPreview[0])
      }

      this.categories = convertToArrayModels(data.categories, CategoryModel)
      this.counters = convertToArrayModels(data.counters, CounterModel)
      this.tags = convertToArrayModels(data.tags, TagModel)
      this.articles = convertToArrayModels(data.publications, Article)
      this.lessons = convertToArrayModels(data.lessons, LessonModel)

      if (isUnknownObject(data.comments)) {
        this.comments.data = convertToArrayModels(data.comments.data, CommentModel)
        if (isUnknownObject(data.comments.paginatorInfo)) {
          this.comments.pagination = new PaginationDataModel(data.comments.paginatorInfo)
        }
      }

      if (isUnknownObject(data.rating)) {
        this.rating = new RatingModel(data.rating)
      }

      if (isUnknownObject(data.author)) {
        this.author = new UserModel(data.author)
      }

      if (isUnknownObject(data.status)) {
        this.status = new StatusModel(data.status)
      }

      if (isPosition(data.position)) {
        this.position = data.position
      }
    }
  }

  static checkPublished(article: Article): boolean {
    return !!article.status?.alias?.match(/_published$/)
  }
}
