import { gql } from 'graphql-tag'
import { ClassJoinRequestFields } from '~/entities/user/gql/fragments'

export default gql`
  ${ClassJoinRequestFields('ClassJoinRequest')}
  mutation CreateClassJoinRequest($input: CreateClassJoinRequestInput!) {
    createClassJoinRequest(input: $input) {
      ...ClassJoinRequestFields
    }
  }
`
