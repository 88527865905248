
import { computed, defineComponent, ref, useContext } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { headerMenu, LOGIN_ROUTE, DEFAULT_ACCOUNT_ROUTE, FAVORITES_ROUTE } from '~/shared/const'

export default defineComponent({
  name: 'HeaderMenuMobile',
  components: { SvoiIcon },
  setup() {
    const ctx = useContext()

    const visible = ref(false)
    const userPhoto = computed(() => ctx.$tstore.user.userPhoto)
    const linkProfile = computed(() => (ctx.$tstore.user.authorizedUser ? DEFAULT_ACCOUNT_ROUTE : LOGIN_ROUTE))
    const isAuthorized = computed(() => ctx.$tstore.user.authorizedUser)

    const items = computed<typeof headerMenu>({
      get: () => {
        const value = [...headerMenu]
        if (isAuthorized.value) {
          value.push({ name: 'Избранное', url: FAVORITES_ROUTE, icon: 'support/star-1' })
        }
        return value
      },
      set: () => null,
    })

    return { items, visible, linkProfile, userPhoto, isAuthorized }
  },
})
