import { gql } from 'graphql-tag'

export default gql`
  query getTags($first: Int = 9999, $page: Int, $sort: [QueryTagsSortOrderByClause!]) {
    tags(first: $first, page: $page, sort: $sort) {
      data {
        id
        name
        counters {
          counter
          id
          type
        }
      }
    }
  }
`
