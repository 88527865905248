import { gql } from 'graphql-tag'
import { ArticleFieldsForCard } from '~/entities/article/gql/fragments'

export default gql`
  ${ArticleFieldsForCard}
  query getNextArticle($id: UUID!) {
    nextActivePublication(id: $id) {
      ...ArticleFieldsForCard
    }
  }
`
