import { gql } from 'graphql-tag'
import { SubjectMainFields } from '~/entities/subject/gql/fragments'
import type { SubjectListType } from '~/entities/subject/interface'

export default (type: SubjectListType) => {
  let data = 'learningKnowledgeBaseActiveSubject'
  let on = 'LearningKnowledgeBaseActiveSubjectType'

  if (type === 'program') {
    data = 'learningProgramSubject'
    on = 'LearningProgramSubjectType'
  }

  return gql`
    ${SubjectMainFields(on)}
    query getSubject($id: UUID!) {
      ${data}(id: $id) {
        ...SubjectMainFields
        educationalProgram {
          id
          value
        }
      }
    }
  `
}
