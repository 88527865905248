import { BaseModel } from '~/shared/model'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'
import { RatingTypeEnum } from '~/entities/rating/enums'

export default class Rating extends BaseModel {
  counters: { like: number; dislike: number } = {
    like: 0,
    dislike: 0,
  }

  userRate: RatingTypeEnum | null = null

  constructor(data: unknown) {
    super()

    if (isUnknownObject(data)) {
      if (isUnknownObject(data) && isUnknownObject(data.counters)) {
        fillOwnProperties(this.counters, data.counters)
      }

      if (data.userRate === 'like') {
        this.userRate = RatingTypeEnum.like
      } else if (data.userRate === 'dislike') {
        this.userRate = RatingTypeEnum.dislike
      }
    }
  }
}
