import { ClassModel } from '~/entities/class/model/index'
import { UserModel, UserShortModel } from '~/entities/user/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { convertToArrayModels } from '~/shared/model'

export default class StudentClass extends ClassModel {
  students: Array<UserModel> = []
  curators: Array<UserModel> = []
  anonymous: UserShortModel | null = null

  constructor(data: unknown) {
    super(data)

    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['students', 'curators', 'anonymous', 'institution', 'educationalProgram'])

      this.students = convertToArrayModels(data.students, UserModel)
      this.curators = convertToArrayModels(data.curators, UserModel)

      if (Array.isArray(data.anonymous) && data.anonymous.length) {
        this.anonymous = new UserShortModel(data.anonymous[0])
      }
    }
  }
}
