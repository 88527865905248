
import { defineComponent, computed, ref } from '@nuxtjs/composition-api'
import { isRefObject } from '@svoi-ui/shared/utils/guards'
import { uuid } from '@svoi-ui/shared/utils/helpers'
import { SvoiHint } from '@svoi-ui/components/hint'
import { SvoiIcon } from '@svoi-ui/components/icon'

export default defineComponent({
  name: 'SvoiTextarea',
  components: { SvoiIcon, SvoiHint },
  props: {
    value: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: null
    },
    rows: {
      type: Number,
      default: null
    },
    showCount: {
      type: Boolean,
      default: true
    },
    error: {
      type: String,
      default: null
    },
    example: {
      type: String,
      default: null
    },
    maxLength: {
      type: Number,
      default: null
    },
    resizeable: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: Number,
      default: 0
    }
  },
  emits: ['input', 'focus', 'blur'],
  setup(props, { emit }) {
    const focus = ref(false)
    const id = uuid()

    const computedValue = computed({
      get() {
        return String(props.value)
      },
      set(value) {
        emit('input', value)
      }
    })

    const characterCount = computed(() => {
      return (computedValue.value as string).toString().length
    })

    const counterDisplay = computed(() => {
      return props.showCount && !props.error
    })

    const classes = computed(() => {
      return {
        '-focus': isRefObject(focus) && focus.value,
        '-filled': isRefObject<string>(computedValue) && (computedValue.value as string).length > 0,
        '-resizeable': props.resizeable,
        '-disabled': props.disabled,
        '-error': props.error
      }
    })

    const onActivate = () => {
      if (!props.disabled) {
        focus.value = true
        emit('focus')
      }
    }

    const onBlur = () => {
      focus.value = false
      emit('blur')
    }

    return {
      id,
      computedValue,
      characterCount,
      counterDisplay,
      classes,
      onActivate,
      onBlur
    }
  }
})
