import { BaseModel, MediaModel } from '~/shared/model'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isString, isUnknownObject } from '~/shared/utils/guards'
import type { JSONContent } from '~/interfaces/editor'

export default class Section extends BaseModel {
  id: string = ''
  sectionType: string = ''
  content: JSONContent | null = null
  documents: Array<MediaModel> = []
  items: Array<string> = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['documents', 'content', 'items'])

      if (Array.isArray(data.items)) {
        data.items.forEach((item) => {
          if (isUnknownObject(item) && isString(item.itemId)) {
            this.items.push(item.itemId)
          }
        })
      }

      if (Array.isArray(data.documents)) {
        this.documents = data.documents.map((d) => new MediaModel(d))
      }

      if (typeof data.content === 'string') {
        this.content = JSON.parse(data.content) ?? null
      } else if (isUnknownObject(data.content)) {
        this.content = data.content
      }
    }
  }
}
