
import { computed, defineComponent, PropType, ref, watchEffect } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import type { PageInfo } from '@svoi-ui/interfaces/pagination'
import { LinkTypes, LinkTypesEnum } from '@svoi-ui/interfaces/link-type'
import SvoiPaginationLink from './SvoiPaginationLink.vue'

export default defineComponent({
  name: 'SvoiPagination',
  components: { SvoiIcon, SvoiPaginationLink },
  props: {
    baseUrl: {
      type: String,
      required: true,
      default: '/'
    },
    totalPages: {
      type: Number,
      default: 1,
      validator: (value: number) => {
        return value >= 0
      }
    },
    activePage: {
      type: Number,
      default: 1
    },
    maxPoints: {
      type: Number,
      default: 9,
      validator: (value: number) => {
        return value === 7 || value === 9
      }
    },
    showArrows: {
      type: Boolean,
      default: true
    },
    componentType: {
      type: String as PropType<LinkTypes>,
      default: LinkTypesEnum.nuxtLink,
      validator: (value: unknown) => {
        return (
          typeof value === 'string' && [LinkTypesEnum.a.toString(), LinkTypesEnum.nuxtLink.toString()].includes(value)
        )
      }
    }
  },
  setup(props) {
    const prepareActivePage = computed(() => Math.max(Math.min(props.activePage, props.totalPages), 1))
    const maxInRow = computed(() => props.maxPoints - 4)

    const minPage = computed(() => {
      const offset = Math.floor(maxInRow.value / 2)
      let min = prepareActivePage.value - offset
      if (min + maxInRow.value >= props.totalPages) {
        min = props.totalPages - maxInRow.value + 1
      }
      if (prepareActivePage.value === min) {
        min--
      }
      min = Math.max(min, 1)
      return min === 2 ? 1 : min
    })

    const maxPage = computed(() => {
      let max = minPage.value + maxInRow.value - 1
      if (prepareActivePage.value === max) {
        max++
      }
      max = Math.min(max, props.totalPages)
      return max
    })

    const pages = ref<Array<PageInfo>>([])
    const prevPage = ref<string | undefined>(undefined)
    const nextPage = ref<string | undefined>(undefined)

    const getLink = (baseUrl: string, page: number): string => {
      const hasHttp = /^http(s):\/\//.test(baseUrl)
      /* если базовый URL не полный, то подставляем к нему фиктивный домен, чтобы создать URL */
      const url = new URL(baseUrl, hasHttp ? '' : 'http://localhost')
      if (page === 1) {
        url.searchParams.delete('p')
      } else {
        url.searchParams.set('p', String(page))
      }
      return hasHttp ? url.href : `${url.pathname}${url.search}`
    }

    const getPages = (url: string) => {
      const pages: Array<PageInfo> = []

      if (minPage.value > 1) {
        pages.push({ title: 1, number: 1, link: getLink(url, 1) })
      }

      if (minPage.value > 2) {
        pages.push({ title: '...' })
      }

      for (let i = minPage.value; i <= maxPage.value; i++) {
        pages.push({ title: i, number: i, link: getLink(url, i) })
      }

      if (maxPage.value < props.totalPages - 1) {
        pages.push({ title: '...' })
      }

      if (maxPage.value < props.totalPages) {
        pages.push({ title: props.totalPages, number: props.totalPages, link: getLink(url, props.totalPages) })
      }

      return pages
    }

    watchEffect(() => {
      const baseUrl = props.baseUrl
      pages.value = getPages(baseUrl)
      prevPage.value = prepareActivePage.value > 1 ? getLink(baseUrl, prepareActivePage.value - 1) : undefined
      nextPage.value =
        prepareActivePage.value < props.totalPages ? getLink(baseUrl, prepareActivePage.value + 1) : undefined
    })

    return { prepareActivePage, pages, prevPage, nextPage }
  }
})
