import type { CreateForm, EditForm } from '.'
import { CreateArticle, EditArticle } from '~/entities/article/gql/mutation'
import {
  Article,
  Articles,
  ArticleBySlug,
  ArticlesMainPage,
  PreviousArticle,
  NextArticle,
} from '~/entities/article/gql/query'
import { ApolloService, toModel, toPaginatedModels, unwrapResponse } from '~/shared/api'
import { convertToArrayModels } from '~/shared/model'
import { messages } from '~/shared/const'
import { ArticleModel } from '~/entities/article/model'
import type { ArticleListParams, ArticleListType, ArticlesMainPageData } from '~/entities/article/interface'
import { isUnknownObject } from '~/shared/utils/guards'

export class ArticleService {
  private apolloService: ApolloService

  constructor(apolloService: ApolloService) {
    this.apolloService = apolloService
  }

  getArticles(type: ArticleListType, params: ArticleListParams = {}) {
    return this.apolloService.getQuery(Articles(type), params).then(toPaginatedModels(ArticleModel))
  }

  getArticle(type: ArticleListType, id: string): Promise<ArticleModel> {
    return this.apolloService.getQuery(Article(type), { id }).then(toModel(ArticleModel, messages.errors.get))
  }

  getArticleBySlug(type: ArticleListType, slug: string): Promise<ArticleModel> {
    return this.apolloService.getQuery(ArticleBySlug(type), { slug }).then(toModel(ArticleModel, messages.errors.get))
  }

  getArticlesMainPage(): Promise<ArticlesMainPageData> {
    return this.apolloService.getQuery(ArticlesMainPage).then((data) => {
      const response = unwrapResponse(data)
      const result: ArticlesMainPageData = { second: [], interesting: [], fresh: [], news: [] }

      if (isUnknownObject(response)) {
        if (isUnknownObject(response.top)) {
          result.top = new ArticleModel(response.top)
        }

        const propsSecond = ['bottomLeft', 'bottomCenter', 'bottomRight']

        propsSecond.forEach((key) => {
          const val = response[key]
          if (isUnknownObject(val)) {
            result.second?.push(new ArticleModel(val))
          }
        })

        result.fresh = convertToArrayModels(response.fresh, ArticleModel)
        result.interesting = convertToArrayModels(response.interesting, ArticleModel)
        result.news = convertToArrayModels(response.news, ArticleModel)
      }

      return result
    })
  }

  createArticle(form: CreateForm): Promise<ArticleModel> {
    return this.apolloService
      .executeMutation(CreateArticle, { input: form.getInputData() })
      .then(toModel(ArticleModel, messages.errors.create))
  }

  editArticle(form: EditForm): Promise<ArticleModel> {
    return this.apolloService
      .executeMutation(EditArticle, { input: form.getInputData() })
      .then(toModel(ArticleModel, messages.errors.update))
  }

  getPreviousArticle(id: string): Promise<ArticleModel> {
    return this.apolloService.getQuery(PreviousArticle, { id }).then(toModel(ArticleModel, messages.errors.get))
  }

  getNextArticle(id: string): Promise<ArticleModel> {
    return this.apolloService.getQuery(NextArticle, { id }).then(toModel(ArticleModel, messages.errors.get))
  }
}
