import { gql } from 'graphql-tag'

export default gql`
  mutation uploadAgreementsDocuments($documents: UploadAgreementsDocumentsInput!, $studentId: UUID) {
    uploadAgreementsDocuments(documents: $documents, studentId: $studentId) {
      id
      name
      path
      size
    }
  }
`
