import { BaseModel, convertToArrayModels } from '~/shared/model'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'
import { LessonModel } from '~/entities/lesson/model'
import { EducationProgramModel } from '~/entities/education-program/model'
import { SubjectModel } from '~/entities/subject/model'
import type { LearningStatus } from '~/shared/interface'
import { LearningStatusesEnum } from '~/shared/enums'

export default class Block extends BaseModel {
  id: string = ''
  order: number = 0
  title: string = ''
  description: string = ''
  isTemplate: boolean = false
  educationPrograms: Array<EducationProgramModel> = []
  subject: SubjectModel = new SubjectModel(null)
  lessons: Array<LessonModel> = []
  status: LearningStatus = LearningStatusesEnum.active

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['lessons', 'educationalProgram', 'subject'])

      this.lessons = convertToArrayModels(data.lessons, LessonModel)
      this.educationPrograms = convertToArrayModels(data.educationalProgram, EducationProgramModel).sort((a, b) =>
        Number(a.value) > Number(b.value) ? 1 : -1
      )

      if (isUnknownObject(data.subject)) {
        this.subject = new SubjectModel(data.subject)
      }
    }
  }
}
