
import { defineComponent, PropType, ref, computed } from '@nuxtjs/composition-api'
import { SvoiButton } from '@svoi-ui/components/button'
import { Modal } from '.'

export default defineComponent({
  name: 'ConfirmModal',
  components: {
    Modal,
    SvoiButton,
  },
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    title: {
      default: '',
      type: String,
    },
    description: {
      default: '',
      type: String,
    },
    confirmButtonText: {
      default: 'Да',
      type: String,
    },
    cancelButtonText: {
      default: 'Нет',
      type: String,
    },
    onConfirm: {
      type: Function as PropType<(...args: any[]) => void>,
      required: true,
      default: () => undefined,
    },
    onReject: {
      type: Function as PropType<(...args: any[]) => void>,
      required: false,
      default: () => undefined,
    },
  },
  setup(props, { emit }) {
    const confirmLoading = ref(false)
    const cancelLoading = ref(false)

    const confirmButton = computed(() => ({
      name: confirmLoading.value ? 'Ожидание...' : props.confirmButtonText,
      loading: confirmLoading.value,
      disabled: cancelLoading.value,
    }))
    const cancelButton = computed(() => ({
      name: cancelLoading.value ? 'Ожидание...' : props.cancelButtonText,
      loading: cancelLoading.value,
      disabled: confirmLoading.value,
    }))

    const onConfirmClick = () => {
      confirmLoading.value = true
      Promise.resolve(props.onConfirm())
        .then(() => emit('input', false))
        .finally(() => (confirmLoading.value = false))
    }

    const onRejectClick = () => {
      cancelLoading.value = true
      Promise.resolve(props.onReject())
        .then(() => emit('input', false))
        .finally(() => (cancelLoading.value = false))
    }

    return { confirmLoading, cancelLoading, confirmButton, cancelButton, onConfirmClick, onRejectClick }
  },
})
