import { gql } from 'graphql-tag'
import { ProfileFields } from '~/entities/user/gql/fragments'

export default gql`
  ${ProfileFields('User')}
  mutation editShortPersonalData($input: EditShortPersonalDataInput!) {
    editShortPersonalData(input: $input) {
      ...ProfileFields
    }
  }
`
