import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import { UserModule } from '~/entities/user/store'
import { NotificationsModule } from '~/entities/notification/store'

export interface TypedStore {
  user: UserModule
  notifications: NotificationsModule
}

const initializeStore: (store: Store<any>) => TypedStore = (store: Store<any>) => ({
  user: getModule(UserModule, store),
  notifications: getModule(NotificationsModule, store),
})

export { initializeStore }
