import { gql } from 'graphql-tag'

export default gql`
  mutation sendFeedback($input: SendFeedbackMessageInput!) {
    sendFeedbackMessage(input: $input) {
      id
      number
    }
  }
`
