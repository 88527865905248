import { StoreOptions as StoreOptionsItem } from './interfaces'

export default class StoreOptions {
  private elementOptions: Map<HTMLInputElement, StoreOptionsItem> = new Map()
  private defaultOptions: StoreOptionsItem = { previousValue: '', mask: [] }

  get(el: HTMLInputElement) {
    return this.elementOptions.get(el) || this.defaultOptions
  }

  partiallyUpdate(el: HTMLInputElement, newOptions: Partial<StoreOptionsItem>) {
    this.elementOptions.set(el, { ...this.get(el), ...newOptions })
  }

  remove(el: HTMLInputElement) {
    this.elementOptions.delete(el)
  }
}
