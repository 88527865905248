import { gql } from 'graphql-tag'

export default gql`
  mutation closeFeedback($input: CloseFeedbackMessageInput!) {
    closeFeedbackMessage(input: $input) {
      id
      number
    }
  }
`
