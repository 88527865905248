import { OrganizationModel } from '.'
import { isUnknownObject } from '~/shared/utils/guards'
import { ClassInstitutionModel } from '~/entities/class/model'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { BaseModel, convertToArrayModels } from '~/shared/model'

export default class SchoolOrganization extends BaseModel {
  id: string = ''
  name: string = ''
  link: string = ''
  classes: Array<ClassInstitutionModel> = []
  organization: OrganizationModel = new OrganizationModel(null)

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['classes', 'organization'])

      this.classes = convertToArrayModels(data.classes, ClassInstitutionModel)

      if (isUnknownObject(data.organization)) {
        this.organization = new OrganizationModel(data.organization)
        this.name = this.organization.nameShort
      }
    }
  }
}
