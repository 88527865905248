import { AddressService } from './address-service'
import { CompanyService } from './company-service'
import type { SuggestionInput, ServiceConfig } from '~/entities/dadata/interface'
import { UnknownObject } from '~/shared/interface'
import { camelToSnake } from '~/shared/utils/helpers'

export default class DadataService {
  protected token
  protected defaultCount = 10
  protected defaultHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  readonly baseUrl
  readonly address: AddressService
  readonly company: CompanyService

  constructor(config: ServiceConfig) {
    this.token = config.token ?? ''
    this.baseUrl = config.baseUrl ?? ''

    this.address = new AddressService(this)
    this.company = new CompanyService(this)
  }

  protected get headers() {
    if (this.token) {
      return {
        ...this.defaultHeaders,
        Authorization: `Token ${this.token}`,
      }
    }

    return this.defaultHeaders
  }

  protected prepareBody(body: UnknownObject): string {
    const result: UnknownObject = {}
    Object.keys(body).forEach((key) => {
      result[camelToSnake(key)] = body[key]
    })

    return JSON.stringify(result)
  }

  getSuggestions(url: string, params: SuggestionInput): Promise<unknown> {
    const body = { ...params }
    body.count = body.count ?? this.defaultCount

    const options: RequestInit = {
      method: 'POST',
      mode: 'cors',
      headers: this.headers,
      body: this.prepareBody(body),
    }

    return fetch(url, options).then((response) => response.json())
  }
}
