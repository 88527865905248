import { gql } from 'graphql-tag'

export default gql`
  query GetSchoolsInstitutions($first: Int = 9999, $filter: SchoolsInstitutionsFilterInput) {
    schoolsInstitutions(filter: $filter, first: $first) {
      data {
        organization {
          hid
          name {
            short
            full
          }
          address {
            id
            value
            geo {
              lat
              lon
            }
            region {
              value
              typeFull
              withType
              id
            }
          }
        }
      }
    }
  }
`
