import { AvailableTransitions, Statuses } from '~/entities/workflow/gql/query'
import { ApplyTransition } from '~/entities/workflow/gql/mutation'
import { ApolloService, toModel, toModels } from '~/shared/api'
import { messages } from '~/shared/const'
import { StatusModel, TransitionModel } from '~/entities/workflow/model'
import type { TransitionTypeEnum, WorkflowSchemes } from '~/entities/workflow/enum'
import { cache } from '~/shared/utils/decorators' // eslint-disable-line @typescript-eslint/no-unused-vars
import type {
  ApplyTransitionInput,
  AvailableTransitionListParams,
  StatusListParams,
} from '~/entities/workflow/interface'
import type { HasCache } from '~/shared/interface'
import type { BaseModel } from '~/shared/model'

interface WorkflowService extends HasCache<Array<BaseModel>> {}

@cache()
// eslint-disable-next-line no-redeclare
class WorkflowService {
  private apolloService: ApolloService

  constructor(apolloService: ApolloService) {
    this.apolloService = apolloService
  }

  getStatuses(schema: WorkflowSchemes): Promise<Array<StatusModel>> {
    const cacheKey = schema
    const cachedValue = this._cacheInstance.get(cacheKey)
    if (cachedValue !== undefined) {
      return Promise.resolve(cachedValue as Array<StatusModel>)
    }

    const params: StatusListParams = { filter: { schema } }
    return this.apolloService
      .getQuery(Statuses, params)
      .then(toModels(StatusModel))
      .then((statuses) => {
        this._cacheInstance.set(cacheKey, statuses)
        return statuses
      })
  }

  getAvailableTransitions(type: TransitionTypeEnum, id: string): Promise<Array<TransitionModel>> {
    const params: AvailableTransitionListParams = { filter: { id, type } }
    return this.apolloService.getQuery(AvailableTransitions, params).then(toModels(TransitionModel))
  }

  applyTransitions(id: string, transitionId: string, type: TransitionTypeEnum): Promise<StatusModel> {
    const input: ApplyTransitionInput = { id, type, transitionId }
    return this.apolloService
      .executeMutation(ApplyTransition, { input })
      .then(toModel(StatusModel, messages.errors.update))
  }
}

export default WorkflowService
