import { SectionModel } from '.'
import { BaseModel, MediaModel, PaginationDataModel } from '~/shared/model'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'
import { UserModel } from '~/entities/user/model'
import { CommentModel } from '~/entities/comments/model'
import type { LearningStatus } from '~/shared/interface'
import { LearningStatusesEnum } from '~/shared/enums'
import { LearningBlockModel } from '~/entities/learning-block/model'
import type { Commentable } from '~/entities/comments/interface'
import { EntityType as CommentableEntityType } from '~/entities/comments/enums'

export default class Lesson extends BaseModel implements Commentable {
  id: string = ''
  commentableType = CommentableEntityType.learningLesson
  order: number = 0
  title: string = ''
  description: string = ''
  number: number = 0
  status: LearningStatus = LearningStatusesEnum.active
  author: UserModel = new UserModel(null)
  createdAt: string = ''
  updatedAt: string = ''
  dateEnd: string = ''
  dateStart: string = ''
  block: LearningBlockModel = new LearningBlockModel(null)
  preview: MediaModel | null = null
  comments: Commentable['comments'] = {
    data: [],
    pagination: null,
  }

  sections: Array<SectionModel> = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['author', 'block', 'preview', 'comments', 'sections'])

      if (isUnknownObject(data.author)) {
        this.author = new UserModel(data.author)
      }

      if (isUnknownObject(data.block)) {
        this.block = new LearningBlockModel(data.block)
      }

      if (Array.isArray(data.preview) && data.preview.length && isUnknownObject(data.preview[0])) {
        this.preview = new MediaModel(data.preview[0])
      }

      if (isUnknownObject(data.comments)) {
        if (Array.isArray(data.comments.data) && data.comments.data.length) {
          this.comments.data = data.comments.data.map((c) => new CommentModel(c))
        }
        if (isUnknownObject(data.comments.paginatorInfo)) {
          this.comments.pagination = new PaginationDataModel(data.comments.paginatorInfo)
        }
      }

      if (Array.isArray(data.sections) && data.sections.length) {
        this.sections = data.sections.map((s) => new SectionModel(s))
      }
    }
  }
}
