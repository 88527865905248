export function singleton<T extends { new (...args: any[]): any }>(target: T) {
  return class extends target {
    private static instance: { new (...args: any[]): T }

    // eslint-disable-next-line no-useless-constructor
    private constructor(...args: any[]) {
      super(...args)
    }

    static getInstance(...args: any[]) {
      if (!this.instance) {
        this.instance = new target(args) // eslint-disable-line new-cap
      }
      return this.instance
    }
  }
}
