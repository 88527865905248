
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { Themes, ThemesEnum, Sizes, SizesEnum } from '@svoi-ui/interfaces/label'
import { SizesEnum as IconSizesEnum } from '@svoi-ui/interfaces/icon'
import { isString } from '@svoi-ui/shared/utils/guards'

export default defineComponent({
  name: 'SvoiLabel',
  components: { SvoiIcon },
  props: {
    name: {
      type: String,
      required: true
    },
    theme: {
      type: String as PropType<Themes>,
      default: ThemesEnum.qiwi,
      validator(value: unknown) {
        return (
          isString(value) &&
          [
            ThemesEnum.qiwi.toString(),
            ThemesEnum.qiwiLight.toString(),
            ThemesEnum.banana.toString(),
            ThemesEnum.chilli.toString(),
            ThemesEnum.potato.toString(),
            ThemesEnum.figs.toString()
          ].includes(value)
        )
      }
    },
    iconLeft: {
      type: String,
      default: ''
    },
    iconRight: {
      type: String,
      default: ''
    },
    size: {
      type: String as PropType<Sizes>,
      default: SizesEnum.m,
      validator: (value: unknown) => {
        return isString(value) && [SizesEnum.m.toString(), SizesEnum.l.toString()].includes(value)
      }
    }
  },
  emits: ['click'],
  setup(props) {
    const classes = computed(() => {
      return {
        [`-theme-${props.theme}`]: true,
        [`-size-${props.size}`]: true
      }
    })
    const iconSize = computed(() => (props.size === SizesEnum.m ? IconSizesEnum.xs : IconSizesEnum.s))

    return { classes, iconSize }
  }
})
