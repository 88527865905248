import { ApolloService, toModels } from '~/shared/api'
import { ClassifiersEntries } from '~/shared/gql/query'
import { ReferenceSubjects } from '~/entities/subject/gql/query'
import type { BaseModel } from '~/shared/model'
import { EducationProgramModel } from '~/entities/education-program/model'
import { ProfileSubjectModel } from '~/entities/profile-subject/model'
import { SubjectModel } from '~/entities/subject/model'
import { ThemeModel } from '~/entities/feedback/model'
import { ClassifierEntryModel } from '~/shared/model'
import { cache } from '~/shared/utils/decorators' // eslint-disable-line @typescript-eslint/no-unused-vars
import type { ClassConstructor } from '~/shared/utils/generics'
import type { HasCache } from '~/shared/interface'
import type { ClassifiersEntriesFilters } from '~/interfaces/classifiers-entries'

interface ReferenceService extends HasCache<Array<BaseModel>> {}

@cache()
// eslint-disable-next-line no-redeclare
class ReferenceService {
  protected apolloService: ApolloService

  constructor(apolloService: ApolloService) {
    this.apolloService = apolloService
  }

  getClassifierEntries<T extends ClassifierEntryModel>(
    filter: ClassifiersEntriesFilters = {},
    model?: ClassConstructor<T>,
    cacheKey?: string
  ): Promise<Array<T>> {
    if (cacheKey) {
      const cachedValue = this._cacheInstance.get(cacheKey)
      if (cachedValue !== undefined) {
        return Promise.resolve(cachedValue as Array<T>)
      }
    }

    return this.apolloService
      .getQuery(ClassifiersEntries, { filter })
      .then(toModels((model ?? ClassifierEntryModel) as ClassConstructor<T>))
      .then((data) => {
        if (cacheKey) {
          this._cacheInstance.set(cacheKey, data)
        }
        return data
      })
  }

  getEducationPrograms(): Promise<Array<EducationProgramModel>> {
    const filter: ClassifiersEntriesFilters = { group: 'educational_program_level' }
    return this.getClassifierEntries(filter, EducationProgramModel, 'education-programs')
  }

  getProfileSubjects(): Promise<Array<ProfileSubjectModel>> {
    const filter: ClassifiersEntriesFilters = { group: 'profile_subjects' }
    return this.getClassifierEntries(filter, ProfileSubjectModel, 'profile-subjects')
  }

  getSubjectsBase(): Promise<Array<SubjectModel>> {
    return this.apolloService.getQuery(ReferenceSubjects).then(toModels(SubjectModel))
  }

  getFeedbackThemes(): Promise<Array<ThemeModel>> {
    const filter: ClassifiersEntriesFilters = { group: 'feedback_messages_types' }
    return this.getClassifierEntries(filter, ThemeModel, 'feedback-themes')
  }
}

export default ReferenceService
