
import { computed, defineComponent } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { formatNumber } from '~/shared/utils/helpers'

export default defineComponent({
  components: { SvoiIcon },
  props: {
    ...SvoiIcon.props,
    counts: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const formattedCounts = computed(() => formatNumber(props.counts))

    return { formattedCounts }
  },
})
