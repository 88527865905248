import { useContext } from '@nuxtjs/composition-api'
import type { Context } from '@nuxt/types'
import {
  hasPermission as hasPermissionShared,
  hasAnyPermissions as hasAnyPermissionsShared,
} from '~/shared/utils/permission'
import { PermissionsEnum } from '~/shared/enums'

export const usePermissions = (context?: Context) => {
  const ctx = context ?? useContext()
  const hasPermission = (permission: PermissionsEnum) => {
    if (ctx.$tstore.user.authorizedUser) {
      return hasPermissionShared(ctx.$tstore.user.authorizedUser, permission)
    }
    return false
  }

  const hasAnyPermission = (permissions: Iterable<PermissionsEnum>) => {
    if (ctx.$tstore.user.authorizedUser) {
      return hasAnyPermissionsShared(ctx.$tstore.user.authorizedUser, permissions)
    }
    return false
  }

  return { hasPermission, hasAnyPermission }
}
