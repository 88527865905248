import { gql } from 'graphql-tag'

export default (on: string) => gql`
  fragment CommentFields on ${on} {
    id
    message
    createdAt

    author {
      id
      profile {
        name
        surName
        photo {
          path
        }
      }
    }
  }
`
