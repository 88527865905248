
import { defineComponent } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'

export default defineComponent({
  name: 'BackLink',
  components: { SvoiIcon },
  props: {
    to: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      default: '',
    },
  },
})
