import { BaseModel, MediaModel } from '~/shared/model'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'
import type { LearningStatus } from '~/shared/interface'
import { LearningStatusesEnum } from '~/shared/enums'
import { EducationProgramModel } from '~/entities/education-program/model'
import { LearningBlockModel } from '~/entities/learning-block/model'
import { SubjectTypeModel } from '~/entities/subject/model/index'

export default class Subject extends BaseModel {
  id: string = ''
  title: string = ''
  description: string = ''
  createdAt: string = ''
  subjectType: Array<SubjectTypeModel> = []
  backgroundColor: string = '#fff'
  status: LearningStatus = LearningStatusesEnum.active
  media: Array<MediaModel> = []
  educationPrograms: Array<EducationProgramModel> = []
  blocks: Array<LearningBlockModel> = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['media', 'educationalProgram', 'blocks', 'subjectType'])

      if (Array.isArray(data.media) && data.media.length > 0) {
        this.media = data.media.map((i) => new MediaModel(i))
      }

      if (Array.isArray(data.educationalProgram) && data.educationalProgram.length > 0) {
        this.educationPrograms = data.educationalProgram
          .map((i) => new EducationProgramModel(i))
          .sort((a, b) => (Number(a.value) > Number(b.value) ? 1 : -1))
      }

      if (Array.isArray(data.blocks) && data.blocks.length > 0) {
        this.blocks = data.blocks.map((i) => new LearningBlockModel(i))
      }

      if (Array.isArray(data.subjectType) && data.subjectType.length > 0) {
        this.subjectType = data.subjectType.map((i) => new SubjectTypeModel(i))
      }
    }
  }
}
