import { customRef, onMounted, Ref, useRoute, useRouter } from '@nuxtjs/composition-api'
import { useEventObserver } from '~/composables/use-event-observer'

type SearchParamValue = Array<string> | number | string | null | undefined

export const useSearchParams = <T extends Record<string, SearchParamValue>, K extends keyof T = keyof T>(filter: T) => {
  const router = useRouter()
  const route = useRoute()

  const searchParams = new Map<K, T[K]>()

  const searchParamsRef = (key: K, value: T[K]) => {
    searchParams.set(key, value)

    return customRef((track, trigger) => {
      return {
        get() {
          track()
          return value
        },
        set(newValue) {
          searchParams.set(key, newValue)

          updateQuery()

          value = newValue
          trigger()
        },
      }
    })
  }

  onMounted(() => {
    updateQuery((newQuery) => {
      broadcast(newQuery)
    })
  })

  const updateQuery = (errorCallback?: (newQuery: Partial<T>) => void) => {
    const updatedFilters = unwrapFilters()

    const keys = Object.keys(route.value.query)

    const previousQuery = keys.reduce<Record<string, string>>((accum, key) => {
      const value = route.value.query[key] as T[K]

      if (!searchParams.has(key as K) && Boolean(value)) {
        return { ...accum, [key]: String(value) }
      }

      return accum
    }, {})

    const newQuery = { ...previousQuery, ...updatedFilters }

    router.push(
      { query: newQuery },
      () => {
        broadcast(newQuery)
      },
      () => {
        if (errorCallback) {
          errorCallback(newQuery)
        }
      }
    )
  }

  const refFilters = () => {
    fillFromSearchParams()

    const keys = Object.keys(filter) as Array<K>

    return Object.fromEntries(
      keys.map((key) => {
        const value = searchParams.get(key)

        return [key, searchParamsRef(key, value || filter[key])]
      })
    ) as Required<{ [key in K]: Ref<T[key] extends Array<string> ? Array<string> : T[key]> }>
  }

  const fillFromSearchParams = () => {
    const queries = route.value.query
    const keys = Object.keys(queries)

    if (queries) {
      keys.forEach((key) => {
        const value = queries[key]
        const k = key as K

        if (Object.hasOwn(filter, key)) {
          const targetValue = filter[key]

          if (Array.isArray(targetValue) && !Array.isArray(value)) {
            const v = searchParams.get(k)

            if (Array.isArray(v)) {
              searchParams.set(k, [value, ...v] as T[K])
            } else {
              searchParams.set(k, [value] as T[K])
            }
          } else {
            searchParams.set(k, value as T[K])
          }
        }
      })
    }
  }

  const unwrapFilters = () => {
    const params = new Map(searchParams)
    const result: Partial<T> = {}

    params.forEach((value, key) => {
      if (Array.isArray(value) ? value.length : Boolean(value)) {
        result[key] = value
      }
    })

    return result
  }

  const removeSearchParam = (targetKey: string) => {
    const query = { ...route.value.query }

    delete query[targetKey]

    router.push({ query: { ...query } })
  }

  const { broadcast, subscribe, unsubscribe } = useEventObserver<(query: ReturnType<typeof unwrapFilters>) => void>()

  const targetParams = refFilters()

  return {
    model: targetParams,
    unwrapFilters,
    subscribeUpdateParams: subscribe,
    unsubscribeUpdateParams: unsubscribe,
    removeSearchParam,
  }
}
