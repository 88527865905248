import Vue from 'vue'
import type { Plugin, Context } from '@nuxt/types'
import { EventEmitter2 } from 'eventemitter2'

const eventEmitter: Plugin = (context: Context) => {
  const emitter = new EventEmitter2()

  Vue.prototype.$emitter = emitter
  context.$emitter = emitter
}

export default eventEmitter
