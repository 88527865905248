import { BaseModel, CounterModel } from '~/shared/model'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'

export default class Tag extends BaseModel {
  id: string = ''
  name: string = ''
  counters: Array<CounterModel> = []

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['counters'])

      if (Array.isArray(data.counters) && data.counters.length) {
        this.counters = data.counters.map((i) => new CounterModel(i))
      }
    }
  }
}
