import type { HasPermissions } from '~/shared/interface'
import { navBarMenusBothItems, navBarMenusMainItems } from '~/shared/const'
import { hasAnyPermissions } from '~/shared/utils/permission'

export const getNavBarMenus = (entity: HasPermissions) => {
  return {
    main: navBarMenusMainItems.filter((i) => i.permissions === null || hasAnyPermissions(entity, i.permissions)),
    both: navBarMenusBothItems.filter((i) => i.permissions === null || hasAnyPermissions(entity, i.permissions)),
  }
}
