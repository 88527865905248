import dayjs, { ConfigType, OpUnitType } from 'dayjs'
import localizedFormatPlugin from 'dayjs/plugin/localizedFormat'
import 'dayjs/locale/ru'

dayjs.extend(localizedFormatPlugin)
dayjs.locale('ru')

export function toDateFormat(value: ConfigType): string {
  return dayjs(value).format('L')
}

export function toDateTimeFormat(value: ConfigType): string {
  return dayjs(value).format('L LT')
}

export function toApiDateTimeFormat(value: ConfigType): string {
  return dayjs(value).format('YYYY-MM-DD HH:mm:ss')
}

export function diffDays(date1: ConfigType, date2: ConfigType): number {
  return dayjs(date1).startOf('day').diff(dayjs(date2).startOf('day'), 'day')
}

export function diffYears(date1: ConfigType, date2: ConfigType): number {
  return dayjs(date1).startOf('day').diff(dayjs(date2).startOf('day'), 'year')
}

export function toDateEndOf(value: ConfigType, unit: OpUnitType): Date {
  return dayjs(value).endOf(unit).toDate()
}

export function toDateStartOf(value: ConfigType, unit: OpUnitType): Date {
  return dayjs(value).startOf(unit).toDate()
}

export function getAge(birthDay: ConfigType): number {
  return diffYears(new Date(), birthDay)
}

export function isFutureDate(value: ConfigType): boolean {
  return toDateStartOf(value, 'day') > toDateStartOf(new Date(), 'day')
}
