
import { defineComponent, reactive } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'

export default defineComponent({
  name: 'SvoiIconsList',
  components: { SvoiIcon },
  setup() {
    const icons: Record<string, { path: string; ids: Array<string> }> = reactive({
      users: {
        path: require('@svoi-ui/assets/icons/users.svg'),
        ids: []
      },
      search: {
        path: require('@svoi-ui/assets/icons/search.svg'),
        ids: []
      },
      files: {
        path: require('@svoi-ui/assets/icons/files.svg'),
        ids: []
      },
      arrow: {
        path: require('@svoi-ui/assets/icons/arrow.svg'),
        ids: []
      },
      essential: {
        path: require('@svoi-ui/assets/icons/essential.svg'),
        ids: []
      },
      'content-edit': {
        path: require('@svoi-ui/assets/icons/content-edit.svg'),
        ids: []
      },
      delivery: {
        path: require('@svoi-ui/assets/icons/delivery.svg'),
        ids: []
      },
      security: {
        path: require('@svoi-ui/assets/icons/security.svg'),
        ids: []
      },
      building: {
        path: require('@svoi-ui/assets/icons/building.svg'),
        ids: []
      },
      time: {
        path: require('@svoi-ui/assets/icons/time.svg'),
        ids: []
      },
      support: {
        path: require('@svoi-ui/assets/icons/support.svg'),
        ids: []
      },
      settings: {
        path: require('@svoi-ui/assets/icons/settings.svg'),
        ids: []
      },
      messages: {
        path: require('@svoi-ui/assets/icons/messages.svg'),
        ids: []
      },
      paragraph: {
        path: require('@svoi-ui/assets/icons/paragraph.svg'),
        ids: []
      },
      'video-audio': {
        path: require('@svoi-ui/assets/icons/video-audio.svg'),
        ids: []
      },
      learning: {
        path: require('@svoi-ui/assets/icons/learning.svg'),
        ids: []
      },
      grid: {
        path: require('@svoi-ui/assets/icons/grid.svg'),
        ids: []
      },
      money: {
        path: require('@svoi-ui/assets/icons/money.svg'),
        ids: []
      },
      'design-tools': {
        path: require('@svoi-ui/assets/icons/design-tools.svg'),
        ids: []
      },
      notifications: {
        path: require('@svoi-ui/assets/icons/notifications.svg'),
        ids: []
      },
      svoe: {
        path: require('@svoi-ui/assets/icons/svoe.svg'),
        ids: []
      }
    })

    Object.keys(icons).forEach(key => {
      fetch(icons[key].path)
        .then(data => data.text())
        .then(data => {
          const ids: Array<string> = []
          const iter = data.matchAll(/id="([\w-]+)"/g)
          for (const result of iter) {
            if (result.length > 0) {
              ids.push(result[1])
            }
          }

          icons[key].ids = ids
        })
    })

    return { icons }
  }
})
