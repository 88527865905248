import { gql } from 'graphql-tag'

export default gql`
  query getScheduledLessonsSubjectsBlocks {
    learningClassLessonsSubjectsBlocks {
      data {
        id
        title
        order

        blocks {
          id
          order
          title
        }
      }
    }
  }
`
