
import { computed, defineComponent, ref } from '@nuxtjs/composition-api'

export default defineComponent({
  name: 'ReadMore',

  props: {
    text: {
      type: String,
      required: true,
    },
    maxChars: {
      type: Number,
      default: 200,
    },

    /**
     *  Количество символов на которое необходимо сместить срезание строки чтобы оно имело смысла
     * e.g - Полная длинна строки - 203, максимально разрешенное количестве символов - 200, в таком примере будет обрезать 3 символа
     * что не будет иметь смысла, данный параметр позволит сместись срез строки на N символов
     **/
    offsetHide: {
      type: Number,
      default: 30,
    },
  },

  setup(props) {
    const isShowFull = ref(false)

    const value = computed(() => {
      if (props.text.length <= props.maxChars) {
        isShowFull.value = true

        return props.text
      }

      return isShowFull.value ? props.text : props.text.slice(0, props.maxChars - props.offsetHide)
    })

    return { value, isShowFull }
  },
})
