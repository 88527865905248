import { BaseModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties, getUIUniqId } from '~/shared/utils/helpers'
import type { TestAnswer as TestAnswerImplement } from '~/entities/lesson/interface'
import type { UIUniqId } from '~/shared/types'

export default class TestAnswer extends BaseModel implements TestAnswerImplement {
  id: string | UIUniqId = getUIUniqId()
  title: string = ''
  description: string = ''
  content: string = ''
  points: number = 0
  order: number = 0

  constructor(data: unknown) {
    super()

    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
