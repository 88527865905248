import { gql } from 'graphql-tag'

export default (on: string) => gql`
  fragment FileFields on ${on} {
    id
    name
    path
    mimeType
    size
    conversions
  }
`
