import {
  requiredRule,
  onlyCyrillicRule,
  emailRule,
  phoneRule,
  passportSeriesRule,
  passportNumberRule,
  passportDepartmentRule,
} from '~/shared/utils/validation'
import { singleton } from '~/shared/utils/decorators' // eslint-disable-line @typescript-eslint/no-unused-vars

@singleton
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class ParentsForm {
  surname: string = ''
  name: string = ''
  middleName: string = ''
  serial: string = ''
  number: string = ''
  issuedBy: string = ''
  dateOfIssue: string = ''
  birthDate: string = ''
  address: string = ''
  postalCode: string = ''
  email: string = ''
  phone: string = ''
  departmentCode: string = ''
  _invalid: boolean = true

  toAgreementsData() {
    return {
      parent: {
        parentSurName: this.surname,
        parentName: this.name,
        parentMiddleName: this.middleName,
        parentDateOfBirth: this.birthDate,
        parentPassportSerial: this.serial,
        parentPassportNumber: this.number,
        parentPassportIssuer: this.issuedBy,
        parentPassportIssueDate: this.dateOfIssue,
        parentPassportDivisionCode: this.departmentCode,
        parentRegistrationAddress: this.address,
        parentZipCode: this.postalCode,
        parentEmail: this.email,
        parentPhone: this.phone,
      },
    }
  }

  static getInstance() {
    return new ParentsForm()
  }

  static rules() {
    return {
      surname: { ...requiredRule, ...onlyCyrillicRule },
      name: { ...requiredRule, ...onlyCyrillicRule },
      middleName: { ...requiredRule, ...onlyCyrillicRule },
      serial: { ...requiredRule, ...passportSeriesRule },
      number: { ...requiredRule, ...passportNumberRule },
      issuedBy: requiredRule,
      dateOfIssue: requiredRule,
      birthDate: requiredRule,
      address: requiredRule,
      postalCode: requiredRule,
      email: { ...requiredRule, ...emailRule },
      phone: { ...requiredRule, ...phoneRule },
      departmentCode: { ...requiredRule, ...passportDepartmentRule },
    }
  }
}
