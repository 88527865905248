import { conformMask } from './conform'
import { arrayMaskToRegExpMask, stringMaskToRegExpMask } from './converter'
import type { InputMask, ParsedMask, CallableMask } from './interfaces'
import StoreOptions from './store-options'

const storeOptions = new StoreOptions()

const parseMask = (inputMask: InputMask): ParsedMask | CallableMask => {
  if (Array.isArray(inputMask)) {
    return arrayMaskToRegExpMask(inputMask)
  }
  if (typeof inputMask === 'string') {
    return stringMaskToRegExpMask(inputMask)
  }
  return inputMask
}

const triggerInputUpdate = (el: HTMLInputElement) => {
  const e = new InputEvent('input', { bubbles: true, cancelable: true })
  el.dispatchEvent(e)
}

const updateValue = (el: HTMLInputElement, force = false) => {
  const { value } = el
  const { previousValue, mask } = storeOptions.get(el)

  const isValueChanged = value !== previousValue
  const isUpdateNeeded = value && isValueChanged
  const hasMask = typeof mask === 'function' || mask.length > 0

  if ((force || isUpdateNeeded) && hasMask) {
    el.value = conformMask(value, mask)
    triggerInputUpdate(el)
  }

  storeOptions.partiallyUpdate(el, { previousValue: value })
}

const updateMask = (el: HTMLInputElement, inputMask: InputMask) => {
  const mask = parseMask(inputMask)

  storeOptions.partiallyUpdate(el, { mask })
}

const maskToString = (mask: InputMask) => {
  const preparedMask = typeof mask === 'function' ? mask() : mask
  const maskArray = Array.isArray(preparedMask) ? preparedMask : [preparedMask]
  return maskArray.toString()
}

export const createDirective = () => {
  return {
    bind(el: HTMLInputElement, { value }: { value: InputMask }) {
      updateMask(el, value)
      updateValue(el)
    },

    componentUpdated(el: HTMLInputElement, { value, oldValue }: { value: InputMask; oldValue: InputMask }) {
      const isMaskChanged = typeof value === 'function' || maskToString(oldValue) !== maskToString(value)

      if (isMaskChanged) {
        updateMask(el, value)
      }

      updateValue(el, isMaskChanged)
    },

    unbind(el: HTMLInputElement) {
      storeOptions.remove(el)
    }
  }
}

export default createDirective()
