import { gql } from 'graphql-tag'
import { LessonMainFields } from '~/entities/lesson/gql/fragments'

export default gql`
  ${LessonMainFields('LearningProgramLessonType')}
  mutation assignLessonToClass($input: AssignLearningProgramLessonToClassInput!) {
    assignLearningProgramLessonToClass(input: $input) {
      ...LessonMainFields
    }
  }
`
