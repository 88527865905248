import { gql } from 'graphql-tag'
import { SubjectMainFields } from '~/entities/subject/gql/fragments'
import { FileFields } from '~/shared/gql/fragments'

export default gql`
  ${SubjectMainFields('LearningProgramSubjectType')}
  ${FileFields('MediaType')}
  query getSubjectsProgram(
    $first: Int = 9999
    $page: Int
    $filter: LearningProgramSubjectsFilterInput
    $sort: [QueryLearningProgramSubjectsSortOrderByClause!]
  ) {
    learningProgramSubjects(first: $first, page: $page, filter: $filter, sort: $sort) {
      data {
        ...SubjectMainFields
        media(collection: "preview") {
          ...FileFields
        }
        blocks {
          id
          status
        }
        educationalProgram {
          id
          value
        }
      }
    }
  }
`
