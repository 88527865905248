import type { UnknownObject } from '~/shared/interface'
import { isExcludePrimitive, isNonEmptyArray, isUnknownObject } from '~/shared/utils/guards'
import { NonEmptyArray, ObjectKeyByPrimitiveValue } from '~/shared/utils/generics'

/**
 * Склонение слов числительных
 * declOfNum(5, ['год', 'года', 'лет'])
 */
export const declOfNum = (n: number, titles: [string, string, string]): string => {
  return titles[
    n % 10 === 1 && n % 100 !== 11 ? 0 : n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20) ? 1 : 2
  ]
}

export function camelize(str: string): string {
  return str.replace(/^([A-Z])|[\s-_]+(\w)/g, function (_a, p1, p2) {
    if (p2) {
      return p2.toUpperCase()
    }
    return p1.toLowerCase()
  })
}

export function camelToSnake(str: string): string {
  return str.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`)
}

export function formatNumber(n: number, fractionDigits = 1) {
  const ranges = [
    { divider: 1e18, suffix: 'E' },
    { divider: 1e15, suffix: 'P' },
    { divider: 1e12, suffix: 'T' },
    { divider: 1e9, suffix: 'G' },
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'k' },
  ]

  for (let i = 0; i < ranges.length; i++) {
    if (n >= ranges[i].divider) {
      const number = n / ranges[i].divider
      const targetNumber = number.toFixed(fractionDigits)
      const comparedNumber = number.toFixed(0)

      return (Number(targetNumber) === Number(comparedNumber) ? comparedNumber : targetNumber) + ranges[i].suffix
    }
  }

  return n.toString()
}

export function fillOwnProperties(
  obj: { [key in string]: any },
  data: UnknownObject,
  ignoreProps: Array<string> = []
): void {
  const properties = new Set(Object.getOwnPropertyNames(obj).filter((p) => !ignoreProps.includes(p)))
  Object.keys(data).forEach((key) => {
    if (properties.has(key)) {
      obj[key] = data[key]
    } else if (properties.has(camelize(key))) {
      obj[camelize(key)] = data[key]
    }
  })
}

export function removeObjectProperties<T extends Record<string | number | symbol, any>, K extends keyof T>(
  target: T,
  props: Array<K>
): Omit<T, K> {
  const result = {} as Omit<T, K>
  const keys = Object.keys(target) as Array<K>

  for (const property of keys) {
    if (isExcludePrimitive(property, props)) {
      result[property] = target[property]
    }
  }

  return result
}

export const getValueByChain = (target: UnknownObject, chain: string, separator = '.'): unknown | undefined => {
  const chainArray: Array<string> = chain.split(separator)

  if (!chainArray || !isNonEmptyArray(chainArray)) {
    return undefined
  }

  const get = (target: UnknownObject, chainArray: NonEmptyArray<string>): unknown | undefined => {
    const key = <string>chainArray.shift()
    const newTarget = target[key]

    if (!isNonEmptyArray(chainArray)) {
      return newTarget
    } else if (isUnknownObject(newTarget)) {
      return get(newTarget, chainArray)
    }

    return undefined
  }

  return get(target, chainArray)
}

export const previewFileSize = (size: number): string => {
  let res = size
  let unit = 'Б'

  if (res > 1024) {
    res = res / 1024
    unit = 'Кб'
  }

  if (res > 1024) {
    res = res / 1024
    unit = 'Мб'
  }

  return `${res.toFixed(1)} ${unit}`
}

export const previewUserFIO = (surname: string, name: string, middleName: string): string => {
  const initials = (name ? `${name[0]}.` : '') + (middleName ? `${middleName[0]}.` : '')
  return surname + (initials ? ` ${initials}` : '')
}

export const previewUserName = (surname: string, name: string) => {
  return [surname, name].filter(Boolean).join(' ')
}

export const replaceParams = (str: string, params: Record<string, string | number>): string => {
  return str.replace(/\${(.+?)}/g, (_, expression) => String(params[expression]))
}

export const prepareFilters = <T extends Record<string, unknown>>(initial: T): T => {
  const preparedFilters = { ...initial }
  Object.keys(preparedFilters).forEach((key) => {
    if (!preparedFilters[key as keyof T]) {
      delete preparedFilters[key as keyof T]
    } else if (key === 'search') {
      Object.assign(preparedFilters, { search: `%${preparedFilters.search}%` })
    }
  })
  return preparedFilters
}

export const clearEmptyFields = <T extends Record<string, unknown>>(initial: T): T => {
  const result = { ...initial }
  Object.keys(result).forEach((key) => {
    const value = result[key as keyof T]
    if (value === undefined || value === null || value === '') {
      delete result[key as keyof T]
    }
  })
  return result
}

export const uniqArrayObjects = <T>(array: Array<T>, uniqBy: ObjectKeyByPrimitiveValue<T>): Array<T> => {
  const entry = new Map<T[ObjectKeyByPrimitiveValue<T>], T>()

  for (const item of array) {
    if (!entry.has(item[uniqBy])) {
      entry.set(item[uniqBy], item)
    }
  }

  return [...entry.values()]
}
