import { gql } from 'graphql-tag'

export default gql`
  query availableTransitions($filter: AvailableWorkflowTransitionsFilterInput) {
    availableWorkflowTransitions(filter: $filter) {
      id
      title
    }
  }
`
