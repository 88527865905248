import { gql } from 'graphql-tag'

export default gql`
  query socialRedirect {
    oAuthRedirects {
      link
      provider
    }
  }
`
