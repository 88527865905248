import Vue from 'vue'
// @ts-ignore
import YaMetrika from 'vue-yandex-metrika'
import type { Plugin, Context } from '@nuxt/types'

const yandexMetrikaPlugin: Plugin = (context: Context) => {
  const isProduction = context.$config.environmentStand === 'prod'

  Vue.use(YaMetrika, {
    id: '95442830',
    env: isProduction ? 'production' : 'development',
    router: context.app.router,
    debug: !isProduction,
    webvisor: false,
    clickmap: true,
    trackLinks: true,
    accurateTrackBounce: true,
  })
}

export default yandexMetrikaPlugin
