import { v4 as uuidv4 } from 'uuid'
import { uiidPrefix } from '~/shared/const'
import type { UIUniqId } from '~/shared/types'

export const uuid = () => {
  return uuidv4()
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export function getUIUniqId(): UIUniqId {
  return `${uiidPrefix}_${uuid()}`
}
