import type { CacheEngine, CacheValues, CacheOptions } from '~/shared/interface'

type StoreValue<V> = {
  value: V
  expires?: number
}

export default class LRU<V extends CacheValues, K = string> implements CacheEngine<V, K> {
  private _store: Map<K, StoreValue<V>> = new Map()
  private readonly _limit: number
  private readonly _lifetime: number = 0

  constructor(options: CacheOptions = {}) {
    this._limit = options.limit ?? 1000
    if (options.lifetime && options.lifetime > 0) {
      this._lifetime = options.lifetime * 1000
    }
  }

  get(key: K): V | undefined {
    const storeValue = this._store.get(key)

    if (storeValue !== undefined) {
      this.delete(key)

      const expired = storeValue.expires !== undefined && storeValue.expires < new Date().getTime()

      if (!expired) {
        this._store.set(key, storeValue)
        return storeValue.value
      }
    }

    return undefined
  }

  set(key: K, value: V): void {
    const storeValue: StoreValue<V> = { value }

    if (this._lifetime) {
      storeValue.expires = new Date().getTime() + this._lifetime
    }

    this.delete(key)
    this._store.set(key, storeValue)

    if (this._store.size > this._limit) {
      const firstKey = this._store.keys().next().value
      this._store.delete(firstKey)
    }
  }

  delete(key: K): void {
    if (this._store.has(key)) {
      this._store.delete(key)
    }
  }

  clear(): void {
    this._store.clear()
  }
}
