import Vue from 'vue'
import { Plugin } from '@nuxt/types'
import { isNumber, isString } from '~/shared/utils/guards'
import { getErrorScrollElement } from '~/composables'

const errorScroll: Plugin = () => {
  Vue.directive('error-scroll', {
    bind(el, binding) {
      const id = isString(binding.value) || isNumber(binding.value) ? String(binding.value) : null

      if (id) {
        const { dataAttributeName } = getErrorScrollElement(id)

        el.setAttribute(dataAttributeName, id)
      }
    },
  })
}

export default errorScroll
