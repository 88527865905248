import { gql } from 'graphql-tag'
import { LessonQuizType } from '~/entities/lesson/interface'
import { QuizMainFields } from '~/entities/lesson/gql/fragments'

export default (type: LessonQuizType) => {
  const isFull = type === 'full'

  let data, on

  switch (type) {
    case 'short': {
      data = 'quizShort'
      on = 'QuizShortType'
      break
    }
    case 'full': {
      data = 'quizFull'
      on = 'QuizFullType'
      break
    }
  }

  return gql`
    ${QuizMainFields(on, isFull)}
    query getQuiz($id: UUID) {
      ${data}(id: $id) {
        ...QuizMainFields
      }
    }
  `
}
