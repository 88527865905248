
import { defineComponent } from '@nuxtjs/composition-api'
import { DEFAULT_MOBILE_WIDTH, DEFAULT_TABLET_WIDTH } from '~/shared/const'

export default defineComponent({
  name: 'AdaptiveImage',
  props: {
    desktop: {
      type: String,
      default: '',
    },
    tablet: {
      type: String,
      default: '',
    },
    mobile: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
  },
  setup() {
    const mediaWidth = {
      desktop: `(min-width: ${DEFAULT_TABLET_WIDTH + 1}px)`,
      tablet: `(min-width: ${DEFAULT_MOBILE_WIDTH + 1}px)`,
    }
    return { mediaWidth }
  },
})
