import { gql } from 'graphql-tag'
import { SubjectMainFields } from '~/entities/subject/gql/fragments'

export default gql`
  ${SubjectMainFields('LearningProgramSubjectType')}
  mutation createLearningProgramSubject($input: CreateLearningProgramSubjectInput!) {
    createLearningProgramSubject(input: $input) {
      ...SubjectMainFields
    }
  }
`
