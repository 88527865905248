import type { LearningStatus, UnknownObject, StatisticCounters } from 'shared/interface'
import type { HeadingLevel, UIUniqId } from '~/shared/types'
import { LearningStatusesEnum, StatisticCountersEnum } from '~/shared/enums'
import { NonEmptyArray } from '~/shared/utils/generics'
import { uiidPrefix } from '~/shared/const'

export function isUnknownObject(data: unknown): data is UnknownObject {
  return typeof data === 'object' && data !== null && !Array.isArray(data)
}

export function isString(value: any): value is string {
  return typeof value === 'string'
}

export function isNumber(value: any): value is number {
  return typeof value === 'number'
}

export function isBoolean(value: any): value is boolean {
  return typeof value === 'boolean'
}

export function isExcludePrimitive<T extends string | number | symbol>(
  exclude: T,
  target: Array<T>
): exclude is Exclude<T, typeof exclude> {
  return !target.includes(exclude)
}

export function isLearningStatus(data: unknown): data is LearningStatus {
  return (
    data === LearningStatusesEnum.active ||
    data === LearningStatusesEnum.review ||
    data === LearningStatusesEnum.draft ||
    data === LearningStatusesEnum.archive
  )
}

export function isNonEmptyArray<T>(arr: T[]): arr is NonEmptyArray<T> {
  return arr.length > 0
}

export function isUIUniqid(id: any): id is UIUniqId {
  return isString(id) && id.includes(uiidPrefix)
}

export function isHeadingLevel(level: any): level is HeadingLevel {
  return Boolean(isNumber(level) && level >= 1 && level <= 6)
}

export function isInnerRoute(value: unknown): value is string {
  return isString(value) && /^\/\w*/.test(value)
}

export function isStatisticCounters(data: unknown): data is StatisticCounters {
  return data === StatisticCountersEnum.newApplications || data === StatisticCountersEnum.favorites
}
