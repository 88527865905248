
import type { Component } from '@nuxtjs/composition-api'
import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { Button, Types, TypesEnum } from '@svoi-ui/interfaces/notification'

export default defineComponent({
  name: 'SvoiNotification',
  components: { SvoiIcon },
  props: {
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    componentContent: {
      type: [Object, Function] as PropType<Component>,
      default: undefined
    },
    type: {
      type: String as PropType<Types>,
      default: TypesEnum.info,
      validator: (value: string) => {
        return [
          TypesEnum.info.toString(),
          TypesEnum.infoBlue.toString(),
          TypesEnum.warning.toString(),
          TypesEnum.error.toString()
        ].includes(value)
      }
    },
    collapse: {
      type: Boolean,
      default: false
    },
    buttons: {
      type: Array as PropType<Array<Button>>,
      default: () => []
    }
  },
  emits: ['close'],
  setup(props) {
    const collapsed = ref(false)
    const collapsedText = computed(() => `${collapsed.value ? 'Развернуть' : 'Свернуть'} подсказку`)

    const classes = computed(() => ({
      [`-type-${props.type}`]: true
    }))

    const onClickButton = (button: Button) => {
      button.callback()
    }

    return { classes, collapsed, collapsedText, onClickButton }
  }
})
