import { gql } from 'graphql-tag'

export default (on: string) => gql`
  fragment ClassJoinRequestFields on ${on} {
    id
    status
    class {
      id
      name
      institution {
        id
        title
        organization {
          name {
            short
            full
          }
          address {
            region {
              id
              withType
            }
            city {
              id
              withType
            }
            settlement {
              id
              withType
            }
          }
        }
      }
    }
  }
`
