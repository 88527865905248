import { ClassModel } from '~/entities/class/model'
import { requiredRule } from '~/shared/utils/validation'
import { AssignLessonToClassInput, UpdateAssignLessonToClassInput } from '~/entities/class/interface'
import { toApiDateTimeFormat, toDateEndOf, toDateStartOf } from '~/shared/utils/date'
import { LessonModel } from '~/entities/lesson/model'

export default class AssignLessonToClassForm {
  assignClass: ClassModel | null = null
  assignedLessonId: string = ''
  dateStart: Date = new Date()
  dateEnd: Date | null = null
  lesson: LessonModel = new LessonModel(null)

  constructor(lesson: LessonModel) {
    this.lesson = lesson
  }

  private get inputDates(): Pick<AssignLessonToClassInput, 'dateEnd' | 'dateStart'> {
    return {
      dateEnd: toApiDateTimeFormat(toDateEndOf(this.dateEnd, 'day')),
      dateStart: toApiDateTimeFormat(toDateStartOf(this.dateStart, 'day')),
    }
  }

  getAssignInputData(): AssignLessonToClassInput {
    return {
      classId: this.assignClass?.id ?? '',
      id: this.lesson.id,
      ...this.inputDates,
    }
  }

  getUpdateAssignInputData(): UpdateAssignLessonToClassInput {
    return {
      id: this.assignedLessonId,
      ...this.inputDates,
    }
  }

  static rules() {
    return {
      assignClass: { id: requiredRule },
      dateStart: requiredRule,
      dateEnd: requiredRule,
      assignedLessonId: [],
    }
  }
}
