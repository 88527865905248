import type { UnknownObject } from '@svoi-ui/shared/types'

export function isRefObject<T>(obj: unknown): obj is { value: T } {
  return typeof obj === 'object' && obj !== null && 'value' in obj
}

export const isFile = (obj: unknown): obj is File => typeof obj === 'object' && obj?.constructor?.name === 'File'

export const isString = (value: unknown): value is string => typeof value === 'string'

export const isUnknownObject = (data: unknown): data is UnknownObject => {
  return typeof data === 'object' && data !== null && !Array.isArray(data)
}
