import { AtLeastOne, WithoutPrivate } from '~/shared/utils/generics'
import { fillOwnProperties, getUIUniqId } from '~/shared/utils/helpers'
import QuestionForm from '~/entities/lesson/api/test/question-form'
import { isNonEmptyArray, isUIUniqid } from '~/shared/utils/guards'
import { SectionForm } from '~/entities/lesson/api'
import { JSONContent } from '~/interfaces/editor'
import type { MediaModel } from '~/shared/model'
import type { TestModel } from '~/entities/lesson/model'
import type {
  QuizResultAnswerInput,
  QuizResultInput,
  SectionDataQuizInput,
  TestQuestion,
} from '~/entities/lesson/interface'
import type { UIUniqId } from '~/shared/types'

export default class TestForm extends SectionForm {
  id: string | UIUniqId = getUIUniqId()
  questions: Map<string | UIUniqId, WithoutPrivate<QuestionForm>> = new Map()
  content: JSONContent | null = null
  documents: Array<File | MediaModel> = []

  test: TestModel | null = null

  constructor(test?: TestModel) {
    super()

    fillOwnProperties(this, { ...test }, ['questions'])

    if (test) {
      this.test = test

      const entryQuestions = Array.from(test.questions.values())

      if (isNonEmptyArray(entryQuestions)) {
        for (const question of entryQuestions) {
          this.addQuestion(question)
        }
      }
    }
  }

  toInputData(): SectionDataQuizInput {
    const newQuestions: Required<SectionDataQuizInput['questions']> = {
      create: [],
      update: [],
      delete: [],
    }

    if (this.test) {
      for (const existQuestion of Array.from(this.test?.questions.values())) {
        if (!this.questions.has(existQuestion.id)) {
          newQuestions.delete.push(existQuestion.id)
        }
      }
    }

    for (const [id, question] of this.questions) {
      const existQuestion = this.test?.questions.get(id)
      const data = question.toInputData(existQuestion)

      if (existQuestion && !isUIUniqid(id)) {
        newQuestions.update.push(data)
      } else {
        newQuestions.create.push(data)
      }
    }

    return {
      ...(this.id && !isUIUniqid(this.id) ? { id: this.id } : {}),
      title: '',
      points: 0,
      order: 0,
      content: '',
      description: '',
      questions: newQuestions,
    }
  }

  toResultInputData(): QuizResultInput {
    const answers: Array<QuizResultAnswerInput> = Array.from(this.questions.values()).map(({ id, answers }) => ({
      questionId: id,
      answersIds: Array.from(answers.values())
        .filter((a) => a.points > 0)
        .map((a) => a.id),
    }))

    return {
      quizId: this.id,
      answers,
    }
  }

  addQuestion(question?: AtLeastOne<TestQuestion>) {
    const questionModel = new QuestionForm(question)

    this.questions.set(questionModel.id, questionModel)
  }

  static rules() {
    return {
      id: [],
      questions: [],
      content: [],
      documents: [],
    }
  }
}
