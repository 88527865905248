import type { EducationProgramModel } from '~/entities/education-program/model'
import { emptyRule, requiredRule } from '~/shared/utils/validation'
import type { PostfixClassModel } from '~/entities/class/model'

export default class CreateClassNameForm {
  name: string = ''
  litter: PostfixClassModel | null = null
  educationalProgram: EducationProgramModel | null = null

  static rules() {
    return {
      litter: emptyRule,
      name: emptyRule,
      educationalProgram: requiredRule,
    }
  }
}
