import type { FeedbackForm } from './'
import { ApolloService, toModel, toPaginatedModels } from '~/shared/api'
import { messages } from '~/shared/const'
import { FeedbackModel } from '~/entities/feedback/model'
import { CloseFeedbackMessage, SendFeedback } from '~/entities/feedback/gql/mutations'
import { WithoutPrivate } from '~/shared/utils/generics'
import { Feedback, FeedbackList } from '~/entities/feedback/gql/query'
import type { CloseFeedbackInput, FeedbackListParams } from '~/entities/feedback/interface'

export class FeedbackService {
  private apolloService: ApolloService

  constructor(apolloService: ApolloService) {
    this.apolloService = apolloService
  }

  sendFeedback(form: WithoutPrivate<FeedbackForm>): Promise<FeedbackModel> {
    return this.apolloService
      .executeMutation(SendFeedback, { input: form.getInputData() })
      .then(toModel(FeedbackModel, messages.errors.feedback))
  }

  feedbackList(params: FeedbackListParams = {}) {
    return this.apolloService.getQuery(FeedbackList, params).then(toPaginatedModels(FeedbackModel))
  }

  getFeedback(id: string): Promise<FeedbackModel> {
    return this.apolloService.getQuery(Feedback, { id }).then(toModel(FeedbackModel))
  }

  closeFeedbackMessage(input: CloseFeedbackInput): Promise<FeedbackModel> {
    return this.apolloService
      .executeMutation(CloseFeedbackMessage, { input })
      .then(toModel(FeedbackModel, messages.errors.update))
  }
}
