
import { defineComponent } from '@nuxtjs/composition-api'
import { FooterMenu, FooterMenuBottom } from '.'

export default defineComponent({
  name: 'Footer',
  components: { FooterMenu, FooterMenuBottom },
  setup() {
    const copyrightText = `© 2000-${new Date().getFullYear()} АО «Россельхозбанк»</br> Генеральная лицензия Банка России № 3349 от 12 августа 2015`

    const location = {
      address: '119034, г. Москва, Гагаринский пер., д.3',
      email: 'office@rshb.ru',
    }

    return { copyrightText, location }
  },
})
