import { gql } from 'graphql-tag'

export default gql`
  {
    schoolsInstitutionsRegionsWithLocality {
      data {
        id
        withType
        settlements {
          id
          withType
          value
        }
        cities {
          id
          withType
          value
        }
      }
    }
  }
`
