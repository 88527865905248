import { BaseModel } from '~/shared/model'
import { isString, isUnknownObject } from '~/shared/utils/guards'

export default class Auth extends BaseModel {
  token: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      if (isString(data.token)) {
        this.token = data.token
      }
    }
  }
}
