import { CreateForm } from '.'
import { requiredRule } from '~/shared/utils/validation'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { EditArticleInput, ArticleFileInput } from '~/entities/article/interface'
import type { ArticleModel } from '~/entities/article/model'
import { PositionEnum } from '~/entities/article/enums'
import type { Nullable } from '~/shared/interface'

export default class EditForm extends CreateForm {
  id: string = ''
  position: Nullable<{ id: PositionEnum }> = null

  readonly article: ArticleModel

  constructor(data: ArticleModel) {
    super()

    this.id = data.id
    this.article = data

    if (data.preview) {
      this.preview = [data.preview]
    }

    if (data.mainPreview) {
      this.mainPreview = [data.mainPreview]
    }

    if (data.content) {
      this.content = { ...data.content }
    }

    this.tags = [...data.tags]
    this.categories = [...data.categories]

    // TODO будет удалено когда будет реализован нормальный механизм выбора сущностей
    const host = (process.client ? window.location.origin : '').replaceAll(
      /localhost:3000/g,
      'agroclasses-dev.sz.rshbcloud.ru'
    )
    data.lessons.forEach((l, idx) => {
      this.lessons[idx] = { link: `${host}/account/knowledge-base/lesson/view/${l.id}` }
    })
    data.articles.forEach((a, idx) => {
      this.articles[idx] = { link: `${host}/account/articles/${a.id}` }
    })

    fillOwnProperties(this, { ...data }, [
      'preview',
      'mainPreview',
      'content',
      'categories',
      'tags',
      'lessons',
      'articles',
      'position',
    ])
  }

  getInputData(): EditArticleInput {
    const assignedData: Pick<EditArticleInput, 'id' | 'preview' | 'mainPreview' | 'position'> = {
      id: this.id,
      preview: this.toInputFile('preview'),
      mainPreview: this.toInputFile('mainPreview'),
      position: this.position ? this.position.id : null,
    }

    return Object.assign(this.getMainInputData(), assignedData)
  }

  protected toInputFile(type: 'preview' | 'mainPreview'): ArticleFileInput | undefined {
    const dataModel = this.article[type]

    if (this[type].length) {
      const firstFile = this[type][0]
      if (firstFile instanceof File) {
        return dataModel
          ? { action: 'REPLACE', file: firstFile, id: dataModel.id }
          : { action: 'CREATE', file: firstFile }
      }
    } else if (dataModel) {
      return { action: 'DELETE', id: dataModel.id }
    }

    return undefined
  }

  static rules() {
    return {
      id: requiredRule,
      position: [],
      ...CreateForm.rules(),
    }
  }
}
