import { BaseModel } from '.'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'

export default class Geo extends BaseModel {
  id: string = ''
  lat: string = ''
  lon: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
