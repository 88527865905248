import type { MediaModel } from '~/shared/model'
import type { UserModel } from '~/entities/user/model'
import type { ThemeModel } from '~/entities/feedback/model'
import type { FeedbackTypeEnum } from '~/entities/feedback/enums'
import type { FeedbackInput, FeedbackFileInput } from '~/entities/feedback/interface'
import { previewUserFIO } from '~/shared/utils/helpers'
import { emailRule, requiredRule } from '~/shared/utils/validation'

export default class FeedbackForm {
  id: string = ''
  name: string = ''
  email: string = ''
  feedback: string = ''
  attachments: Array<File | MediaModel> = []
  theme: ThemeModel | null = null
  type: FeedbackTypeEnum | null = null

  constructor(user: UserModel | null) {
    if (user && user.id) {
      this.fillUserInfo(user)
    }
  }

  protected fillUserInfo(user: UserModel) {
    const { surName, name, middleName } = user.profile

    this.name = previewUserFIO(surName, name, middleName)
    this.email = user.email
  }

  protected toInputFiles(): Array<FeedbackFileInput> {
    const attachments = this.attachments.map((file) => {
      if (file instanceof File) {
        const inputFile: FeedbackFileInput = { action: 'CREATE', file }

        return inputFile
      }

      return null
    })

    return attachments.filter((file): file is FeedbackFileInput => Boolean(file))
  }

  getInputData(): FeedbackInput {
    const data: FeedbackInput = {
      name: this.name,
      email: this.email,
      message: this.feedback,
      attachments: this.toInputFiles(),
      classifiers: this.theme ? [this.theme.id] : [],
    }

    if (this.id) {
      data.id = this.id
    }

    if (this.type) {
      data.type = this.type
    }

    return data
  }

  static rules() {
    return {
      name: requiredRule,
      email: { ...requiredRule, ...emailRule },
      feedback: requiredRule,
      theme: requiredRule,
      attachments: [],
      id: [],
      type: [],
    }
  }
}
