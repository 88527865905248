// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Medium.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Golos;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:400;font-style:normal}@font-face{font-family:Golos;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:500;font-style:normal}.svoi-breadcrumbs[data-v-53ff05aa]{font-family:Golos,sans-serif;font-size:14px;font-weight:400;line-height:22px;-webkit-flex-flow:row wrap;-ms-flex-flow:row wrap;flex-flow:row wrap}.svoi-breadcrumbs[data-v-53ff05aa],.svoi-breadcrumbs>.item[data-v-53ff05aa]{display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal}.svoi-breadcrumbs>.item[data-v-53ff05aa]{-webkit-flex-flow:row nowrap;-ms-flex-flow:row nowrap;flex-flow:row nowrap}.svoi-breadcrumbs[data-v-53ff05aa]{color:#969595;grid-gap:16px;gap:16px}.svoi-breadcrumbs>.item[data-v-53ff05aa]{-webkit-box-align:center;-webkit-align-items:center;-ms-flex-align:center;align-items:center}.svoi-breadcrumbs>.item a[data-v-53ff05aa]{text-decoration:none;color:inherit}.svoi-breadcrumbs>.item>.icon[data-v-53ff05aa]{margin-right:16px;color:#5e5f5f}.svoi-breadcrumbs>.item:first-child .icon[data-v-53ff05aa]{display:none}.svoi-breadcrumbs>.item:last-child .title[data-v-53ff05aa]{color:#101e2c}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
