import type { Plugin, Context } from '@nuxt/types'
import { useGlobalState, usePermissions } from '~/composables'
import { PermissionsEnum, StatisticCountersEnum } from '~/shared/enums'
import type { StatisticCounters } from '~/shared/interface'

const statistic: Plugin = (context: Context) => {
  const { statisticState } = useGlobalState()
  const { hasPermission } = usePermissions(context)
  const user = context.$tstore.user.authorizedUser

  if (user) {
    const counters: Set<StatisticCounters> = new Set([StatisticCountersEnum.favorites])
    if (hasPermission(PermissionsEnum.viewAnySchoolsClassJoinRequest)) {
      counters.add(StatisticCountersEnum.newApplications)
    }

    if (counters.size) {
      context.$services.user.getStatistic(counters).then((data) => {
        statisticState.initCounters(data)
      })
    }
  }
}

export default statistic
