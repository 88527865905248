// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Medium.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Golos;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:400;font-style:normal}@font-face{font-family:Golos;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:500;font-style:normal}.svoi-table>.table td[data-v-ab656914],.svoi-table>.table th[data-v-ab656914]{font-size:12px;font-weight:400;line-height:16px}.svoi-table[data-v-ab656914]{width:100%;height:100%;overflow:auto;background-color:#fff}.svoi-table>.table[data-v-ab656914]{border-collapse:collapse;border:none;border-spacing:0;width:100%;margin:0;overflow:auto}.svoi-table>.table[data-v-ab656914],.svoi-table>.table .body[data-v-ab656914],.svoi-table>.table .header[data-v-ab656914],.svoi-table>.table .row[data-v-ab656914],.svoi-table>.table td[data-v-ab656914]{background-color:inherit}.svoi-table>.table td[data-v-ab656914],.svoi-table>.table th[data-v-ab656914]{text-align:left;padding:12px;min-width:-webkit-fit-content;min-width:-moz-fit-content;min-width:fit-content}.svoi-table>.table th[data-v-ab656914]{color:#7d7e7f}.svoi-table>.table .sticky-header[data-v-ab656914]{position:-webkit-sticky;position:sticky;top:0;z-index:2}.svoi-table>.table .sticky-column[data-v-ab656914]{position:-webkit-sticky;position:sticky;left:0;z-index:1}.svoi-table>.table>.body>.row:not(:first-child)>td[data-v-ab656914],.svoi-table>.table th[data-v-ab656914]{border-top:1px solid #e3e5e8}.svoi-table>.table>.header>.row>td[data-v-ab656914],.svoi-table>.table th[data-v-ab656914]{border-top:0;border-bottom:1px solid #e3e5e8}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
