import { gql } from 'graphql-tag'
import { ClassJoinRequestFields } from '.'
import { FileFields } from '~/shared/gql/fragments'
import { ClassMainFields } from '~/entities/class/gql/fragments'

export default (on: string) => gql`
  ${FileFields('MediaType')}
  ${ClassMainFields('SchoolsClassType')}
  ${ClassJoinRequestFields('SimpleClassJoinRequest')}
  fragment ProfileFields on ${on} {
    id
    email
    phone
    profile {
      id
      middleName
      name
      surName
      dateOfBirth
      experience
      photo {
        ...FileFields
      }
      documents {
        ...FileFields
      }
      profileSubjects {
        value
        id
      }
    }
    permissions {
      id
      name
    }
    roles {
      id
      name
      permissions {
        name
        id
      }
    }
    classJoinRequests {
      ...ClassJoinRequestFields
    }
    classes (status: APPROVED) {
      ...ClassFields
    }
  }
`
