export type { FileData } from './file-data'

export enum ModeEnum {
  replace = 'replace',
  append = 'append'
}

export enum TypeEnum {
  button = 'button',
  dragArea = 'drag-area'
}

export enum TypeListEnum {
  default = 'default',
  images = 'images'
}

export type Mode = ModeEnum.replace | ModeEnum.append
export type Type = TypeEnum.button | TypeEnum.dragArea
export type TypeList = TypeListEnum.default | TypeListEnum.images
