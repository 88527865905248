import { CopyForm, CreateForm, EditForm, TestForm } from '.'
import { Lesson, Quiz, ScheduledLesson, ScheduledLessons } from '~/entities/lesson/gql/query'
import {
  CalculateQuizResult,
  CreateLesson,
  CreateLessonByTemplate,
  EditLesson,
  EditLessonOrders,
} from '~/entities/lesson/gql/mutation'
import type {
  ChangeStatusLessonInput,
  LessonListType,
  LessonQuizType,
  LessonScheduledListParams,
} from '~/entities/lesson/interface'
import { ApolloService, toBooleanResponse, toModel, toPaginatedModels, unwrapResponse } from '~/shared/api'
import { messages } from '~/shared/const'
import { LessonModel, TestModel } from '~/entities/lesson/model'
import type { LearningStatusesEnum } from '~/shared/enums'

export class LessonService {
  private apolloService: ApolloService

  constructor(apolloService: ApolloService) {
    this.apolloService = apolloService
  }

  createLesson(type: LessonListType, form: CreateForm): Promise<LessonModel> {
    return this.apolloService
      .executeMutation(CreateLesson(type), { input: form.getInputData() })
      .then(toModel(LessonModel, messages.errors.create))
  }

  editLesson(type: LessonListType, form: EditForm): Promise<LessonModel> {
    return this.apolloService
      .executeMutation(EditLesson(type), { input: form.getInputData() })
      .then(toModel(LessonModel, messages.errors.update))
  }

  getLesson(type: LessonListType, id: string): Promise<LessonModel> {
    return this.apolloService.getQuery(Lesson(type), { id }).then(toModel(LessonModel, messages.errors.get))
  }

  changeStatusLesson(type: LessonListType, id: string, status: LearningStatusesEnum): Promise<LessonModel> {
    const input: ChangeStatusLessonInput = { id, status }

    return this.apolloService
      .executeMutation(EditLesson(type), { input })
      .then(toModel(LessonModel, messages.errors.update))
  }

  createLessonByTemplate(form: CopyForm): Promise<LessonModel> {
    return this.apolloService
      .executeMutation(CreateLessonByTemplate, { input: form.getInputData() })
      .then(toModel(LessonModel, messages.errors.create))
  }

  getScheduledLessons(params: LessonScheduledListParams = {}) {
    return this.apolloService.getQuery(ScheduledLessons, params).then(toPaginatedModels(LessonModel))
  }

  getScheduledLesson(id: string): Promise<LessonModel> {
    return this.apolloService.getQuery(ScheduledLesson, { id }).then(toModel(LessonModel, messages.errors.get))
  }

  editLessonOrders(ids: Array<LessonModel['id']>): Promise<boolean> {
    return this.apolloService
      .executeMutation(EditLessonOrders, { input: { ids } })
      .then(toBooleanResponse(messages.errors.update))
  }

  getQuiz(id: string, type: LessonQuizType): Promise<TestModel> {
    return this.apolloService.getQuery(Quiz(type), { id }).then(toModel(TestModel, messages.errors.get))
  }

  checkQuiz(form: TestForm): Promise<TestModel> {
    return this.apolloService
      .executeMutation(CalculateQuizResult, { input: form.toResultInputData() })
      .then(unwrapResponse)
      .then(toModel(TestModel, 'Не удалось проверить результаты теста'))
  }
}
