import { TestAnswerModel } from './'
import { BaseModel } from '~/shared/model'
import { fillOwnProperties, getUIUniqId } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'
import { LessonSectionQuizQuestionType } from '~/entities/lesson/enums'
import type { TestQuestion as TestQuestionImplement } from '~/entities/lesson/interface'
import type { UIUniqId } from '~/shared/types'

export default class TestQuestion extends BaseModel implements TestQuestionImplement {
  id: string | UIUniqId = getUIUniqId()
  title: string = ''
  description: string = ''
  content: string = ''
  answers: Map<string | UIUniqId, TestAnswerModel> = new Map()
  questionType: LessonSectionQuizQuestionType = LessonSectionQuizQuestionType.single
  order: number = 0

  constructor(data?: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, { ...data }, ['answers'])

      if (Array.isArray(data.answers)) {
        for (const answer of data.answers) {
          const answerModel = new TestAnswerModel(answer)

          this.answers.set(answerModel.id, answerModel)
        }
      }
    }
  }
}
