// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { singleton } from '~/shared/utils/decorators'

@singleton
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class Sidebar {
  private _isOpen: boolean = true

  setIsOpen(isOpen: boolean) {
    this._isOpen = isOpen
  }

  get isOpen() {
    return this._isOpen
  }

  static getInstance() {
    return new Sidebar()
  }
}
