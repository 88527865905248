
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import type { ContextMenuItem } from '~/interfaces/context-menu'

export default defineComponent({
  name: 'ContextMenu',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    items: {
      type: Array as PropType<Array<ContextMenuItem>>,
      default: () => [],
    },
    width: {
      type: Number,
      default: 265,
    },
    top: {
      type: Number,
      default: 20,
    },
    left: {
      type: Number,
      default: 20,
    },
    position: {
      type: String as PropType<'left' | 'right'>,
      default: 'right',
    },
  },
  setup(props, { emit }) {
    const computedValue = computed({
      get: () => props.value,
      set: (val) => emit('input', val),
    })

    // TODO требуется разработка механизма автоматического определения позиции меню
    const positionMenu = computed<'left' | 'right'>(() => props.position)
    const styles = computed(() => ({
      width: `${props.width}px`,
      'max-width': `${props.width}px`,
      top: `${props.top}px`,
      left: `${positionMenu.value === 'right' ? `${props.left}px` : `calc(${props.left}px - ${props.width}px)`}`,
    }))

    const closeContextMenu = () => (computedValue.value = false)

    const onClickItem = (item: ContextMenuItem) => {
      computedValue.value = false
      if (item.action) {
        emit(item.action)
      }
    }

    return { computedValue, positionMenu, styles, closeContextMenu, onClickItem }
  },
})
