import { gql } from 'graphql-tag'
import type { BlockListType } from '~/entities/learning-block/interface'

export default (type: BlockListType) => {
  let data = 'deleteLearningKnowledgeBaseBlock'
  let input = 'DeleteLearningKnowledgeBaseBlockInput'

  if (type === 'program') {
    data = 'deleteLearningProgramBlock'
    input = 'DeleteLearningProgramBlockInput'
  }

  return gql`
    mutation deleteLearningBlock($input: ${input}!) {
      ${data}(input: $input)
    }
  `
}
