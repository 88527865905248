import { AddComment } from '~/entities/comments/gql/mutation'
import { ApolloService, toModels, unwrapResponse } from '~/shared/api'
import { CommentModel } from '~/entities/comments/model'
import type { Commentable, CommentInput } from '~/entities/comments/interface'

export class CommentsService {
  private apolloService: ApolloService

  constructor(apolloService: ApolloService) {
    this.apolloService = apolloService
  }

  addComment(entity: Commentable, message: string): Promise<Array<CommentModel>> {
    const input: CommentInput = {
      id: entity.id,
      type: entity.commentableType,
      message,
    }
    return this.apolloService
      .executeMutation(AddComment(entity.commentableType), { input })
      .then(unwrapResponse)
      .then(toModels(CommentModel))
  }
}
