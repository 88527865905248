import { gql } from 'graphql-tag'

export default (on: string) => gql`
  fragment BlockMainFields on ${on} {
    id
    title
    description
    isTemplate
  }
`
