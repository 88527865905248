export enum LessonScheduledFilterTypeEnum {
  active = 'ACTIVE',
  finished = 'FINISHED',
}

export enum LessonSectionTypesEnum {
  teacher = 'TEACHER',
  student = 'STUDENT',
  quiz = 'QUIZ',
}

export enum LessonSectionQuizQuestionType {
  single = 'SINGLE',
  multiple = 'MULTIPLE',
}
