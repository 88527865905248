import { BaseModel } from '.'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'

export default class Media extends BaseModel {
  id: number = 0
  order: number = 0
  path: string = ''
  name: string = ''
  mimeType: string = ''
  createdAt: string = ''
  updatedAt: string = ''
  size: number = 0
  conversions: Record<string, { generated: boolean; path: string }> = {}
  responsive: Record<string, { paths: Record<string, string> }> = {}

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
