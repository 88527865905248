import { gql } from 'graphql-tag'
import { ArticleMainFields } from '~/entities/article/gql/fragments'

export default gql`
  ${ArticleMainFields}
  mutation editArticle($input: UpdatePublicationInput!) {
    updatePublication(input: $input) {
      ...ArticleMainFields
    }
  }
`
