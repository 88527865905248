import { ApolloService, toBooleanResponse, toModel, toModels, toPaginatedModels } from '~/shared/api'
import { SubjectsBase, Subject, SubjectsProgram, ScheduledSubjects } from '~/entities/subject/gql/query'
import { CreateSubject, EditSubject, DeleteSubject } from '~/entities/subject/gql/mutation'
import type { SubjectListParams, DeleteSubjectInput, SubjectListType } from '~/entities/subject/interface'
import { SubjectModel } from '~/entities/subject/model'
import { CreateForm, EditForm } from '~/entities/subject/api'
import { messages } from '~/shared/const'
import { backgroundColorGenerator } from '~/shared/utils/helpers'

export default class SubjectService {
  private apolloService: ApolloService

  constructor(apolloService: ApolloService) {
    this.apolloService = apolloService
  }

  getSubjectsBase(params: SubjectListParams = {}) {
    return this.apolloService
      .getQuery(SubjectsBase, params)
      .then(toPaginatedModels(SubjectModel))
      .then((data) => {
        const colors = backgroundColorGenerator()

        data.models.forEach((s) => (s.backgroundColor = colors.next().value))

        return data
      })
  }

  getSubjectsProgram(params: SubjectListParams = {}): Promise<Array<SubjectModel>> {
    return this.apolloService.getQuery(SubjectsProgram, params).then(toModels(SubjectModel))
  }

  getSubject(type: SubjectListType, id: string): Promise<SubjectModel> {
    return this.apolloService.getQuery(Subject(type), { id }).then(toModel(SubjectModel, messages.errors.get))
  }

  createSubject(form: CreateForm): Promise<SubjectModel> {
    return this.apolloService
      .executeMutation(CreateSubject, { input: form.getInputData() })
      .then(toModel(SubjectModel, messages.errors.create))
  }

  editSubject(form: EditForm): Promise<SubjectModel> {
    return this.apolloService
      .executeMutation(EditSubject, { input: form.getInputData() })
      .then(toModel(SubjectModel, messages.errors.update))
  }

  deleteSubject(id: string): Promise<boolean> {
    const input: DeleteSubjectInput = { id }
    return this.apolloService.executeMutation(DeleteSubject, { input }).then(toBooleanResponse(messages.errors.delete))
  }

  getScheduledSubjects(): Promise<Array<SubjectModel>> {
    return this.apolloService.getQuery(ScheduledSubjects).then(toModels(SubjectModel))
  }
}
