
import { defineComponent } from '@nuxtjs/composition-api'
import { FooterMenuGroup } from '~/widgets/footer'
import { footerMenusGroups } from '~/shared/const'

export default defineComponent({
  name: 'FooterMenu',
  components: { FooterMenuGroup },
  setup() {
    return { footerMenusGroups }
  },
})
