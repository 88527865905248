import { gql } from 'graphql-tag'
import { FavoriteEntityEnum } from '~/entities/favorites/enums'

export default (entity: FavoriteEntityEnum) => {
  const on = `${entity.at(0) + entity.slice(1).toLowerCase()}Type`
  return gql`
    mutation favoriteEntity($input: FavoriteEntityInput!) {
      favoriteEntity(input: $input) {
        ... on ${on} {
          id
        }
      }
    }
  `
}
