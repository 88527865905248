import { gql } from 'graphql-tag'
import { StatusMainFields } from '~/entities/workflow/gql/fragments'

export default gql`
  ${StatusMainFields('WorkflowStatusType')}
  mutation applyTransition($input: ApplyWorkflowTransitionInput!) {
    applyWorkflowTransition(input: $input) {
      ...StatusMainFields
    }
  }
`
