import { gql } from 'graphql-tag'

export default gql`
  fragment ArticleMainFields on PublicationType {
    id
    title
    description
    slug
    publishedAt
    status {
      id
      name
      alias
    }
  }
`
