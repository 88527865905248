import { ApolloService, toPaginatedModels, toModel } from '~/shared/api'
import { messages } from '~/shared/const'
import { Blocks } from '~/entities/learning-block/gql/query'
import { CreateBlock, EditBlock, DeleteBlock } from '~/entities/learning-block/gql/mutation'
import type { BlockListParams, DeleteLearningBlockInput, BlockListType } from '~/entities/learning-block/interface'
import { LearningBlockModel } from '~/entities/learning-block/model'
import { CreateForm, EditForm } from '~/entities/learning-block/api'

export default class BlockService {
  private apolloService: ApolloService

  constructor(apolloService: ApolloService) {
    this.apolloService = apolloService
  }

  getBlocks(type: BlockListType, params: BlockListParams = {}) {
    return this.apolloService.getQuery(Blocks(type), params).then(toPaginatedModels(LearningBlockModel))
  }

  createBlock(type: BlockListType, form: CreateForm): Promise<LearningBlockModel> {
    return this.apolloService
      .executeMutation(CreateBlock(type), { input: form.getInputData() })
      .then(toModel(LearningBlockModel, messages.errors.create))
  }

  editBlock(type: BlockListType, form: EditForm): Promise<LearningBlockModel> {
    return this.apolloService
      .executeMutation(EditBlock(type), { input: form.getInputData() })
      .then(toModel(LearningBlockModel, messages.errors.update))
  }

  deleteBlock(type: BlockListType, id: string): Promise<void> {
    const input: DeleteLearningBlockInput = { id }
    return this.apolloService.executeMutation(DeleteBlock(type), { input }).then()
  }
}
