import Vue from 'vue'
import Vuex from 'vuex'

import { UserModule } from '~/entities/user/store'
import { NotificationsModule } from '~/entities/notification/store'

Vue.use(Vuex)

const store = () =>
  // eslint-disable-next-line import/no-named-as-default-member
  new Vuex.Store({
    state: {},
    modules: {
      user: UserModule,
      notifications: NotificationsModule,
    },
    getters: {},
    mutations: {},
    actions: {
      async nuxtServerInit(_, context) {
        try {
          const user = await context.$services.user.getAuthorizedUser()
          context.$tstore.user.setAuthorizedUser(user)
        } catch (e) {
          //
        }
      },
    },
  })

export default store
