import type { HasPermissions } from '~/shared/interface'
import { PermissionsEnum } from '~/shared/enums'

export const hasPermission = (entity: HasPermissions, permission: PermissionsEnum): boolean => {
  return entity.permissions.has(permission)
}

export const hasAnyPermissions = (entity: HasPermissions, permissions: Iterable<PermissionsEnum>): boolean => {
  for (const permission of permissions) {
    if (hasPermission(entity, permission)) {
      return true
    }
  }

  return false
}
