import type { Positions as TooltipPositions } from '@svoi-ui/interfaces/tooltip'

export enum SizesEnum {
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  full = 'full'
}

export type Sizes = SizesEnum.xs | SizesEnum.s | SizesEnum.m | SizesEnum.l | SizesEnum.full
export type IconType = string | { icon: string; tooltip: string; tooltipPosition?: TooltipPositions }
