import { gql } from 'graphql-tag'
import { ArticleMainFields } from '~/entities/article/gql/fragments'

export default gql`
  ${ArticleMainFields}
  mutation createArticle($input: CreatePublicationInput!) {
    createPublication(input: $input) {
      ...ArticleMainFields
    }
  }
`
