import { AddressModel, BaseModel } from '~/shared/model'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'

export default class Organization extends BaseModel {
  hid: string = ''
  nameShort: string = ''
  nameFull: string = ''
  address: AddressModel = new AddressModel(null)

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['address'])

      if (isUnknownObject(data.address)) {
        this.address = new AddressModel(data.address)
      }

      if (isUnknownObject(data.name)) {
        this.nameShort = data.name.short ? String(data.name.short) : ''
        this.nameFull = data.name.full ? String(data.name.full) : ''
      }
    }
  }
}
