
import { defineComponent } from '@nuxtjs/composition-api'
import { footerBottomMenu } from '~/shared/const'

export default defineComponent({
  name: 'FooterMenuBottom',
  setup() {
    return { footerBottomMenu }
  },
})
