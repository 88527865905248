import { useContext } from '@nuxtjs/composition-api'
import { isUnknownObject } from '~/shared/utils/guards'
import { ErrorApi } from '~/shared/api'

export const useErrors = () => {
  const ctx = useContext()

  const parseValidationErrors = <T = unknown>(
    promise: Promise<T>,
    target: Record<string, string>,
    fieldsMap?: Map<string, string>
  ): Promise<T> => {
    return promise
      .then((data) => data)
      .catch((err) => {
        if (ErrorApi.isErrorApi(err)) {
          for (const [key, value] of Object.entries(err.validation)) {
            const targetField = fieldsMap ? fieldsMap.get(key) : key
            if (targetField) {
              target[targetField] = value.join('. ')
            }
          }
        }

        throw err
      })
  }

  const displayError = <T = unknown>(promise: Promise<T>, catchCallback?: () => void): Promise<T | undefined> => {
    return promise
      .then((data) => data)
      .catch((err) => {
        ctx.$tstore.notifications.pushError(err.message.replace(/^GraphQL error:/, '').trim())

        const extra = isUnknownObject(err.cause) ? err.cause : undefined
        ctx.$sentry.captureException(err, { extra })

        if (typeof catchCallback === 'function') {
          catchCallback()
        }

        return undefined
      })
  }

  const ignoreError = <T = unknown>(promise: Promise<T>): Promise<T | undefined> => {
    return promise.then((data) => data).catch(() => undefined)
  }

  return { displayError, ignoreError, parseValidationErrors }
}
