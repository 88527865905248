import { gql } from 'graphql-tag'

export default gql`
  query getClassifiersEntries($first: Int = 9999, $filter: classifiersEntriesFilterInput) {
    classifiersEntries(first: $first, filter: $filter) {
      data {
        id
        value
        alias
      }
    }
  }
`
