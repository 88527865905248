import { gql } from 'graphql-tag'
import { PaginationFields } from '~/shared/gql/fragments'
import { ClassMainFields } from '~/entities/class/gql/fragments'

export default gql`
  ${ClassMainFields('SchoolsCuratorClassType')}
  ${PaginationFields(`SchoolsCuratorClassTypePaginator`)}
  query getClasses($first: Int = 9999, $page: Int, $sort: [QuerySchoolsCuratorClassesSortOrderByClause!]) {
    schoolsCuratorClasses(first: $first, page: $page, sort: $sort) {
      data {
        ...ClassFields

        students(status: APPROVED) {
          id
          profile {
            name
            middleName
            surName
            photo {
              conversions
            }
          }
        }

        anonymous {
          id
          email
        }
      }
      ...PaginationFields
    }
  }
`
