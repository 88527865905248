import { gql } from 'graphql-tag'
import { FeedbackMainFields } from '~/entities/feedback/gql/fragments'
import { CommentFields, FileFields } from '~/shared/gql/fragments'

export default gql`
  ${FileFields('MediaType')}
  ${FeedbackMainFields}
  ${CommentFields('CommentType')}
  query getFeedback($id: UUID, $number: Int) {
    feedbackMessage(id: $id, number: $number) {
      ...FeedbackMainFields

      message

      attachments {
        ...FileFields
      }

      lastStatusTransitionHistoryRecord {
        createdAt
      }

      comments(first: 9999) {
        data {
          ...CommentFields
        }
      }
    }
  }
`
