import { ApolloService, toPaginatedModels } from '~/shared/api'
import { classJoinRequests } from '~/entities/user/gql/query'
import type { ApplicationsListParams } from '~/entities/user/interfaces'
import { ApplicationModel } from '~/entities/user/model'
import { approveClassJoinRequest, rejectClassJoinRequest } from '~/entities/user/gql/mutation'

export class ApplicationsService {
  private apolloService: ApolloService

  constructor(apolloService: ApolloService) {
    this.apolloService = apolloService
  }

  getApplicationsList(params: ApplicationsListParams = {}) {
    return this.apolloService.getQuery(classJoinRequests, params).then(toPaginatedModels(ApplicationModel))
  }

  approveApplication(userId: string, classId: string): Promise<void> {
    return this.apolloService.executeMutation(approveClassJoinRequest, { classId, userId }).then()
  }

  rejectApplication(userId: string, classId: string): Promise<void> {
    return this.apolloService.executeMutation(rejectClassJoinRequest, { classId, userId }).then()
  }
}
