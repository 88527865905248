import { AddressModel } from '.'
import { BaseModel } from '~/shared/model'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'
import type { UnknownObject } from '~/shared/interface'

/**
 * Полное описание полей для модели содержится на странице
 * https://dadata.ru/api/suggest/party/
 * При использовании полей из объекта data оформляем их через геттер с необходимой типизацией
 * get fieldName() {
 *  return this._rawData[fieldName]
 * }
 */

export default class Company extends BaseModel {
  value: string = ''
  unrestrictedValue: string = ''
  private readonly _rawData: UnknownObject = {}

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (isUnknownObject(data.data)) {
        this._rawData = data.data
      }
    }
  }

  get dadata(): UnknownObject {
    return {
      value: this.value,
      unrestricted_value: this.unrestrictedValue,
      data: this._rawData,
    }
  }

  get hid(): string {
    return String(this._rawData.hid || '')
  }

  get address(): AddressModel | null {
    return this._rawData.address ? new AddressModel(this._rawData.address) : null
  }

  get preview(): string {
    return `${this.value}${this.address ? ' (' + this.address.locality + ')' : ''}`
  }
}
