import { gql } from 'graphql-tag'
import { CommentFields } from '~/shared/gql/fragments'
import { EntityType } from '~/entities/comments/enums'

export default (type: EntityType) => {
  let on: string

  switch (type) {
    case EntityType.learningLesson: {
      on = 'LearningClassLessonType'
      break
    }
    case EntityType.publication: {
      on = 'PublicationType'
      break
    }
    case EntityType.feedback: {
      on = 'FeedbackMessageType'
      break
    }
  }

  return gql`
    ${CommentFields('CommentType')}
    mutation addComment($input: CommentEntityInput!) {
      commentEntity(input: $input) {
        ... on ${on} {
          comments(first: 9999) {
            data {
              ...CommentFields
            }
          }
        }
      }
    }
  `
}
