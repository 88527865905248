import { Favorites } from '~/entities/favorites/gql/query'
import { FavoriteEntity, UnFavoriteEntity } from '~/entities/favorites/gql/mutation'
import { ApolloService, toPaginatedModels } from '~/shared/api'
import { FavoriteModel } from '~/entities/favorites/model'
import type { FavoritesListParams, FavoriteEntityInput, UnFavoriteEntityInput } from '~/entities/favorites/interface'
import { FavoriteEntityEnum, keyOfEntity } from '~/entities/favorites/enums'

export class FavoritesService {
  private apolloService: ApolloService

  constructor(apolloService: ApolloService) {
    this.apolloService = apolloService
  }

  getFavorites(params: FavoritesListParams = {}) {
    return this.apolloService
      .getQuery(Favorites, params)
      .then(toPaginatedModels(FavoriteModel))
      .then((data) => {
        data.models = data.models.filter((i) => !!i.favoritable)
        return data
      })
  }

  favorite(type: FavoriteEntityEnum, id: string): Promise<void> {
    const input: FavoriteEntityInput = { type, id, collection: keyOfEntity(type) }
    return this.apolloService.executeMutation(FavoriteEntity(type), { input }).then()
  }

  unFavorite(type: FavoriteEntityEnum, id: string): Promise<void> {
    const input: UnFavoriteEntityInput = { type, id, collection: keyOfEntity(type) }
    return this.apolloService.executeMutation(UnFavoriteEntity(type), { input }).then()
  }
}
