import { gql } from 'graphql-tag'
import { ArticleFieldsForCard } from '~/entities/article/gql/fragments'

export default gql`
  ${ArticleFieldsForCard}
  query getPreviousArticle($id: UUID!) {
    previousActivePublication(id: $id) {
      ...ArticleFieldsForCard
    }
  }
`
