import type { DownloadFile } from '~/interfaces/flle'

export function useFileDownloader() {
  const downloadFile = (data: DownloadFile): void => {
    const downloadLink: HTMLAnchorElement = document.createElement('a')

    downloadLink.href = 'data:application/octet-stream;base64,' + data.content

    downloadLink.download = data.filename

    document.body.appendChild(downloadLink)

    downloadLink.click()

    document.body.removeChild(downloadLink)
  }

  return { downloadFile }
}
