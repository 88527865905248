import { BaseModel } from '.'

export function convertToArrayModels<T extends BaseModel>(
  data: unknown,
  constructor: new (...args: any[]) => T
): Array<T> {
  if (Array.isArray(data) && data.length) {
    return data.map((i) => new constructor(i))
  }

  return []
}
