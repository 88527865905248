import { ApolloService, toModels, toModel } from '~/shared/api'
import {
  SchoolsInstitutionsForMap,
  SchoolsInstitutionsRegionsWithLocality,
  SchoolsInstitutions,
  SchoolInstitution,
} from '~/entities/school/gql/query'
import { SchoolOrganizationModel } from '~/entities/school/model'
import { BaseModel, RegionModel } from '~/shared/model'
import type { SchoolsListParams } from '~/entities/school/interface'
import { cache } from '~/shared/utils/decorators' // eslint-disable-line @typescript-eslint/no-unused-vars
import type { HasCache } from '~/shared/interface'

interface SchoolsService extends HasCache<Array<BaseModel>> {}

@cache('LRU')
// eslint-disable-next-line no-redeclare
class SchoolsService {
  private apolloService: ApolloService

  constructor(apolloService: ApolloService) {
    this.apolloService = apolloService
  }

  getSchoolsForMap(): Promise<Array<SchoolOrganizationModel>> {
    return this.apolloService.getQuery(SchoolsInstitutionsForMap).then(toModels(SchoolOrganizationModel))
  }

  getSchoolsRegions(): Promise<Array<RegionModel>> {
    const cacheKey = 'schools-regions'
    const cachedValue = this._cacheInstance.get(cacheKey)
    if (cachedValue !== undefined) {
      return Promise.resolve(cachedValue as Array<RegionModel>)
    }

    return this.apolloService
      .getQuery(SchoolsInstitutionsRegionsWithLocality)
      .then(toModels(RegionModel))
      .then((regions) => {
        this._cacheInstance.set(cacheKey, regions)
        return regions
      })
  }

  getSchools(params: SchoolsListParams = {}): Promise<Array<SchoolOrganizationModel>> {
    return this.apolloService.getQuery(SchoolsInstitutions, params).then(toModels(SchoolOrganizationModel))
  }

  getSchoolByHid(hid: string): Promise<SchoolOrganizationModel> {
    return this.apolloService
      .getQuery(SchoolInstitution, { organizationHid: hid })
      .then(toModel(SchoolOrganizationModel))
  }
}

export default SchoolsService
