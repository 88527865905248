import type { DadataService } from '.'
import type { SuggestionAddressInput } from '~/entities/dadata/interface'
import { toModels } from '~/shared/api'
import { AddressModel } from '~/entities/dadata/model'

export class AddressService {
  protected url = 'suggest/address'
  protected rootService: DadataService

  constructor(root: DadataService) {
    this.rootService = root
  }

  suggestions(params: SuggestionAddressInput) {
    const url = `${this.rootService.baseUrl}${this.url}`
    return this.rootService.getSuggestions(url, params).then(toModels(AddressModel))
  }
}
