import { BaseModel } from '~/shared/model'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'
import type { DownloadFile as AbstractDownloadFile } from '~/interfaces/flle'

export default class DownloadFile extends BaseModel implements AbstractDownloadFile {
  content: string = ''
  filename: string = ''
  mimetype: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
