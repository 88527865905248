import { computed, onMounted, ref } from '@nuxtjs/composition-api'
import { DEFAULT_MOBILE_WIDTH, DEFAULT_TABLET_WIDTH } from '~/shared/const'

export const useDevices = () => {
  const currentDeviceWidth = ref(0)

  onMounted(() => {
    new ResizeObserver(() => {
      const width = window.innerWidth

      if (width) {
        setDeviceWidth(window.innerWidth)
      }
    }).observe(document.body)
  })

  const setDeviceWidth = (width: number) => {
    currentDeviceWidth.value = width
  }

  const isDesktop = computed(() => {
    return currentDeviceWidth.value > DEFAULT_TABLET_WIDTH
  })

  const isTablet = computed(() => {
    return currentDeviceWidth.value > DEFAULT_MOBILE_WIDTH && currentDeviceWidth.value <= DEFAULT_TABLET_WIDTH
  })

  const isMobile = computed(() => {
    return currentDeviceWidth.value <= DEFAULT_MOBILE_WIDTH
  })

  return { isDesktop, isMobile, isTablet }
}
