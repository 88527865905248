import { BaseModel } from '~/shared/model'
import { SchoolOrganizationModel } from '~/entities/school/model'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'

export default class ClassInstitution extends BaseModel {
  id: string = ''
  link: string = ''
  name: string = ''
  title: string = ''
  school: SchoolOrganizationModel | null = null

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['institution'])

      if (isUnknownObject(data.institution)) {
        this.school = new SchoolOrganizationModel(data.institution)
      }
    }
  }
}
