
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { SvoiTooltipped } from '@svoi-ui/components/tooltip'
import { SizesEnum, Sizes, IconType } from '@svoi-ui/interfaces/icon'
import { PositionsEnum as TooltipPositions } from '@svoi-ui/interfaces/tooltip'
import { isString } from '@svoi-ui/shared/utils/guards'
import { SvoiIcon } from '.'

export default defineComponent({
  name: 'SvoiTooltippedIcon',
  components: { SvoiTooltipped, SvoiIcon },
  props: {
    icon: {
      type: [String, Object] as PropType<IconType>,
      required: true
    },
    size: {
      type: String as PropType<Sizes>,
      default: SizesEnum.m,
      validator(value: string) {
        return [
          SizesEnum.xs.toString(),
          SizesEnum.s.toString(),
          SizesEnum.m.toString(),
          SizesEnum.l.toString(),
          SizesEnum.full.toString()
        ].includes(value)
      }
    }
  },
  emits: ['click'],
  setup() {
    return { TooltipPositions }
  },
  methods: { isString }
})
