import { gql } from 'graphql-tag'

export default (on: string) => gql`
  fragment SubjectMainFields on ${on} {
    id
    status
    title
    createdAt
    description
  }
`
