export enum ThemesEnum {
  dark = 'dark',
  white = 'white'
}

export enum PositionsEnum {
  bottomLeft = 'bottom-left',
  bottomRight = 'bottom-right',
  topLeft = 'top-left',
  topRight = 'top-right',
  leftTop = 'left-top',
  leftBottom = 'left-bottom',
  rightTop = 'right-top',
  rightBottom = 'right-bottom'
}

export type Themes = ThemesEnum.dark | ThemesEnum.white
export type Positions =
  | PositionsEnum.bottomLeft
  | PositionsEnum.bottomRight
  | PositionsEnum.topLeft
  | PositionsEnum.topRight
  | PositionsEnum.leftTop
  | PositionsEnum.leftBottom
  | PositionsEnum.rightTop
  | PositionsEnum.rightBottom
