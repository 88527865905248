import { BaseModel, convertToArrayModels, MediaModel } from '~/shared/model'
import { isString, isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { UserModel } from '~/entities/user/model'
import { StatusModel } from '~/entities/workflow/model'
import { CommentModel } from '~/entities/comments/model'
import type { Commentable } from '~/entities/comments/interface'
import { EntityType as CommentableEntityType } from '~/entities/comments/enums'

export default class Feedback extends BaseModel implements Commentable {
  id: string = ''
  commentableType = CommentableEntityType.feedback
  number: number = 0
  name: number = 0
  email: string = ''
  createdAt: string = ''
  closedAt: string = ''
  message: string = ''
  status: StatusModel | null = null
  user: UserModel | null = null
  assignee: UserModel | null = null
  attachments: MediaModel[] = []
  comments: Commentable['comments'] = {
    data: [],
    pagination: null,
  }

  constructor(data: unknown) {
    super()

    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['status', 'user', 'assignee', 'attachments'])

      if (isUnknownObject(data.user)) {
        this.user = new UserModel(data.user)
      }

      if (isUnknownObject(data.assignee)) {
        this.assignee = new UserModel(data.assignee)
      }

      if (isUnknownObject(data.status)) {
        this.status = new StatusModel(data.status)
      }

      if (
        isUnknownObject(data.lastStatusTransitionHistoryRecord) &&
        isString(data.lastStatusTransitionHistoryRecord.createdAt)
      ) {
        this.closedAt = data.lastStatusTransitionHistoryRecord.createdAt
      }

      this.attachments = convertToArrayModels(data.attachments, MediaModel)

      if (isUnknownObject(data.comments)) {
        if (Array.isArray(data.comments.data) && data.comments.data.length) {
          this.comments.data = data.comments.data.map((c) => new CommentModel(c))
        }
      }
    }
  }
}
