
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { SvoiLabel } from '@svoi-ui/components/label'
import { SvoiTable } from '@svoi-ui/components/table'
import type { TableItem } from '@svoi-ui/interfaces/table'
import { ThemesEnum } from '@svoi-ui/interfaces/label'
import { LoaderWrapper, PaginationWrapper } from '~/shared/ui/base'
import { EmptyResult } from '~/shared/ui/banners'
import { toDateFormat } from '~/shared/utils/date'
import { previewUserFIO } from '~/shared/utils/helpers'
import type { UserProfileModel } from '~/entities/user/model'

export default defineComponent({
  components: { LoaderWrapper, EmptyResult, PaginationWrapper, SvoiLabel, SvoiTable },

  props: {
    items: {
      type: Array as PropType<Array<TableItem>>,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    baseUrl: {
      type: String,
      required: true,
    },
    totalPages: {
      type: Number,
      default: 1,
    },
    activePage: {
      type: Number,
      default: 1,
    },
  },

  setup() {
    const header = [
      { id: 1, title: 'Номер заявки', key: 'number', order: 1, sticky: true },
      { id: 2, title: 'Дата создания', key: 'createdAt', order: 2 },
      { id: 3, title: 'Дата закрытия', key: 'closedAt', order: 3 },
      { id: 4, title: 'Статус', key: 'status', order: 4 },
      { id: 5, title: 'Заявитель', key: 'requester', order: 6 },
    ]

    const getThemeLabel = (type: string): ThemesEnum => {
      switch (type) {
        case 'feedback_new':
          return ThemesEnum.chilli
        case 'feedback_processed':
          return ThemesEnum.figs
        default:
          return ThemesEnum.qiwi
      }
    }

    const getFIOUser = (profile: UserProfileModel) => {
      return previewUserFIO(profile.surName, profile.name, profile.middleName)
    }

    return { header, getThemeLabel, toDateFormat, getFIOUser }
  },
})
