import { gql } from 'graphql-tag'
import { CategoryMainFields } from '~/entities/category/gql/fragments'

export default gql`
  ${CategoryMainFields}
  query getCategories {
    categories {
      ...CategoryMainFields
      children {
        ...CategoryMainFields
        children {
          ...CategoryMainFields
          children {
            ...CategoryMainFields
            children {
              ...CategoryMainFields
              children {
                ...CategoryMainFields
              }
            }
          }
        }
      }
    }
  }
`
