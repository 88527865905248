import { ApolloService, toBooleanResponse, toModel, toPaginatedModels } from '~/shared/api'
import type {
  ClassListParams,
  ClassListType,
  ClassPostfixListParams,
  DeleteClassInput,
} from '~/entities/class/interface'
import { CuratorClasses, PostfixClasses, StudentClasses } from '~/entities/class/gql/query'
import {
  AssignLearningProgramLessonToClass,
  CreateClass,
  DeleteClass,
  EditClass,
  RevokeLearningProgramLessonFromClass,
  UpdateAssignLearningProgramLessonToClass,
} from '~/entities/class/gql/mutation'
import { ClassModel, PostfixClassModel, StudentClassModel } from '~/entities/class/model'
import { LessonModel } from '~/entities/lesson/model'
import { AssignLessonToClassForm, CreateForm, EditForm } from '~/entities/class/api'
import { messages } from '~/shared/const'
import type { WithoutPrivate } from '~/shared/utils/generics'

export default class ClassService {
  private apolloService: ApolloService

  constructor(apolloService: ApolloService) {
    this.apolloService = apolloService
  }

  getClasses(type: ClassListType, params: ClassListParams = {}) {
    const query = type === 'curator' ? CuratorClasses : StudentClasses
    return this.apolloService.getQuery(query, params).then(toPaginatedModels(StudentClassModel))
  }

  assignLearningProgramLessonToClass(form: WithoutPrivate<AssignLessonToClassForm>): Promise<LessonModel> {
    return this.apolloService
      .executeMutation(AssignLearningProgramLessonToClass, { input: form.getAssignInputData() })
      .then(toModel(LessonModel, messages.errors.create))
  }

  updateAssignLearningProgramLessonToClass(form: WithoutPrivate<AssignLessonToClassForm>): Promise<LessonModel> {
    return this.apolloService
      .executeMutation(UpdateAssignLearningProgramLessonToClass, { input: form.getUpdateAssignInputData() })
      .then(toModel(LessonModel, messages.errors.update))
  }

  revokeLearningProgramLessonFromClass(id: string): Promise<void> {
    return this.apolloService
      .executeMutation(RevokeLearningProgramLessonFromClass, { input: { id } })
      .then(toBooleanResponse(messages.errors.delete))
      .then((data) => {
        if (data) {
          return undefined
        }

        throw new Error(messages.errors.delete)
      })
  }

  createClass(form: CreateForm): Promise<ClassModel> {
    return this.apolloService
      .executeMutation(CreateClass, { input: form.getInputData() })
      .then(toModel(ClassModel, messages.errors.create))
  }

  updateClass(form: EditForm): Promise<ClassModel> {
    return this.apolloService
      .executeMutation(EditClass, { input: form.getInputData() })
      .then(toModel(ClassModel, messages.errors.update))
  }

  deleteClass(id: string): Promise<void> {
    const input: DeleteClassInput = { id }
    return this.apolloService
      .executeMutation(DeleteClass, { input })
      .then(toBooleanResponse(messages.errors.delete))
      .then((data) => {
        if (data) {
          return undefined
        }

        throw new Error(messages.errors.delete)
      })
  }

  getListPostfixClasses(params: ClassPostfixListParams = {}) {
    return this.apolloService.getQuery(PostfixClasses, params).then(toPaginatedModels(PostfixClassModel))
  }
}
