import { fillOwnProperties, getUIUniqId } from '~/shared/utils/helpers'
import { AtLeastOne } from '~/shared/utils/generics'
import { requiredRule } from '~/shared/utils/validation'
import { isUIUniqid } from '~/shared/utils/guards'
import type { SectionDataQuizAnswerInput, TestAnswer } from '~/entities/lesson/interface'
import type { UIUniqId } from '~/shared/types'

export default class AnswerForm {
  id: string | UIUniqId = getUIUniqId()
  title: string = ''
  description: string = ''
  content: string = ''
  points: number = 0
  order: number = 1

  constructor(answer?: AtLeastOne<TestAnswer>) {
    fillOwnProperties(this, { ...answer })
  }

  toInputData(): SectionDataQuizAnswerInput {
    return {
      ...(this.id && !isUIUniqid(this.id) ? { id: this.id } : {}),
      title: this.title,
      description: this.description,
      content: this.content,
      points: this.points,
      order: this.order,
    }
  }

  static rules() {
    return {
      id: [],
      title: requiredRule,
      points: [],
      order: [],
    }
  }
}
