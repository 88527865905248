
import { defineComponent, PropType, computed } from '@nuxtjs/composition-api'
import Draggable from 'vuedraggable'
import { SvoiIcon } from '@svoi-ui/components/icon'
import type { FileData } from '@svoi-ui/interfaces/file-picker'
import { isFile } from '@svoi-ui/shared/utils/guards'
import { uuid } from '@svoi-ui/shared/utils/helpers'

export default defineComponent({
  name: 'SvoiImagesList',
  components: { Draggable, SvoiIcon },
  props: {
    value: {
      type: Array as PropType<Array<File | FileData>>,
      default: [] as Array<File | FileData>
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'on-delete-file'],
  setup(props, { emit }) {
    const classes = computed(() => ({
      '-disabled': props.disabled
    }))

    const convertToFileData = (file: File): FileData => {
      return {
        id: uuid(),
        name: file.name,
        path: URL.createObjectURL(file),
        origin: file
      }
    }

    const originList = computed({
      get: () => props.value,
      set: val => {
        if (!props.disabled) {
          emit('input', val)
        }
      }
    })
    const list = computed(() => props.value?.map(i => (isFile(i) ? convertToFileData(i) : i)))

    const onClickDeleteFile = (file: FileData) => {
      if (!props.disabled) {
        emit('on-delete-file', file.origin ?? file)
      }
    }

    return { list, originList, classes, onClickDeleteFile }
  }
})
