import { render, staticRenderFns } from "./FooterMenu.vue?vue&type=template&id=59d1f3f6&scoped=true&"
import script from "./FooterMenu.vue?vue&type=script&lang=ts&"
export * from "./FooterMenu.vue?vue&type=script&lang=ts&"
import style0 from "./FooterMenu.vue?vue&type=style&index=0&id=59d1f3f6&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59d1f3f6",
  null
  
)

export default component.exports