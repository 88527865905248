// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../assets/fonts/Golos/Golos-Text/Golos-Text_Medium.woff");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:Golos;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\");font-weight:400;font-style:normal}@font-face{font-family:Golos;src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff\");font-weight:500;font-style:normal}.svoi-hint[data-v-0ddde453]{font-family:Golos,sans-serif;font-size:12px;font-weight:400;line-height:16px;display:-webkit-box;display:-webkit-flex;display:-ms-flexbox;display:flex;-webkit-box-orient:horizontal;-webkit-box-direction:normal;-webkit-flex-flow:row wrap;-ms-flex-flow:row wrap;flex-flow:row wrap}.svoi-hint.-theme-qiwi[data-v-0ddde453]{color:#42ab44}.svoi-hint.-theme-figs[data-v-0ddde453]{color:#2e5aac}.svoi-hint.-theme-banana[data-v-0ddde453]{color:#ffd53f}.svoi-hint.-theme-chilli[data-v-0ddde453]{color:#fa1255}.svoi-hint.-theme-potato[data-v-0ddde453]{color:#7d7e7f}.svoi-hint .icon[data-v-0ddde453]{-webkit-flex-shrink:0;-ms-flex-negative:0;flex-shrink:0;margin-right:8px}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
