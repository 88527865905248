import { gql } from 'graphql-tag'
import { LessonMainFields } from '~/entities/lesson/gql/fragments'

export default gql`
  ${LessonMainFields('LearningProgramLessonType')}
  mutation updateAssignLessonToClass($input: UpdateClassLessonInput!) {
    updateClassLesson(input: $input) {
      ...LessonMainFields
    }
  }
`
