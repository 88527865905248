import { BaseModel, MediaModel } from '~/shared/model'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'

export default class Category extends BaseModel {
  id: string = ''
  title: string = ''
  slug: string = ''
  parent: Category | null = null
  children: Array<Category> = []
  preview: MediaModel | null = null

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['parent', 'children', 'preview'])

      if (isUnknownObject(data.parent)) {
        this.parent = new Category(data.parent)
      }

      if (Array.isArray(data.children) && data.children.length) {
        this.children = data.children.map((i) => new Category(i))
      }

      if (Array.isArray(data.preview) && data.preview.length) {
        this.preview = new MediaModel(data.preview[0])
      }
    }
  }
}
