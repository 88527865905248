
import { defineComponent } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    active: {
      type: Boolean,
      default: false
    }
  },

  setup(props, { emit }) {
    const onClick = () => {
      if (!props.disabled) {
        emit('click')
      }
    }

    return {
      onClick
    }
  }
})
