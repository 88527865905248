import { PROFILE_ROUTE, DEFAULT_ACCOUNT_ROUTE, NEWS_ROUTE, PUBLICATIONS_ROUTE } from '.'
import type {
  FooterMenuGroup,
  FooterMenuItem,
  NavBarBothMenuItem,
  NavBarMainMenuItem,
  HeaderMenuItem,
} from '~/shared/interface'
import { PermissionsEnum } from '~/shared/enums'

export const navBarMenusMainItems: Array<NavBarMainMenuItem> = [
  {
    id: 1,
    name: 'Основная информация',
    url: DEFAULT_ACCOUNT_ROUTE,
    permissions: null,
    icon: 'building/house',
    exact: true,
  },
  {
    id: 2,
    name: 'Заявки на регистрацию',
    url: '/account/applications',
    permissions: [PermissionsEnum.viewAnySchoolsClassJoinRequest],
    icon: 'users/profile-add',
  },
  {
    id: 3,
    name: 'Мои агроклассы',
    url: '/account/agroclasses',
    permissions: [PermissionsEnum.viewAnySchoolsStudentClass, PermissionsEnum.viewAnySchoolsCuratorClass],
    icon: 'users/people',
  },
  {
    id: 4,
    name: 'Назначенные уроки',
    url: '/account/scheduled-lessons',
    permissions: [PermissionsEnum.viewAnyLearningClassLesson],
    icon: 'learning/teacher',
  },
  {
    id: 5,
    name: 'Моя программа',
    url: '/account/program',
    permissions: [PermissionsEnum.viewAnyLearningProgramSubject],
    icon: 'learning/note-2',
  },
  {
    id: 6,
    name: 'База знаний',
    url: '/account/knowledge-base',
    permissions: [PermissionsEnum.viewAnyLearningBaseActiveSubject],
    icon: 'learning/book-1',
  },
  {
    id: 7,
    name: 'Мои статьи',
    url: '/account/articles',
    permissions: [PermissionsEnum.viewAnyMyPublications],
    icon: 'learning/note-2',
  },
  {
    id: 8,
    name: 'Статьи на проверку',
    url: '/account/articles-moderation',
    permissions: [PermissionsEnum.viewAnyPublication],
    icon: 'money/receipt-edit',
  },
  {
    id: 9,
    name: 'Обращения',
    url: '/account/feedback',
    permissions: [PermissionsEnum.viewAnyFeedback],
    icon: 'messages/messages-3',
  },
]

export const navBarMenusBothItems: Array<NavBarBothMenuItem> = [
  {
    id: 1,
    name: 'Настройки',
    url: PROFILE_ROUTE,
    action: null,
    permissions: [PermissionsEnum.editPersonalData, PermissionsEnum.createClassJoinRequest],
    icon: 'users/user-edit',
  },
  {
    id: 2,
    name: 'Выйти',
    url: '#logout',
    action: 'logout',
    permissions: null,
    icon: 'arrow/logout-1',
  },
]

export const footerMenusGroups: Array<FooterMenuGroup> = [
  {
    name: 'О нас',
    items: [
      {
        name: 'О Россельхозбанке',
        url: 'https://www.rshb.ru/about/',
      },
      {
        name: 'Новости',
        url: NEWS_ROUTE,
      },
    ],
  },
  {
    name: 'Пользователям',
    items: [
      {
        name: 'Школьникам',
        url: DEFAULT_ACCOUNT_ROUTE,
      },
      {
        name: 'Кураторам',
        url: DEFAULT_ACCOUNT_ROUTE,
      },
      {
        name: 'Родителям',
        url: '',
      },
    ],
  },
  {
    name: 'По всем вопросам',
    items: [
      {
        name: 'Обратная связь',
        url: 'mailto:svoevagro-help@rshb.ru',
      },
    ],
  },
]

export const footerBottomMenu: Array<FooterMenuItem> = [
  {
    name: 'Политика обработки персональных данных',
    url: 'https://www.rshb.ru/pd-policy/',
  },
  {
    name: 'Пользовательское соглашение',
    url: 'https://svoevagro.ru/documents/terms.pdf',
  },
]

export const headerMenu: Array<HeaderMenuItem> = [
  {
    name: 'Новости',
    url: NEWS_ROUTE,
    icon: 'notifications/notification-1',
  },
  {
    name: 'Журнал',
    url: PUBLICATIONS_ROUTE,
    icon: 'learning/book-1',
  },
]
