import { computed, defineComponent, PropType, ref } from '@nuxtjs/composition-api'
import { Sizes, Type } from '@svoi-ui/interfaces/input'
import { isRefObject } from '@svoi-ui/shared/utils/guards'

/* eslint-disable-next-line @typescript-eslint/naming-convention */
export const BaseInputComponent = defineComponent({
  name: 'BaseInput',
  props: {
    value: {
      type: String,
      default: ''
    },
    type: {
      type: String as PropType<Type>,
      default: 'text',
      validator(value: string) {
        return ['text', 'password', 'number'].includes(value)
      }
    },
    size: {
      type: String as PropType<Sizes>,
      default: 'l',
      validator(value: string) {
        return ['l', 'm'].includes(value)
      }
    },
    label: {
      type: String,
      default: ''
    },
    /** @deprecated */
    hint: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    readonly: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: Infinity
    },
    placeholder: {
      type: String,
      default: ''
    },
    tabindex: {
      type: Number,
      default: 0
    }
  },
  emits: ['input', 'change', 'focus', 'blur', 'on-click-icon', 'keyup'],
  setup(props, { emit }) {
    const focus = ref(false)
    const inputRef = ref<HTMLInputElement>()

    const computedValue = computed({
      get() {
        return String(props.value)
      },
      set(value) {
        emit('input', value)
      }
    })

    const classes = computed(() => {
      return {
        [`-size-${props.size}`]: true,
        '-focus': isRefObject(focus) && focus.value,
        '-filled': isRefObject<string>(computedValue) && computedValue.value.length > 0,
        '-disabled': props.disabled,
        '-error': props.error
      }
    })

    const computedType = ref(String(props.type))

    const onClickEye = () => {
      computedType.value = computedType.value === 'password' ? 'text' : 'password'
    }

    const iconEye = computed(() => (computedType.value === 'password' ? 'security/eye' : 'security/eye-slash'))

    const onActivate = () => {
      if (!props.disabled) {
        focus.value = true
        emit('focus')
      }
    }

    const onBlur = () => {
      focus.value = false
      emit('blur')
    }

    const onChange = (event: Event) => {
      emit('change', (event.target as HTMLInputElement).value)
    }

    const triggerFocus = () => {
      if (inputRef.value) {
        inputRef.value.focus()
      }
    }

    const triggerBlur = () => {
      if (inputRef.value) {
        inputRef.value.blur()
      }
    }

    const onClickIcon = () => {
      if (!props.disabled) {
        emit('on-click-icon')
      }
    }

    return {
      inputRef,
      computedValue,
      classes,
      focus,
      computedType,
      iconEye,
      onActivate,
      onBlur,
      onChange,
      onClickEye,
      onClickIcon,
      triggerFocus,
      triggerBlur
    }
  }
})
