import { gql } from 'graphql-tag'
import type { StatisticCounters } from '~/shared/interface'

export default (iterCounters: Iterable<StatisticCounters>) => {
  const counters = new Set(iterCounters)
  const chunks: Array<string> = []

  for (const counter of counters) {
    switch (counter) {
      case 'newApplications': {
        chunks.push(`
          newApplications: classJoinRequests(first: 9999, filter: { status: REVIEW }) {
            paginatorInfo {
              count
            }
          }
        `)
        break
      }
      case 'favorites': {
        chunks.push(`
          favorites(first: 9999) {
            paginatorInfo {
              count
            }
          }
        `)
        break
      }
    }
  }

  return gql`
    query statistic {
      ${chunks.join()}
    }
  `
}
