import { requiredRule, passwordRule, sameAsRule } from '~/shared/utils/validation'
import { singleton } from '~/shared/utils/decorators' // eslint-disable-line @typescript-eslint/no-unused-vars
import type { UpdatePasswordInput } from '~/entities/user/interfaces'

@singleton
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class UpdatePasswordForm {
  currentPassword: string = ''
  password: string = ''
  passwordConfirmation: string = ''

  getInputData(): UpdatePasswordInput {
    return {
      current_password: this.currentPassword,
      password: this.password,
      password_confirmation: this.passwordConfirmation,
    }
  }

  clear() {
    this.currentPassword = ''
    this.password = ''
    this.passwordConfirmation = ''
  }

  static getInstance() {
    return new UpdatePasswordForm()
  }

  static rules() {
    const self = this.getInstance()
    return {
      currentPassword: { ...requiredRule, ...passwordRule },
      password: { ...requiredRule, ...passwordRule },
      passwordConfirmation: { ...requiredRule, ...sameAsRule(self, 'password', 'Пароли не совпадают') },
    }
  }
}
