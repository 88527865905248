import { gql } from 'graphql-tag'
import { FileFields, PaginationFields } from '~/shared/gql/fragments'

export default gql`
  ${PaginationFields('ClassJoinRequestPaginator')}
  ${FileFields('MediaType')}
  query classJoinRequests(
    $first: Int = 9999
    $page: Int
    $filter: ClassJoinRequestsFilterInput
    $sort: [QueryClassJoinRequestsSortOrderByClause!]
  ) {
    classJoinRequests(first: $first, page: $page, filter: $filter, sort: $sort) {
      data {
        id
        student {
          email
          id
          phone
          profile {
            name
            middleName
            surName
            dateOfBirth
            documents {
              ...FileFields
            }
          }
        }
        status
        class {
          id
          name
        }
      }

      ...PaginationFields
    }
  }
`
