
import { computed, defineComponent, onMounted, PropType, reactive, ref, useContext } from '@nuxtjs/composition-api'
import { useVuelidate } from '@vuelidate/core'
import { SvoiInput } from '@svoi-ui/components/input'
import { SvoiTextarea } from '@svoi-ui/components/textarea'
import { SvoiFilePickerComplex } from '@svoi-ui/components/file-picker'
import { SvoiButton } from '@svoi-ui/components/button'
import { SvoiHint } from '@svoi-ui/components/hint'
import { Modal } from '~/shared/ui/modal'
import { FeedbackForm } from '~/entities/feedback/api'
import { ThemeModel } from '~/entities/feedback/model'
import { useErrors } from '~/composables'
import { FeedbackTypeEnum } from '~/entities/feedback/enums'

export default defineComponent({
  name: 'FeedbackModal',
  components: { SvoiButton, SvoiFilePickerComplex, SvoiTextarea, SvoiInput, SvoiHint, Modal },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    theme: {
      type: Object as PropType<ThemeModel>,
      default: undefined,
    },
    type: {
      type: String as PropType<FeedbackTypeEnum>,
      default: undefined,
    },
    id: {
      type: String,
      default: undefined,
    },
  },
  emits: ['input'],
  setup(props, { emit }) {
    const previewPath = require('~/assets/images/article/feedback_preview.png')

    const { displayError } = useErrors()
    const ctx = useContext()

    const loading = ref<boolean>(false)

    const entity = reactive(new FeedbackForm(ctx.$tstore.user.authorizedUser))
    const form = useVuelidate(FeedbackForm.rules(), entity)

    onMounted(() => {
      form.value.id.$model = props.id
      form.value.type.$model = props.type ?? null
      form.value.theme.$model = props.theme ?? null
    })

    const buttonSettings = computed(() => ({
      disabled: form.value.$invalid,
      name: loading.value ? 'Отправляем' : 'Отправить',
      loading: loading.value,
    }))
    const themeLabel = computed(() => {
      return form.value.theme.$model ? form.value.theme.$model.value : ''
    })

    const onSubmit = () => {
      form.value.$validate().then((isValid) => {
        if (isValid) {
          loading.value = true

          displayError(
            ctx.$services.feedback
              .sendFeedback(entity)
              .then(({ number }) => {
                ctx.$tstore.notifications.pushInfo({
                  title: `Заявка №${number} создана`,
                  text: 'Спасибо за обращение! В ближайшее время мы свяжемся с вами по указанным контактам.',
                })

                closeModal()
              })
              .finally(() => {
                loading.value = false
              })
          )
        }
      })
    }

    const closeModal = () => {
      emit('input', false)
    }

    return { previewPath, form, buttonSettings, themeLabel, onSubmit, closeModal }
  },
})
