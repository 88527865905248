
import { defineComponent, PropType, computed } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { Themes, ThemesEnum } from '@svoi-ui/interfaces/hint'

export default defineComponent({
  name: 'SvoiHint',
  components: { SvoiIcon },
  props: {
    theme: {
      type: String as PropType<Themes>,
      default: ThemesEnum.qiwi,
      validator: (value: string) => {
        return [
          ThemesEnum.qiwi.toString(),
          ThemesEnum.figs.toString(),
          ThemesEnum.banana.toString(),
          ThemesEnum.chilli.toString(),
          ThemesEnum.potato.toString()
        ].includes(value)
      }
    },
    icon: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const classes = computed(() => ({
      [`-theme-${props.theme}`]: true
    }))

    return { classes }
  }
})
