import { gql } from 'graphql-tag'
import { FileFields } from '~/shared/gql/fragments'

export default gql`
  ${FileFields('MediaType')}
  fragment CategoryMainFields on CategoryType {
    id
    title
    slug
    preview {
      ...FileFields
    }
  }
`
