import { gql } from 'graphql-tag'

export default gql`
  query schoolInstitutionByHid($organizationHid: String) {
    schoolsInstitution(organizationHid: $organizationHid) {
      id
      link
    }
  }
`
