const errors = {
  request: 'Возникли ошибки при выполнении запроса.',
  get: 'Возникли ошибки при получении данных.',
  create: 'Возникли ошибки при сохранении данных.',
  update: 'Возникли ошибки при обновлении данных.',
  delete: 'Возникли ошибки при удалении данных.',
  VkAuthenticate: 'Возникли ошибки при авторизации через VK ID',
  feedback: 'Возникли ошибки при отправке формы обратной связи',
  authorization: 'Возникли ошибки при отправке формы авторизации',
  registration: 'Возникли ошибки при отправке формы регистрации',
}

export const messages = {
  errors,
}
