import { reactive, useContext } from '@nuxtjs/composition-api'
import { useEmitter } from '.'
import type { FeedbackProps } from '~/interfaces/feedback'
import type { FeedbackModal } from '~/widgets/feedback'
import type { DeepMutable } from '~/shared/utils/generics'
import { events } from '~/shared/const'

export const useFeedback = () => {
  const ctx = useContext()
  const { activateMountedListener } = useEmitter()

  const defaultProps: FeedbackProps = {
    value: false,
    theme: undefined,
    type: undefined,
    id: undefined,
  }

  const listenerFeedbackModal = () => {
    const componentFeedbackProps = reactive<Partial<DeepMutable<InstanceType<typeof FeedbackModal>>['$props']>>({
      ...defaultProps,
    })

    const componentFeedbackListeners = {
      input: (value: boolean) => {
        componentFeedbackProps.value = value
      },
    }

    activateMountedListener(events.layout.showFeedbackModal, (props: FeedbackProps) => {
      const { type, value, id, theme } = props

      componentFeedbackProps.type = type
      componentFeedbackProps.value = value
      componentFeedbackProps.id = id
      componentFeedbackProps.theme = theme
    })

    return { componentFeedbackProps, componentFeedbackListeners }
  }

  const openFeedbackModal = (params: Omit<FeedbackProps, 'value'>) => {
    ctx.$emitter.emit(events.layout.showFeedbackModal, { ...params, value: true })
  }

  const closeFeedbackModal = () => {
    ctx.$emitter.emit(events.layout.showFeedbackModal, { ...defaultProps })
  }

  return { listenerFeedbackModal, openFeedbackModal, closeFeedbackModal }
}
