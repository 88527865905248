import { ApolloService, toModels } from '~/shared/api'
import { BaseModel } from '~/shared/model'
import { Tags } from '~/entities/tag/gql/query'
import { TagModel } from '~/entities/tag/model'
import { cache } from '~/shared/utils/decorators' // eslint-disable-line @typescript-eslint/no-unused-vars
import type { TagsListParams } from '~/entities/tag/interface'
import type { HasCache } from '~/shared/interface'

interface TagService extends HasCache<Array<BaseModel>> {}

@cache('LRU', { lifetime: 60 })
// eslint-disable-next-line no-redeclare
class TagService {
  private apolloService: ApolloService

  constructor(apolloService: ApolloService) {
    this.apolloService = apolloService
  }

  getTags(): Promise<Array<TagModel>> {
    const cacheKey = 'tags'
    const cachedValue = this._cacheInstance.get(cacheKey)
    if (cachedValue !== undefined) {
      return Promise.resolve(cachedValue as Array<TagModel>)
    }

    const params: TagsListParams = { sort: [{ column: 'VIEWS', order: 'DESC' }] }

    return this.apolloService
      .getQuery(Tags, params)
      .then(toModels(TagModel))
      .then((tags) => {
        this._cacheInstance.set(cacheKey, tags)
        return tags
      })
  }
}

export default TagService
