import { BaseModel } from '.'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'

export default class PaginationData extends BaseModel {
  currentPage: number = 0
  hasMorePages: boolean = false
  count: number = 0
  total: number = 0
  lastPage: number = 0
  perPage: number = 0

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }
}
