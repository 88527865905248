
import { defineComponent, computed } from '@nuxtjs/composition-api'
import { SvoiInput } from '@svoi-ui/components/input'

export default defineComponent({
  name: 'SearchInput',
  components: { SvoiInput },
  props: {
    value: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: 'Найти',
    },
  },
  emits: ['input', 'search'],
  setup(props, { emit }) {
    const computedValue = computed({
      get: () => props.value,
      set: (val) => emit('input', val),
    })

    return { computedValue }
  },
})
