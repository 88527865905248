import { BaseModel } from '~/shared/model'
import { isString, isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import type { UIUniqId } from '~/shared/types'

export default class PostfixClass extends BaseModel {
  id: string | UIUniqId = ''
  postfix: string = ''

  constructor(data?: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['postfix'])

      if (isString(data.postfix)) {
        this.postfix = data.postfix
      }
    }
  }
}
