import { gql } from 'graphql-tag'
import { PaginationFields } from '~/shared/gql/fragments'
import { ArticleFieldsForCard } from '~/entities/article/gql/fragments'

export default gql`
  ${PaginationFields('FavoriteTypePaginator')}
  ${ArticleFieldsForCard}
  query getFavorites(
    $first: Int = 9999
    $page: Int
    $filter: FavoritesFilterInput
    $sort: [QueryFavoritesSortOrderByClause!]
  ) {
    favorites(first: $first, page: $page, filter: $filter, sort: $sort) {
      data {
        id
        collection
        favoritable {
          ... on PublicationType {
            ...ArticleFieldsForCard
            isFavorited(collection: "article")
          }
        }
      }

      ...PaginationFields
    }
  }
`
