import type { Permission, PermissionId } from '~/shared/interface'

export const permissions: { [key in PermissionId]?: Permission } = {
  viewAnySchoolsClassJoinRequest: {
    allowedRoutes: [/^\/account\/applications\/?$/],
  },
  editPersonalData: {
    allowedRoutes: [/^\/account\/profile\/?$/],
  },
  createClassJoinRequest: {
    allowedRoutes: [/^\/account\/profile\/?$/],
  },
  viewAnyLearningBaseActiveSubject: {
    allowedRoutes: [/^\/account\/knowledge-base\/?$/],
  },
  viewLearningBaseActiveSubject: {
    allowedRoutes: [/^\/account\/knowledge-base\/[\w-]{36}\/?$/],
  },
  createLearningKnowledgeBaseLesson: {
    allowedRoutes: [/^\/account\/knowledge-base\/lesson\/create\/?$/],
  },
  updateLearningKnowledgeBaseLesson: {
    allowedRoutes: [/^\/account\/knowledge-base\/lesson\/edit\/[\w-]{36}\/?$/],
  },
  viewLearningKnowledgeBaseLesson: {
    allowedRoutes: [/^\/account\/knowledge-base\/lesson\/view\/[\w-]{36}\/?$/],
  },
  viewLearningKnowledgeBaseActiveLesson: {
    allowedRoutes: [/^\/account\/knowledge-base\/lesson\/view\/[\w-]{36}\/?$/],
  },
  viewAnyLearningProgramSubject: {
    allowedRoutes: [/^\/account\/program\/?$/],
  },
  viewLearningProgramSubject: {
    allowedRoutes: [/^\/account\/program\/[\w-]{36}\/?$/],
  },
  createLearningProgramLesson: {
    allowedRoutes: [/^\/account\/program\/lesson\/create\/?$/],
  },
  updateLearningProgramLesson: {
    allowedRoutes: [/^\/account\/program\/lesson\/edit\/[\w-]{36}\/?$/],
  },
  viewLearningProgramLesson: {
    allowedRoutes: [/^\/account\/program\/lesson\/view\/[\w-]{36}\/?$/],
  },
  viewAnyLearningClassLesson: {
    allowedRoutes: [/^\/account\/scheduled-lessons\/?$/],
  },
  viewLearningClassLesson: {
    allowedRoutes: [/^\/account\/scheduled-lessons\/[\w-]{36}\/?$/],
  },
  viewAnySchoolsStudentClass: {
    allowedRoutes: [/^\/account\/agroclasses\/?$/],
  },
  viewAnySchoolsCuratorClass: {
    allowedRoutes: [/^\/account\/agroclasses\/?$/],
  },
  generateAgreementsDocuments: {
    allowedRoutes: [/^\/account\/agreements\/generate\/?$/],
  },
  viewAnyMyPublications: {
    allowedRoutes: [/^\/account\/articles\/?$/, /^\/account\/articles\/[\w-]{36}\/?$/],
  },
  viewAnyPublication: {
    allowedRoutes: [/^\/account\/articles-moderation\/?$/, /^\/account\/articles-moderation\/[\w-]{36}\/?$/],
  },
  createPublication: {
    allowedRoutes: [/^\/account\/articles\/create\/?$/],
  },
  updatePublication: {
    allowedRoutes: [/^\/account\/articles\/edit\/[\w-]{36}\/?$/],
  },
  updateAnyPublication: {
    allowedRoutes: [/^\/account\/articles-moderation\/edit\/[\w-]{36}\/?$/],
  },
  viewAnyFeedback: {
    allowedRoutes: [/^\/account\/feedback\/[\w-]{36}\/?$/, /^\/account\/feedback\/?$/],
  },
}
