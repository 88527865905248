import { gql } from 'graphql-tag'
import { LessonMainFields } from '~/entities/lesson/gql/fragments'
import type { LessonListType } from '~/entities/lesson/interface'

export default (type: LessonListType) => {
  let data = 'createLearningKnowledgeBaseLesson'
  let on = 'LearningKnowledgeBaseLessonType'
  let input = 'CreateLearningKnowledgeBaseLessonInput'

  if (type === 'program') {
    data = 'createLearningProgramLesson'
    on = 'LearningProgramLessonType'
    input = 'CreateLearningProgramLessonInput'
  }

  return gql`
    ${LessonMainFields(on)}
    mutation createLesson($input: ${input}!) {
      ${data}(input: $input) {
        ...LessonMainFields
      }
    }
  `
}
