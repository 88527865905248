
import { computed, defineComponent, reactive } from '@nuxtjs/composition-api'
import SvoiSidebarMenuItem from '@svoi-ui/components/sidebar/SvoiSidebarMenuItem.vue'
import SvoiIcon from '@svoi-ui/components/icon/SvoiIcon.vue'
import { Sidebar } from '~/shared/class'

export default defineComponent({
  components: { SvoiIcon, SvoiSidebarMenuItem },
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    hint: {
      type: Number,
      default: 0,
    },
    exact: {
      type: Boolean,
      default: false,
    },
  },

  setup(props) {
    const sidebar = reactive(Sidebar.getInstance())

    const hintTitle = computed(() => (props.hint > 99 ? '99+' : props.hint))

    const isOpenedSidebar = computed(() => sidebar.isOpen)

    const countClosedClass = computed(() => (isOpenedSidebar.value ? 'count--opened' : 'count--closed'))

    return { hintTitle, countClosedClass }
  },
})
