interface ScriptLoaderMetadata {
  attached: boolean
  loaded: boolean
  error: boolean
  resolvers: Array<() => void>
  rejects: Array<() => void>
}

export default class ScriptLoader {
  private static scripts: Map<string, ScriptLoaderMetadata> = new Map()

  static attach(src: string): Promise<void> {
    const metadata = this.getMetadata(src)

    return new Promise((resolve, reject) => {
      if (!metadata.attached) {
        const script = document.createElement('script')
        script.setAttribute('src', src)
        script.setAttribute('async', '')
        document.head.appendChild(script)

        script.onload = () => {
          resolve()
          metadata.loaded = true
          metadata.resolvers.forEach((cb) => cb())
          metadata.resolvers = []
        }

        script.onerror = () => {
          reject(new Error(`Error load script ${src}`))
          metadata.loaded = true
          metadata.error = true
          metadata.rejects.forEach((cb) => cb())
          metadata.rejects = []
        }

        metadata.attached = true
      }

      if (metadata.error) {
        reject(new Error(`Error load script ${src}`))
      }

      if (metadata.loaded) {
        resolve()
      } else {
        metadata.resolvers.push(resolve)
        metadata.rejects.push(reject)
      }
    })
  }

  private static getMetadata(src: string): ScriptLoaderMetadata {
    let metadata: ScriptLoaderMetadata | undefined = this.scripts.get(src)
    if (!metadata) {
      metadata = {
        attached: false,
        loaded: false,
        error: false,
        resolvers: [],
        rejects: [],
      }
      this.scripts.set(src, metadata)
    }

    return metadata
  }
}
