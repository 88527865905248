import { requiredRule } from '~/shared/utils/validation'
import type { ClassInstitutionModel } from '~/entities/class/model'
import type { Nullable } from '~/shared/interface'
import type { CreateClassJoinRequestInput } from '~/entities/user/interfaces'

export default class ClassJoinRequestForm {
  schoolClass: Nullable<ClassInstitutionModel> = null

  constructor(data?: ClassInstitutionModel) {
    this.schoolClass = data ?? null
  }

  getInputData(): CreateClassJoinRequestInput {
    return {
      classId: this.schoolClass?.id ?? '',
    }
  }

  static rules() {
    return {
      schoolClass: requiredRule,
    }
  }
}
