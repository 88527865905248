import { gql } from 'graphql-tag'
import { PaginationFields } from '~/shared/gql/fragments'
import { ClassMainFields } from '~/entities/class/gql/fragments'

export default gql`
  ${ClassMainFields('SchoolsStudentClassType')}
  ${PaginationFields(`SchoolsStudentClassTypePaginator`)}
  query getClasses($first: Int = 9999, $page: Int, $sort: [QuerySchoolsStudentClassesSortOrderByClause!]) {
    schoolsStudentClasses(first: $first, page: $page, sort: $sort) {
      data {
        ...ClassFields

        students {
          id
          profile {
            name
            middleName
            surName
            photo {
              conversions
            }
          }
        }
      }
      ...PaginationFields
    }
  }
`
