
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { LinkTypes, LinkTypesEnum } from '@svoi-ui/interfaces/link-type'

export default defineComponent({
  name: 'BaseLink',
  props: {
    link: {
      type: String,
      required: true
    },
    componentType: {
      type: String as PropType<LinkTypes>,
      default: LinkTypesEnum.nuxtLink,
      validator: (value: unknown) => {
        return (
          typeof value === 'string' && [LinkTypesEnum.a.toString(), LinkTypesEnum.nuxtLink.toString()].includes(value)
        )
      }
    }
  },
  setup() {
    return { LinkTypesEnum }
  }
})
