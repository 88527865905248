import { requiredRule, passportDepartmentRule, passportNumberRule, passportSeriesRule } from '~/shared/utils/validation'
import { singleton } from '~/shared/utils/decorators' // eslint-disable-line @typescript-eslint/no-unused-vars

@singleton
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class PassportForm {
  serial: string = ''
  number: string = ''
  issuedBy: string = ''
  dateOfIssue: string = ''
  address: string = ''
  postalCode: string = ''
  departmentCode: string = ''
  _invalid: boolean = true

  clear() {
    this.serial = ''
    this.number = ''
    this.issuedBy = ''
    this.dateOfIssue = ''
    this.address = ''
    this.postalCode = ''
    this.departmentCode = ''
    this._invalid = true
  }

  toAgreementsData() {
    return {
      passport: {
        passportSerial: this.serial,
        passportNumber: this.number,
        passportIssuer: this.issuedBy,
        passportIssueDate: this.dateOfIssue,
        passportDivisionCode: this.departmentCode,
        zipCode: this.postalCode,
        registrationAddress: this.address,
      },
    }
  }

  static getInstance() {
    return new PassportForm()
  }

  static rules() {
    return {
      serial: { ...requiredRule, ...passportSeriesRule },
      number: { ...requiredRule, ...passportNumberRule },
      issuedBy: requiredRule,
      dateOfIssue: requiredRule,
      address: requiredRule,
      postalCode: requiredRule,
      departmentCode: { ...requiredRule, ...passportDepartmentRule },
    }
  }
}
