
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import Draggable from 'vuedraggable'
import { SvoiIcon } from '@svoi-ui/components/icon'
import type { FileData } from '@svoi-ui/interfaces/file-picker'
import { isFile } from '@svoi-ui/shared/utils/guards'
import { uuid } from '@svoi-ui/shared/utils/helpers'

export default defineComponent({
  name: 'SvoiFilesList',
  components: { Draggable, SvoiIcon },
  props: {
    value: {
      type: Array as PropType<Array<File | FileData>>,
      default: [] as Array<File | FileData>
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input', 'on-delete-file'],
  setup(props, { emit }) {
    const previewFileSize = (size: number): string => {
      let res = size
      let unit = 'Б'

      if (res > 1024) {
        res = res / 1024
        unit = 'Кб'
      }

      if (res > 1024) {
        res = res / 1024
        unit = 'Мб'
      }

      return `${res.toFixed(1)} ${unit}`
    }

    const getFileMetadata = (file: FileData): string => {
      const chunks: Array<string> = []

      if (!file.origin) {
        chunks.push('<span class="status">Загружено</span>')
      }

      if (file.updatedAt) {
        chunks.push(new Date(file.updatedAt).toLocaleString())
      }

      if (file.size) {
        chunks.push(previewFileSize(file.size))
      }

      return chunks.join(' • ')
    }

    const getFileType = (file: FileData): string => {
      if (file.mimeType) {
        const [type, subtype] = file.mimeType.split('/')

        if (type === 'image') {
          return subtype.slice(0, 4).toUpperCase()
        }

        if (subtype === 'vnd.openxmlformats-officedocument.spreadsheetml.sheet' || subtype === 'vnd.ms-excel') {
          return 'XLS'
        }

        if (subtype === 'vnd.openxmlformats-officedocument.wordprocessingml.document' || subtype === 'msword') {
          return 'DOC'
        }

        if (subtype && subtype.length <= 4) {
          return subtype.toUpperCase()
        }
      }

      return '...'
    }

    const onClickDeleteFile = (file: FileData) => {
      if (!props.disabled) {
        emit('on-delete-file', file.origin ?? file)
      }
    }

    const convertToFileData = (file: File): FileData => {
      return {
        id: uuid(),
        name: file.name,
        mimeType: file.type,
        size: file.size,
        updatedAt: new Date(file.lastModified),
        origin: file
      }
    }

    const originList = computed({
      get: () => props.value,
      set: val => {
        if (!props.disabled) {
          emit('input', val)
        }
      }
    })
    const list = computed(() => props.value?.map(i => (isFile(i) ? convertToFileData(i) : i)))

    return {
      list,
      originList,
      getFileMetadata,
      getFileType,
      onClickDeleteFile
    }
  }
})
