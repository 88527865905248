import { gql } from 'graphql-tag'
import { CommentFields, FileFields } from '~/shared/gql/fragments'
import { LessonMainFields } from '~/entities/lesson/gql/fragments'

export default gql`
  ${LessonMainFields('LearningClassLessonType')}
  ${CommentFields('CommentType')}
  ${FileFields('MediaType')}
  query getScheduledLesson($id: UUID) {
    learningClassLesson(id: $id) {
      ...LessonMainFields

      author {
        id
        profile {
          middleName
          name
          surName
        }
      }

      sections {
        id
        content
        sectionType
        documents(sort: { column: ORDER_COLUMN, order: ASC }) {
          ...FileFields
        }
        items {
          id
          sectionId
          itemType
          itemId
        }
      }

      comments(first: 9999) {
        data {
          ...CommentFields
        }
      }
    }
  }
`
