import { requiredRule } from '~/shared/utils/validation'
import { EducationProgramModel } from '~/entities/education-program/model'
import type { CreateSubjectInput } from '~/entities/subject/interface'

export default class CreateForm {
  title: string = ''
  description: string = ''
  educationPrograms: Array<EducationProgramModel> = []
  preview: Array<File> = []

  getInputData(): CreateSubjectInput {
    return {
      educationalProgram: this.educationPrograms.map((i) => i.id),
      title: this.title,
      description: this.description,
      preview: {
        action: 'CREATE',
        file: this.preview.length && this.preview[0] instanceof File ? this.preview[0] : null,
      },
    }
  }

  static rules() {
    return {
      title: requiredRule,
      educationPrograms: requiredRule,
      preview: requiredRule,
      description: [],
    }
  }
}
