
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import type { TableHeader, TableItem } from '@svoi-ui/interfaces/table'

export default defineComponent({
  props: {
    header: {
      type: Array as PropType<Array<TableHeader>>,
      required: true
    },
    items: {
      type: Array as PropType<Array<TableItem>>,
      required: true
    },
    stickyHeader: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const sortedHeader = computed(() => [...props.header].sort((a, b) => a.order - b.order))

    return { sortedHeader }
  }
})
