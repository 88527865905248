
import { defineComponent, PropType, ref, useContext, watchEffect } from '@nuxtjs/composition-api'
import { SvoiTabs } from '@svoi-ui/components/tabs'
import { SearchInput } from '~/shared/ui/base'
import type { FeedbackListFilters } from '~/entities/feedback/interface'
import { StatusModel } from '~/entities/workflow/model'
import { WorkflowSchemes } from '~/entities/workflow/enum'
import { ThemeModel } from '~/entities/feedback/model'

export default defineComponent({
  components: { SvoiTabs, SearchInput },
  props: {
    initialFilters: {
      type: Object as PropType<FeedbackListFilters>,
      default: () => {},
    },
  },
  emits: ['on-change'],
  setup(props, { emit }) {
    const ctx = useContext()

    const filters = ref<FeedbackListFilters>({})
    const statuses = ref<Array<StatusModel>>([])
    const themes = ref<Array<ThemeModel>>([])
    const activeStatus = ref<StatusModel | undefined>(undefined)
    const activeTheme = ref<ThemeModel | undefined>(undefined)

    const initFilters = () => {
      filters.value = {
        status: props.initialFilters?.status ?? undefined,
        classifiers: props.initialFilters?.classifiers ?? undefined,
        search: props.initialFilters?.search ?? '',
      }
    }

    const changeFilterStatus = (value: StatusModel) => {
      if (!isActiveStatus(value)) {
        filters.value.status = value.id ? [value.id] : undefined
        emit('on-change', filters.value)
      }
    }

    const changeFilterTheme = (value: ThemeModel) => {
      if (!isActiveTheme(value)) {
        filters.value.classifiers = value.id ? value.id : undefined
        emit('on-change', filters.value)
      }
    }

    const changeFilterSearch = () => {
      emit('on-change', filters.value)
    }

    const isActiveStatus = (statusValue: StatusModel): boolean => {
      if (Array.isArray(filters.value.status) && filters.value.status.length) {
        return filters.value.status.includes(statusValue.id)
      } else {
        return statusValue.id === ''
      }
    }

    const isActiveTheme = (themeValue: ThemeModel): boolean =>
      themeValue.id ? themeValue.id === filters.value.classifiers : filters.value.classifiers === undefined

    const getStatuses = () => {
      return ctx.$services.workflow.getStatuses(WorkflowSchemes.feedback).then((data) => {
        const allStatuses = new StatusModel({ id: '', name: 'Все' })

        statuses.value = [allStatuses, ...data]
        activeStatus.value = statuses.value.find((i) => isActiveStatus(i))
      })
    }

    const getFeedbackThemes = () => {
      return ctx.$services.reference.getFeedbackThemes().then((data) => {
        const allThemes = new ThemeModel({ id: '', value: 'Все' })
        themes.value = [allThemes, ...data]
        activeTheme.value = themes.value.find((i) => isActiveTheme(i))
      })
    }

    watchEffect(() => initFilters())

    getStatuses()
    getFeedbackThemes()

    return {
      statuses,
      themes,
      filters,
      activeStatus,
      activeTheme,
      changeFilterStatus,
      changeFilterSearch,
      changeFilterTheme,
    }
  },
})
