import { CreateForm } from '.'
import { LearningBlockModel } from '~/entities/learning-block/model'
import { requiredRule } from '~/shared/utils/validation'
import type { EditLearningBlockInput } from '~/entities/learning-block/interface'
import { fillOwnProperties } from '~/shared/utils/helpers'

export default class EditForm extends CreateForm {
  readonly id: string

  constructor(data: LearningBlockModel) {
    super()

    this.id = data.id
    fillOwnProperties(this, { ...data })
  }

  getInputData(): EditLearningBlockInput {
    return {
      ...super.getInputData(),
      id: this.id,
    }
  }

  static rules() {
    return {
      id: requiredRule,
      ...super.rules(),
    }
  }
}
