import { gql } from 'graphql-tag'
import { BlockMainFields } from '~/entities/learning-block/gql/fragments'
import type { BlockListType } from '~/entities/learning-block/interface'

export default (type: BlockListType) => {
  let data = 'createLearningKnowledgeBaseBlock'
  let input = 'CreateLearningKnowledgeBaseBlockInput'
  let on = 'LearningKnowledgeBaseBlockType'

  if (type === 'program') {
    data = 'createLearningProgramBlock'
    input = 'CreateLearningProgramBlockInput'
    on = 'LearningProgramBlockType'
  }

  return gql`
    ${BlockMainFields(on)}
    mutation createLearningBlock($input: ${input}!) {
      ${data}(input: $input) {
        ...BlockMainFields
      }
    }
  `
}
