import { gql } from 'graphql-tag'
import { PaginationFields } from '~/shared/gql/fragments'
import { FeedbackMainFields } from '~/entities/feedback/gql/fragments'

export default gql`
  ${PaginationFields('FeedbackMessageTypePaginator')}
  ${FeedbackMainFields}
  query getFeedbackList(
    $first: Int = 9999
    $page: Int
    $filter: FeedbackMessagesFilterInput
    $sort: [QueryFeedbackMessagesSortOrderByClause!]
  ) {
    feedbackMessages(first: $first, page: $page, filter: $filter, sort: $sort) {
      data {
        ...FeedbackMainFields

        lastStatusTransitionHistoryRecord {
          createdAt
        }
      }

      ...PaginationFields
    }
  }
`
