
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import type { BreadcrumbItem } from '@svoi-ui/interfaces/breadcrumbs'
import { LinkTypes, LinkTypesEnum } from '@svoi-ui/interfaces/link-type'

export default defineComponent({
  name: 'SvoiBreadcrumbs',
  components: { SvoiIcon },
  props: {
    items: {
      type: Array as PropType<Array<BreadcrumbItem>>,
      default: [] as Array<BreadcrumbItem>
    },
    componentType: {
      type: String as PropType<LinkTypes>,
      default: LinkTypesEnum.nuxtLink,
      validator: (value: unknown) => {
        return (
          typeof value === 'string' && [LinkTypesEnum.a.toString(), LinkTypesEnum.nuxtLink.toString()].includes(value)
        )
      }
    }
  }
})
