
import { computed, defineComponent, reactive, useContext, useRouter, ref } from '@nuxtjs/composition-api'
import { SvoiSidebarMenu, SvoiSidebar } from '@svoi-ui/components/sidebar'
import type { NavBarBothMenuItem, NavBarMenu } from '~/shared/interface'
import { getNavBarMenus } from '~/shared/utils/menu'
import { SidebarAvatar, SidebarMenuItem } from '~/widgets/sidebar'
import { useDevices, useGlobalState } from '~/composables'
import { Sidebar } from '~/shared/class'
import { LOGIN_ROUTE } from '~/shared/const'

export default defineComponent({
  name: 'UserNavBar',
  components: { SidebarAvatar, SidebarMenuItem, SvoiSidebarMenu, SvoiSidebar },
  setup() {
    const context = useContext()
    const router = useRouter()
    const { isDesktop } = useDevices()
    const { statisticState } = useGlobalState()

    const sidebar = reactive(Sidebar.getInstance())

    const statistic = ref(statisticState)
    const userFullName = computed(() => context.$tstore.user.userFullName)

    const navBarMenus: NavBarMenu = context.$tstore.user.authorizedUser
      ? getNavBarMenus(context.$tstore.user.authorizedUser)
      : { main: [], both: [] }

    const isOpenSidebar = computed(() => sidebar.isOpen)

    const nameClasses = computed(() => (isOpenSidebar.value ? 'name--opened' : 'name--closed'))

    const onClickBothMenuItem = (item: NavBarBothMenuItem) => {
      switch (item.action) {
        case 'logout':
          onLogout()
          break
      }
    }

    const onLogout = () => {
      context.$services.apollo.onLogout()
      context.$tstore.user.clearUser()
      router.push(LOGIN_ROUTE)
    }

    const setIsOpenSidebar = (value: boolean) => {
      if (isDesktop.value) {
        sidebar.setIsOpen(value)
      }
    }

    return {
      isDesktop,
      nameClasses,
      isOpenSidebar,
      navBarMenus,
      userFullName,
      statistic,
      onClickBothMenuItem,
      setIsOpenSidebar,
    }
  },
})
