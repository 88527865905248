
import { computed, defineComponent, PropType, ref, watch } from '@nuxtjs/composition-api'
import { FileData, Mode, ModeEnum, Type, TypeEnum, TypeList, TypeListEnum } from '@svoi-ui/interfaces/file-picker'
import type { IconType } from '@svoi-ui/interfaces/icon'
import { SvoiFilesList, SvoiImagesList } from '@svoi-ui/components/list'
import { SvoiIcon, SvoiTooltippedIcon } from '@svoi-ui/components/icon'
import { PositionsEnum as TooltipPositionsEnum } from '@svoi-ui/interfaces/tooltip'
import { BaseFilePickerComponent } from './BaseFilePicker'
import { SvoiFilePicker, SvoiFilePickerButton } from '.'

export default defineComponent({
  name: 'SvoiFilePickerComplex',
  components: { SvoiFilePicker, SvoiFilePickerButton, SvoiFilesList, SvoiImagesList, SvoiTooltippedIcon, SvoiIcon },
  props: {
    ...BaseFilePickerComponent.props,
    value: {
      type: Array as PropType<Array<File | FileData>>,
      default: [] as Array<File | FileData>
    },
    mode: {
      type: String as PropType<Mode>,
      default: ModeEnum.append,
      validator: (value: string) => {
        return [ModeEnum.append.toString(), ModeEnum.replace.toString()].includes(value)
      }
    },
    type: {
      type: String as PropType<Type>,
      default: TypeEnum.dragArea,
      validator: (value: string) => {
        return [TypeEnum.button.toString(), TypeEnum.dragArea.toString()].includes(value)
      }
    },
    buttonText: {
      type: String,
      default: 'Загрузить файлы'
    },
    typeList: {
      type: String as PropType<TypeList>,
      default: TypeListEnum.default,
      validator: (value: string) => {
        return [TypeListEnum.default.toString(), TypeListEnum.images.toString()].includes(value)
      }
    },
    header: {
      type: String,
      default: ''
    },
    tooltip: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  emits: ['input'],
  setup(props, { emit }) {
    const uploadFiles = ref<Array<File>>([])
    const disableInput = computed(() => {
      if (props.disabled) {
        return true
      }

      if (props.mode === ModeEnum.replace) {
        return false
      }

      return props.multiple ? props.value?.length >= props.maxCount : props.value?.length > 0
    })
    const component = computed(() => {
      switch (props.type) {
        case TypeEnum.button:
          return SvoiFilePickerButton
        case TypeEnum.dragArea:
        default:
          return SvoiFilePicker
      }
    })
    const componentList = computed(() => {
      switch (props.typeList) {
        case TypeListEnum.images:
          return SvoiImagesList
        case TypeListEnum.default:
        default:
          return SvoiFilesList
      }
    })
    const iconTooltip = computed<IconType>(() => ({
      icon: 'essential/info-circle',
      tooltip: props.tooltip,
      tooltipPosition: TooltipPositionsEnum.bottomRight
    }))

    const onClickDeleteFile = (file: File | FileData) => {
      emit(
        'input',
        props.value?.filter(i => i !== file)
      )
    }

    const onChangeSortingList = (list: Array<File | FileData>) => {
      emit('input', list)
    }

    const mergeFiles = () => {
      const res = [...props.value]
      let counter = res.length
      uploadFiles.value.forEach(f => {
        if (counter >= props.maxCount) {
          return
        }
        res.push(f)
        counter++
      })
      return res
    }

    watch(
      () => uploadFiles.value,
      () => {
        if (uploadFiles.value.length > 0) {
          if (props.mode === ModeEnum.replace) {
            emit('input', uploadFiles.value)
            return
          }

          const res = mergeFiles()
          uploadFiles.value = []
          emit('input', res)
        }
      }
    )

    return {
      uploadFiles,
      disableInput,
      component,
      componentList,
      iconTooltip,
      onClickDeleteFile,
      onChangeSortingList
    }
  }
})
