import { gql } from 'graphql-tag'

export default gql`
  fragment FeedbackMainFields on FeedbackMessageType {
    id
    number
    name
    email
    createdAt

    status {
      id
      name
      alias
    }
    assignee {
      profile {
        name
        surName
        middleName
      }
      email
    }
    user {
      profile {
        name
        surName
        middleName
      }
      email
    }
  }
`
