export enum SizesEnum {
  xs = 'xs',
  s = 's',
  m = 'm',
  l = 'l',
  xl = 'xl',
  xxl = 'xxl'
}

export type Sizes = SizesEnum.xs | SizesEnum.s | SizesEnum.m | SizesEnum.l | SizesEnum.xl | SizesEnum.xxl
