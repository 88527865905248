const layout = {
  showInformation: 'layout.show-information',
  showConfirm: 'layout.show-confirm',
  showFeedbackModal: 'layout.feedback-modal',
}

const user = {
  updatedClasses: 'user.updated-classes',
}

export const events = { layout, user }
