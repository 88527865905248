import { TestQuestionModel } from './'
import { BaseModel } from '~/shared/model'
import { fillOwnProperties, getUIUniqId } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'
import type { UIUniqId } from '~/shared/types'

export default class Test extends BaseModel {
  id: string | UIUniqId = getUIUniqId()
  questions: Map<string | UIUniqId, TestQuestionModel> = new Map()

  constructor(data: unknown) {
    super()

    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['questions'])

      if (Array.isArray(data.questions)) {
        for (const question of data.questions) {
          const questionModel = new TestQuestionModel(question)

          this.questions.set(questionModel.id, questionModel)
        }
      }
    }
  }
}
