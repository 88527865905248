
import { defineComponent, computed } from '@nuxtjs/composition-api'
import { SvoiInput } from '@svoi-ui/components/input'

export default defineComponent({
  name: 'UrlInput',
  components: { SvoiInput },
  extends: SvoiInput,
  emits: ['input'],
  setup(props, { emit }) {
    const computedValue = computed({
      get: () => props.value.replaceAll(/^http(s):\/\//g, ''),
      set: (val) => emit('input', `https://${val}`),
    })

    return { computedValue }
  },
})
