
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { TabItems, TabItem, TabItemKey } from '@svoi-ui/interfaces/tabs'
import { isString, isUnknownObject } from '@svoi-ui/shared/utils/guards'

export default defineComponent({
  name: 'SvoiTabs',
  props: {
    value: {
      type: [String, Object] as PropType<TabItem | string>,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    items: {
      type: Array as PropType<TabItems>,
      default: () => []
    },
    // Label from option Object, that will be visible in the dropdown.
    itemLabel: {
      type: String,
      default: 'title'
    },
    // Key to compare objects
    trackBy: {
      type: [String, Number] as PropType<TabItemKey>,
      default: 'id'
    }
  },
  emits: ['input'],
  setup(props) {
    const isSimple = computed(() => isString(props.items[0]))

    const isActiveTab = (tab: TabItem) => {
      return isSimple.value
        ? tab === props.value
        : isUnknownObject(props.value) && tab[props.trackBy] === props.value[props.trackBy]
    }

    return { isSimple, isActiveTab }
  }
})
