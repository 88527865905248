import { requiredRule } from '~/shared/utils/validation'
import { SubjectModel } from '~/entities/subject/model'
import { EducationProgramModel } from '~/entities/education-program/model'
import type { CreateLearningBlockInput } from '~/entities/learning-block/interface'

export default class CreateForm {
  title: string = ''
  description: string = ''
  subject: SubjectModel | null = null
  educationPrograms: Array<EducationProgramModel> = []

  getInputData(): CreateLearningBlockInput {
    return {
      educationalProgram: this.educationPrograms.map((i) => i.id),
      subjectId: this.subject?.id ?? '',
      title: this.title,
      description: this.description,
    }
  }

  static rules() {
    return {
      title: requiredRule,
      description: [],
      subject: requiredRule,
      educationPrograms: requiredRule,
    }
  }
}
