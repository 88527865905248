import { gql } from 'graphql-tag'
import { BlockFields } from '~/entities/learning-block/gql/fragments'
import { PaginationFields } from '~/shared/gql/fragments'
import type { BlockListType } from '~/entities/learning-block/interface'

export default (type: BlockListType) => {
  let on, filter, data, sort

  switch (type) {
    case 'base-any': {
      on = 'LearningKnowledgeBaseBlockType'
      filter = 'LearningKnowledgeBaseBlocksFilterInput'
      data = 'learningKnowledgeBaseBlocks'
      sort = 'QueryLearningKnowledgeBaseBlocksSortOrderByClause'
      break
    }
    case 'program': {
      on = 'LearningProgramBlockType'
      filter = 'LearningProgramBlocksFilterInput'
      data = 'learningProgramBlocks'
      sort = 'QueryLearningProgramBlocksSortOrderByClause'
      break
    }
    default: {
      on = 'LearningKnowledgeBaseActiveBlockType'
      filter = 'LearningKnowledgeBaseActiveBlocksFilterInput'
      data = 'learningKnowledgeBaseActiveBlocks'
      sort = 'QueryLearningKnowledgeBaseActiveBlocksSortOrderByClause'
    }
  }

  return gql`
    ${BlockFields(on)}
    ${PaginationFields(`${on}Paginator`)}
    query getBlocks($first: Int = 9999, $page: Int, $filter: ${filter}, $sort: [${sort}!], $filterLessons: LessonsFilterInput) {
      ${data}(first: $first, page: $page, filter: $filter, sort: $sort) {
        data {
          ...BlockFields

          lessons(filter: $filterLessons) {
            id
            order
            title
            description
            number
            status
            author {
              profile {
                name
                middleName
                surName
              }
            }
          }
        }
        ...PaginationFields
      }
    }
  `
}
