import { CreateForm } from '.'
import { requiredRule } from '~/shared/utils/validation'
import { fillOwnProperties } from '~/shared/utils/helpers'
import type { EditClassInput } from '~/entities/class/interface'
import { ClassModel, PostfixClassModel } from '~/entities/class/model'

export default class EditForm extends CreateForm {
  id: string = ''

  readonly _class: ClassModel

  constructor(data: ClassModel) {
    super()

    this._class = data

    fillOwnProperties(this, { ...data })

    if (data.postfix) {
      this.litter = new PostfixClassModel(data)
    }
  }

  getInputData(): EditClassInput {
    return Object.assign(super.getInputData(), { id: this.id })
  }

  static rules() {
    return {
      id: requiredRule,
      ...CreateForm.rules(),
    }
  }
}
