export function* loopGenerator<T>(from: Array<T>): Generator<T> {
  let current = 0
  const max = from.length - 1
  while (true) {
    yield from[current++]
    current = current > max ? 0 : current
  }
}

export function* backgroundColorGenerator(): Generator<string> {
  const colors = ['#dbfde2', '#ffecec', '#ebf1fa', '#f4fcd7', '#fdf1e5', '#dcdfff', '#fff5db', '#e9feff']
  yield* loopGenerator(colors)
}
