import { requiredContentEditor, requiredRule, maxLengthListRule, websiteRule, helpers } from '~/shared/utils/validation'
import { CreateArticleInput, ArticleFileInput } from '~/entities/article/interface'
import type { JSONContent } from '~/interfaces/editor'
import type { MediaModel } from '~/shared/model'
import type { CategoryModel } from '~/entities/category/model'
import type { TagModel } from '~/entities/tag/model'

export default class CreateForm {
  title: string = ''
  description: string = ''
  content: JSONContent | null = null
  preview: Array<File | MediaModel> = []
  mainPreview: Array<File | MediaModel> = []
  categories: Array<CategoryModel> = []
  tags: Array<TagModel> = []
  lessons: Array<{ link: string }> = new Array(3).fill(undefined)
  articles: Array<{ link: string }> = new Array(3).fill(undefined)

  constructor() {
    this.fillLinks()
  }

  getInputData(): CreateArticleInput {
    return Object.assign(this.getMainInputData(), {
      preview: this.toInputFile('preview'),
      mainPreview: this.toInputFile('mainPreview'),
    })
  }

  protected fillLinks() {
    this.lessons.forEach((i, idx) => {
      if (i === undefined) {
        this.lessons[idx] = { link: '' }
      }
    })

    this.articles.forEach((i, idx) => {
      if (i === undefined) {
        this.articles[idx] = { link: '' }
      }
    })
  }

  protected getMainInputData(): CreateArticleInput {
    return {
      title: this.title,
      description: this.description,
      content: this.content ? JSON.stringify(this.content) : '',
      categories: this.categories.map((c) => c.id),
      tags: this.tags.map((t) => t.id),
      lessons: this.lessons.filter((l) => !!l.link).map((l) => l.link),
      publications: this.articles.filter((a) => !!a.link).map((a) => a.link),
    }
  }

  protected toInputFile(type: 'preview' | 'mainPreview'): ArticleFileInput | undefined {
    const firstFile = this[type][0]
    if (firstFile instanceof File) {
      return { action: 'CREATE', file: firstFile }
    }

    return undefined
  }

  static rules() {
    return {
      title: requiredRule,
      description: requiredRule,
      content: requiredContentEditor,
      categories: requiredRule,
      preview: requiredRule,
      mainPreview: requiredRule,
      tags: maxLengthListRule(2),
      lessons: {
        $each: helpers.forEach({
          link: websiteRule,
        }),
      },
      articles: {
        $each: helpers.forEach({
          link: websiteRule,
        }),
      },
    }
  }
}
