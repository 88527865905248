
import { defineComponent } from '@nuxtjs/composition-api'
import { headerMenu } from '~/shared/const'

export default defineComponent({
  name: 'HeaderMenu',
  setup() {
    return { headerMenu }
  },
})
