import { gql } from 'graphql-tag'
import { LessonMainFields } from '~/entities/lesson/gql/fragments'
import { BlockFields } from '~/entities/learning-block/gql/fragments'
import type { LessonListType } from '~/entities/lesson/interface'
import { FileFields } from '~/shared/gql/fragments'

export default (type: LessonListType) => {
  let data, on, onBlock

  switch (type) {
    case 'program': {
      data = 'learningProgramLesson'
      on = 'LearningProgramLessonType'
      onBlock = 'LearningProgramBlockType'
      break
    }
    case 'base-active': {
      data = 'learningKnowledgeBaseActiveLesson'
      on = 'LearningKnowledgeBaseActiveLessonType'
      onBlock = 'LearningKnowledgeBaseActiveBlockType'
      break
    }
    default: {
      data = 'learningKnowledgeBaseLesson'
      on = 'LearningKnowledgeBaseLessonType'
      onBlock = 'LearningKnowledgeBaseBlockType'
    }
  }

  return gql`
    ${BlockFields(onBlock)}
    ${LessonMainFields(on)}
    ${FileFields('MediaType')}
    query getLesson($id: UUID) {
      ${data}(id: $id) {
        ...LessonMainFields

        block {
          ...BlockFields
        }

        sections {
          id
          content
          sectionType
          documents(sort: { column: ORDER_COLUMN, order: ASC }) {
            ...FileFields
            order
            updatedAt
          }
          items {
            id
            sectionId
            itemType
            itemId
          }
        }

        preview {
          ...FileFields
        }

        author {
          id
          profile {
            middleName
            name
            surName
          }
        }
      }
    }
  `
}
