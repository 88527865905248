import { useMeta, UnwrapRef } from '@nuxtjs/composition-api'
import type { OpenGraphTags, OpenGraphTagsKeys } from '~/interfaces/meta'

export const useMetaData = () => {
  const metadata = useMeta()

  // https://ogp.me/ полное описание разметки OpenGraph
  const setOpenGraphTags = (data: OpenGraphTags) => {
    const tags: UnwrapRef<ReturnType<typeof useMeta>['meta']> = []

    const makeTag = (prop: OpenGraphTagsKeys, content: string) => ({
      hid: `og-${prop}`,
      property: `og:${prop}`,
      content,
    })

    Object.keys(data).forEach((key) => {
      const value = data[key as OpenGraphTagsKeys]
      if (value) {
        tags.push(makeTag(key as OpenGraphTagsKeys, value))
      }
    })

    if (!data.site_name) {
      tags.push(makeTag('site_name', 'Агроклассы'))
    }

    metadata.meta.value = tags
  }

  return { setOpenGraphTags }
}
