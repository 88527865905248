import { BaseModel } from '.'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'

export default class Counter extends BaseModel {
  id: string = ''
  type: string = ''
  counter: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)
    }
  }

  static checkView(data: Counter): boolean {
    return data.type === 'view'
  }
}
