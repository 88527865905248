import type { DadataService } from '.'
import type { SuggestionCompanyInput } from '~/entities/dadata/interface'
import { toModels } from '~/shared/api'
import { CompanyModel } from '~/entities/dadata/model'

export class CompanyService {
  protected url = 'suggest/party'
  protected rootService: DadataService

  constructor(root: DadataService) {
    this.rootService = root
  }

  suggestions(params: SuggestionCompanyInput) {
    const url = `${this.rootService.baseUrl}${this.url}`
    return this.rootService.getSuggestions(url, params).then(toModels(CompanyModel))
  }
}
