export enum ThemesEnum {
  qiwi = 'qiwi',
  qiwiLight = 'qiwi-light',
  banana = 'banana',
  chilli = 'chilli',
  potato = 'potato',
  figs = 'figs'
}

export type Themes =
  | ThemesEnum.qiwi
  | ThemesEnum.qiwiLight
  | ThemesEnum.banana
  | ThemesEnum.chilli
  | ThemesEnum.potato
  | ThemesEnum.figs

export enum SizesEnum {
  l = 'l',
  m = 'm'
}

export type Sizes = SizesEnum.l | SizesEnum.m
