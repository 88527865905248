import { BaseModel } from '~/shared/model'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'
import { FavoriteEntityEnum, isEntityKey } from '~/entities/favorites/enums'
import type { Nullable } from '~/shared/interface'
import { ArticleModel } from '~/entities/article/model'

export default class Favorite extends BaseModel {
  id: string = ''
  collection: Nullable<keyof typeof FavoriteEntityEnum> = null
  favoritable?: ArticleModel

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['collection'])

      if (isEntityKey(data.collection)) {
        this.collection = data.collection
      }

      if (isUnknownObject(data.favoritable)) {
        switch (this.collection) {
          case 'article': {
            this.favoritable = new ArticleModel(data.favoritable)
            break
          }
        }
      }
    }
  }
}
