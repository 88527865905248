import { gql } from 'graphql-tag'
import { ArticleMainFields } from '.'
import { CommentFields, FileFields, PaginationFields } from '~/shared/gql/fragments'
import { LessonMainFields } from '~/entities/lesson/gql/fragments'

export default gql`
  ${PaginationFields('CommentTypePaginator')}
  ${ArticleMainFields}
  ${FileFields('MediaType')}
  ${LessonMainFields('LearningKnowledgeBaseActiveLessonType')}
  ${FileFields('MediaType')}
  ${CommentFields('CommentType')}
  fragment ArticleFull on PublicationType {
    ...ArticleMainFields
    content
    rating
    position
    isFavorited(collection: "article")

    preview {
      ...FileFields
    }
    mainPreview {
      ...FileFields
    }
    tags {
      id
      name
    }
    categories {
      id
      title
      slug
    }
    lessons {
      id
    }
    author {
      id
      profile {
        name
        middleName
        surName
        photo {
          path
        }
      }
    }
    publications {
      ...ArticleMainFields

      comments(first: 9999) {
        ...PaginationFields
      }
    }
    lessons {
      ...LessonMainFields

      preview {
        ...FileFields
      }

      author {
        profile {
          name
          middleName
          surName
        }
      }
    }

    comments(first: 9999) {
      data {
        ...CommentFields
      }

      ...PaginationFields
    }

    counters {
      id
      type
      counter
    }
  }
`
