import Vue from 'vue'
import { Plugin } from '@nuxt/types'

const clickOutside: Plugin = () => {
  Vue.directive('click-outside', {
    bind: (el, bindings) => {
      el.addEventListener('click', (e) => e.stopPropagation())
      document.body.addEventListener('click', bindings.value)
    },
    unbind: (_, bindings) => {
      document.body.removeEventListener('click', bindings.value)
    },
  })
}

export default clickOutside
