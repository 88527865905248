import { gql } from 'graphql-tag'
import { ProfileFields } from '~/entities/user/gql/fragments'

export default gql`
  ${ProfileFields('User')}
  mutation editFullPersonalData($input: EditFullPersonalDataInput!) {
    editFullPersonalData(input: $input) {
      ...ProfileFields
    }
  }
`
