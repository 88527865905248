import { ErrorObject } from '@vuelidate/core'
import VueScrollTo from 'vue-scrollto'
import { UnknownObject } from '~/shared/interface'
import { getUIUniqId, getValueByChain } from '~/shared/utils/helpers'
import { isString, isUIUniqid, isUnknownObject } from '~/shared/utils/guards'
import { NestedRulesIds } from '~/interfaces/error-scoll-form'

export const getErrorScrollElement = (id: string) => {
  const dataAttributeName = 'data-error-scroll'
  const element = `[${dataAttributeName}=${id}]`

  return { dataAttributeName, element }
}

export const useErrorScrollForm = <T extends UnknownObject>(targetRules: T) => {
  const getKey = (property: string | number): string => {
    return `${property}_${getUIUniqId()}`
  }

  const iterableTarget = (
    target: UnknownObject,
    callback: (node: unknown, key: string, currentNesting: UnknownObject) => void
  ): UnknownObject => {
    const currentNesting = {} as UnknownObject

    for (const key in target) {
      const node = target[key]

      callback(node, key, currentNesting)
    }

    return currentNesting
  }

  const createRule = (node: unknown, key: string, currentNesting: UnknownObject) => {
    if (Array.isArray(node)) {
      currentNesting[key] = []
    }

    if (isUnknownObject(node)) {
      if (Object.hasOwn(node, '$validator')) {
        const newKey = getKey(key)

        currentNesting[newKey] = { ...node }
      } else {
        currentNesting[key] = iterableTarget(node, createRule)
      }
    }
  }

  const createIdsRule = (node: unknown, key: string, currentNesting: UnknownObject) => {
    const fieldId = getKey(key)

    if (Array.isArray(node)) {
      currentNesting[key] = { id: fieldId, rulesIds: [] }
    }

    if (isUnknownObject(node)) {
      const keys = Object.keys(node)

      if (keys.every(isUIUniqid)) {
        currentNesting[key] = { id: fieldId, rulesIds: keys }
      } else {
        currentNesting[key] = iterableTarget(node, createIdsRule)
      }
    }
  }

  const scrollToError = (error: ErrorObject): boolean => {
    const path = error.$propertyPath.split('.')

    if (path.length < 1) {
      return false
    }

    const idRule = error.$validator
    const field = getValueByChain(rulesIds, error.$propertyPath)

    if (
      isUnknownObject(field) &&
      isString(field.id) &&
      Array.isArray(field.rulesIds) &&
      field.rulesIds.includes(idRule)
    ) {
      const { element } = getErrorScrollElement(field.id)

      VueScrollTo.scrollTo(element, { offset: -window.innerHeight / 2 })

      return true
    }

    return false
  }

  const rules = iterableTarget(targetRules, createRule) as T
  const rulesIds = iterableTarget(rules, createIdsRule) as NestedRulesIds<T>

  return { rules, rulesIds, scrollToError, getErrorScrollElement }
}
