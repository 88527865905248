import { render, staticRenderFns } from "./SidebarAvatar.vue?vue&type=template&id=91510452&scoped=true&"
import script from "./SidebarAvatar.vue?vue&type=script&lang=ts&"
export * from "./SidebarAvatar.vue?vue&type=script&lang=ts&"
import style0 from "./SidebarAvatar.vue?vue&type=style&index=0&id=91510452&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91510452",
  null
  
)

export default component.exports