import type { Context } from '@nuxt/types'
import type { ApolloHelpers } from '@nuxtjs/apollo'
import type { ApolloClient } from 'apollo-client'
import { FileService, ReferenceService } from '.'
import { ApolloService } from '~/shared/api'
import { ApplicationsService, UserService } from '~/entities/user/api'
import { SchoolsService } from '~/entities/school/api'
import { LessonService } from '~/entities/lesson/api'
import { SubjectService } from '~/entities/subject/api'
import { BlockService } from '~/entities/learning-block/api'
import type { Services } from '~/shared/interface'
import { ClassService } from '~/entities/class/api'
import { ArticleService } from '~/entities/article/api'
import { CategoryService } from '~/entities/category/api'
import { TagService } from '~/entities/tag/api'
import { RatingService } from '~/entities/rating/api'
import { WorkflowService } from '~/entities/workflow/api'
import { FeedbackService } from '~/entities/feedback/api'
import { DadataService } from '~/entities/dadata/api'
import { FavoritesService } from '~/entities/favorites/api'
import { CommentsService } from '~/entities/comments/api'

export const initializeServices = (context: Context): Services => {
  const apolloClient = context.app.apolloProvider.defaultClient as ApolloClient<any>
  const apolloHelpers = context.app.$apolloHelpers as ApolloHelpers

  const apolloService = new ApolloService(apolloClient, apolloHelpers)

  return {
    apollo: apolloService,
    user: new UserService(apolloService),
    applications: new ApplicationsService(apolloService),
    schools: new SchoolsService(apolloService),
    lessons: new LessonService(apolloService),
    subjects: new SubjectService(apolloService),
    learningBlocks: new BlockService(apolloService),
    reference: new ReferenceService(apolloService),
    file: new FileService(apolloService),
    class: new ClassService(apolloService),
    article: new ArticleService(apolloService),
    category: new CategoryService(apolloService),
    tag: new TagService(apolloService),
    rating: new RatingService(apolloService),
    workflow: new WorkflowService(apolloService),
    feedback: new FeedbackService(apolloService),
    favorites: new FavoritesService(apolloService),
    comments: new CommentsService(apolloService),
    dadata: new DadataService(context.$config.dadata),
  }
}
