import { gql } from 'graphql-tag'
import { QuizMainFields } from '~/entities/lesson/gql/fragments'

export default gql`
  ${QuizMainFields('QuizFullType', true)}
  mutation calculateQuizResult($input: CalculateQuizResultInput!) {
    calculateQuizResult(input: $input) {
      quiz {
        ...QuizMainFields
      }
    }
  }
`
