
import { defineComponent, computed, useRouter, PropType } from '@nuxtjs/composition-api'
import { SvoiButton } from '@svoi-ui/components/button'

export default defineComponent({
  name: 'BaseBanner',
  components: { SvoiButton },
  props: {
    theme: {
      type: String as PropType<'blue' | 'green'>,
      default: 'blue',
      validator: (value: unknown) => {
        return typeof value === 'string' && ['blue', 'green'].includes(value)
      },
    },
    name: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    buttonName: {
      type: String,
      default: '',
    },
    url: {
      type: String,
      default: '',
    },
    image: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const $router = useRouter()

    const classes = computed(() => ({
      [`theme-${props.theme}`]: true,
    }))

    const onClickButton = () => {
      $router.push(props.url)
    }

    return { classes, onClickButton }
  },
})
