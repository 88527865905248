import { BaseModel } from '~/shared/model'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'
import type { UnknownObject } from '~/shared/interface'

/**
 * Полное описание полей для модели содержится на странице
 * https://dadata.ru/api/suggest/address/
 * При использовании полей из объекта data оформляем их через геттер с необходимой типизацией
 * get fieldName() {
 *  return this._rawData[fieldName]
 * }
 */

export default class Address extends BaseModel {
  value: string = ''
  unrestrictedValue: string = ''
  private readonly _rawData: UnknownObject = {}

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data)

      if (isUnknownObject(data.data)) {
        this._rawData = data.data
      }
    }
  }

  get locality(): string {
    return String(this._rawData?.city_with_type || this._rawData?.settlement_with_type || '')
  }
}
