import { BaseModel, RegionModel, CityModel, SettlementModel, GeoModel } from '.'
import { isUnknownObject } from '~/shared/utils/guards'
import { fillOwnProperties } from '~/shared/utils/helpers'
import type { Nullable } from '~/shared/interface'

export default class Address extends BaseModel {
  id: string = ''
  value: string = ''
  region: Nullable<RegionModel> = null
  city: Nullable<CityModel> = null
  settlement: Nullable<SettlementModel> = null
  geo: Nullable<GeoModel> = null

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['region', 'city', 'geo', 'settlement'])

      if (isUnknownObject(data.region)) {
        this.region = new RegionModel(data.region)
      }

      if (isUnknownObject(data.city)) {
        this.city = new CityModel(data.city)
      }

      if (isUnknownObject(data.settlement)) {
        this.settlement = new SettlementModel(data.settlement)
      }

      if (isUnknownObject(data.geo)) {
        this.geo = new GeoModel(data.geo)
      }
    }
  }
}
