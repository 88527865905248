import type { Component } from '@nuxtjs/composition-api'

export enum TypesEnum {
  info = 'info',
  warning = 'warning',
  error = 'error',
  infoBlue = 'info-blue'
}

export type Types = TypesEnum.info | TypesEnum.error | TypesEnum.infoBlue | TypesEnum.warning

export interface Button {
  name: string
  type?: 'main' | 'outline'
  callback: (...args: any[]) => void
}

export interface Notification {
  id: string
  title: string
  text?: string
  componentContent?: Component
  type?: Types
  collapse?: boolean
  timeout?: number
  buttons?: Array<Button>
}

export type NotificationItem = Required<Omit<Notification, 'timeout' | 'componentContent'>>

export enum PositionsEnum {
  leftTop = 'left-top',
  leftBottom = 'left-bottom',
  rightTop = 'right-top',
  rightBottom = 'right-bottom'
}

export type Positions =
  | PositionsEnum.leftTop
  | PositionsEnum.leftBottom
  | PositionsEnum.rightTop
  | PositionsEnum.rightBottom
