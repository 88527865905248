// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { Types, TypesEnum, Notification as AbstractNotification } from '@svoi-ui/interfaces/notification'
import { Notification } from '~/entities/notification/class'

function createNotification(params: Omit<AbstractNotification, 'id'> | string, type: Types): Notification {
  if (typeof params === 'string') {
    let title: string
    switch (type) {
      case TypesEnum.error: {
        title = 'Ошибка'
        break
      }
      case TypesEnum.warning: {
        title = 'Предупреждение'
        break
      }
      default: {
        title = 'Информация'
      }
    }
    return new Notification({ title, text: params, type })
  } else {
    return new Notification({ ...params, type })
  }
}

@Module({ namespaced: true, stateFactory: true, name: 'notifications' })
export default class extends VuexModule {
  private _notifications: Array<AbstractNotification> = []

  @Mutation
  private mutationSetNotifications(notifications: Array<AbstractNotification>): void {
    this._notifications = notifications
  }

  @Mutation
  private mutationAddNotification(notification: AbstractNotification): void {
    this._notifications.push(notification)
  }

  @Action
  public setNotifications(notifications: Array<AbstractNotification>): void {
    this.context.commit('mutationSetNotifications', notifications)
  }

  @Action
  public pushNotification(params: AbstractNotification): void {
    this.context.commit('mutationAddNotification', params)
  }

  @Action
  public pushInfo(params: Omit<AbstractNotification, 'id'> | string): void {
    this.pushNotification(createNotification(params, TypesEnum.info))
  }

  @Action
  public pushInfoBlue(params: Omit<AbstractNotification, 'id'> | string): void {
    this.pushNotification(createNotification(params, TypesEnum.infoBlue))
  }

  @Action
  public pushWarning(params: Omit<AbstractNotification, 'id'> | string): void {
    this.pushNotification(createNotification(params, TypesEnum.warning))
  }

  @Action
  public pushError(params: Omit<AbstractNotification, 'id'> | string): void {
    this.pushNotification(createNotification(params, TypesEnum.error))
  }

  get notifications(): Array<AbstractNotification> {
    return this._notifications
  }
}
