
import { computed, defineComponent, PropType } from '@nuxtjs/composition-api'
import { Positions, PositionsEnum, Themes, ThemesEnum } from '@svoi-ui/interfaces/tooltip'

export default defineComponent({
  name: 'SvoiTooltip',
  props: {
    text: {
      type: String,
      default: ''
    },
    theme: {
      type: String as PropType<Themes>,
      default: ThemesEnum.dark,
      validator: (value: string) => {
        return [ThemesEnum.dark.toString(), ThemesEnum.white.toString()].includes(value)
      }
    },
    position: {
      type: String as PropType<Positions>,
      default: PositionsEnum.bottomLeft,
      validator: (value: string) => {
        return [
          PositionsEnum.bottomLeft.toString(),
          PositionsEnum.bottomRight.toString(),
          PositionsEnum.topLeft.toString(),
          PositionsEnum.topRight.toString(),
          PositionsEnum.leftTop.toString(),
          PositionsEnum.leftBottom.toString(),
          PositionsEnum.rightTop.toString(),
          PositionsEnum.rightBottom.toString()
        ].includes(value)
      }
    },
    delayOpening: {
      type: Number as PropType<number>,
      default: 0
    }
  },
  setup(props) {
    const classes = computed(() => ({
      [`-theme-${props.theme}`]: true,
      [`-position-${props.position}`]: true
    }))

    const bindVariables = computed(() => ({ '--tooltip-delay': `${props.delayOpening}ms` }))

    return { classes, bindVariables }
  }
})
