
import { defineComponent } from '@nuxtjs/composition-api'
import { Modal } from '.'

export default defineComponent({
  name: 'InformationModal',
  components: {
    Modal,
  },
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    title: {
      default: '',
      type: String,
    },
    description: {
      default: '',
      type: String,
    },
  },
})
