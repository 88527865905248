import { singleton } from '~/shared/utils/decorators' // eslint-disable-line @typescript-eslint/no-unused-vars
import type { StatisticCounters } from '~/shared/interface'
import { isStatisticCounters } from '~/shared/utils/guards'

@singleton
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class StatisticState {
  private _counters: { [key in StatisticCounters]?: number } = {
    newApplications: 0,
    favorites: 0,
  }

  static getInstance() {
    return new StatisticState()
  }

  get newApplications(): number {
    return this._counters.newApplications ?? 0
  }

  get favorites(): number {
    return this._counters.favorites ?? 0
  }

  initCounters(values: Record<string, number>): void {
    Object.keys(values).forEach((key) => {
      if (isStatisticCounters(key)) {
        this._counters[key] = values[key]
      }
    })
  }

  changeCounter(key: StatisticCounters, value: number): void {
    const current = this._counters[key] ?? 0
    this._counters[key] = current + value
  }

  increment(key: StatisticCounters): void {
    this.changeCounter(key, 1)
  }

  decrement(key: StatisticCounters): void {
    this.changeCounter(key, -1)
  }
}
