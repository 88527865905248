
import Vue from 'vue'
import { defineComponent, PropType } from '@nuxtjs/composition-api'
import { SvoiHint } from '@svoi-ui/components/hint'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { InputMask, mask } from '@svoi-ui/shared/directive'
import { BaseInputComponent } from './BaseInput'

Vue.directive('mask', mask)

export default defineComponent({
  name: 'SvoiMaskedInput',
  components: { SvoiHint, SvoiIcon },
  extends: BaseInputComponent,
  props: {
    ...BaseInputComponent.props,
    mask: {
      type: [String, Function, Array] as PropType<InputMask>,
      default: ''
    }
  }
})
