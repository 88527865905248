import { gql } from 'graphql-tag'

export default (on: string, isFull: boolean) =>
  gql`
  fragment QuizMainFields on ${on} {
    id
    questions {
      id
      title
      order
      questionType
          
      answers {
        id
        title
        order
        ${isFull ? 'points' : ''} 
      }
    }  
  }
`
