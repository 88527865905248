import { requiredContentEditor, requiredRule } from '~/shared/utils/validation'
import { CreateForm } from '~/entities/lesson/api/index'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { EditLessonInput, SectionDataInput } from '~/entities/lesson/interface'
import type { LessonModel } from '~/entities/lesson/model'
import type { MediaModel } from '~/shared/model'
import { LessonSectionTypesEnum } from '~/entities/lesson/enums'

export default class EditForm extends CreateForm {
  id: string = ''

  readonly lesson: LessonModel

  constructor(data: LessonModel) {
    super()

    this.id = data.id
    this.lesson = data

    data.sections.forEach((s) => {
      if (s.sectionType === LessonSectionTypesEnum.teacher || s.sectionType === LessonSectionTypesEnum.student) {
        this.sections[s.sectionType].documents = [...s.documents]
        this.sections[s.sectionType].content = s.content
      }
    })

    if (data.preview) {
      this.preview = [data.preview]
    }

    fillOwnProperties(this, { ...data }, ['preview', 'sections'])
  }

  getInputData(): EditLessonInput {
    const preview = this.toInputPreview()

    const data: EditLessonInput = {
      id: this.id,
      title: this.title,
      description: this.description,
      blockId: this.learningBlock?.id ?? '',
      sections: this.toInputSections(),
    }

    if (preview) {
      data.preview = preview
    }

    return data
  }

  toInputSections(): EditLessonInput['sections'] {
    const lessonSections = new Map(this.lesson.sections.map((i) => [i.sectionType, i.id]))

    const sections: Required<EditLessonInput['sections']> = {
      create: [],
      update: [],
    }

    Object.keys(this.sections).forEach((k) => {
      const key = k as LessonSectionTypesEnum
      const section = this.sections[key].content

      const data = {
        sectionType: key,
        content: section ? JSON.stringify(section) : '',
        documents: this.toInputDocuments(key),
        items: key === LessonSectionTypesEnum.quiz ? this.toInputQuizItemsSection() : {},
      }

      const id = lessonSections.get(k)
      if (id) {
        sections.update.push(Object.assign(data, { id }))
      } else {
        sections.create.push(data)
      }
    })

    return sections
  }

  toInputDocuments(section: LessonSectionTypesEnum): SectionDataInput['documents'] {
    const result: SectionDataInput['documents'] = []
    let copyInitialDocuments: Map<number, MediaModel> = new Map()

    const lessonSection = this.lesson.sections.find((i) => i.sectionType === section)
    if (lessonSection) {
      copyInitialDocuments = new Map(lessonSection.documents.map((file) => [file.id, file]))
    }

    for (const [order, document] of this.sections[section].documents.entries()) {
      if (document instanceof File) {
        result.push({ action: 'CREATE', file: document, order })

        continue
      }

      if (copyInitialDocuments.has(document.id)) {
        copyInitialDocuments.delete(document.id)

        if (document.order !== order) {
          result.push({ action: 'EDIT', id: Number(document.id), order })
        }
      }
    }

    for (const deletedDocuments of copyInitialDocuments.values()) {
      result.push({ action: 'DELETE', id: Number(deletedDocuments.id) })
    }

    return result
  }

  toInputPreview(): EditLessonInput['preview'] | undefined {
    if (this.preview.length) {
      const firstFile = this.preview[0]
      if (firstFile instanceof File) {
        return this.lesson.preview
          ? { action: 'REPLACE', file: firstFile, id: this.lesson.preview.id }
          : { action: 'CREATE', file: firstFile }
      }
    } else if (this.lesson.preview) {
      return { action: 'DELETE', id: this.lesson.preview.id }
    }

    return undefined
  }

  static rules() {
    return {
      id: requiredRule,
      title: requiredRule,
      subject: requiredRule,
      learningBlock: requiredRule,
      description: requiredRule,
      preview: [],
      sections: {
        [LessonSectionTypesEnum.student]: {
          content: requiredContentEditor,
          documents: [],
        },
        [LessonSectionTypesEnum.teacher]: {
          content: [],
          documents: [],
        },
        [LessonSectionTypesEnum.quiz]: [],
      },
    }
  }
}
