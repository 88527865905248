import { render, staticRenderFns } from "./SvoiLabel.vue?vue&type=template&id=d3c9efe8&scoped=true&"
import script from "./SvoiLabel.vue?vue&type=script&lang=ts&"
export * from "./SvoiLabel.vue?vue&type=script&lang=ts&"
import style0 from "./SvoiLabel.vue?vue&type=style&index=0&id=d3c9efe8&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d3c9efe8",
  null
  
)

export default component.exports