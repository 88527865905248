
import { computed, defineComponent, PropType, ref, watch, onMounted } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { uuid } from '@svoi-ui/shared/utils/helpers'
import { isRefObject } from '@svoi-ui/shared/utils/guards'
import { BaseFilePickerComponent, useBaseFilePicker } from './BaseFilePicker'

export default defineComponent({
  name: 'SvoiFilePicker',
  components: { SvoiIcon },
  props: {
    ...BaseFilePickerComponent.props,
    value: {
      type: Array as PropType<Array<File>>,
      default: [] as Array<File>
    }
  },
  emits: ['input'],
  setup(props, { emit }) {
    const { syncElementFiles, checkFiles } = useBaseFilePicker(props)

    const error = ref('')
    const complexError = computed(() => `${props.error} ${error.value}`)
    const isActive = ref(false)

    const id = ref('')
    onMounted(() => (id.value = uuid()))

    const label = computed(() => {
      return props.multiple ? 'файлы или перетащите их сюда' : 'файл или перетащите его сюда'
    })

    const classes = computed(() => ({
      '-active': isRefObject(isActive) && isActive.value,
      '-disabled': props.disabled,
      '-error': isRefObject(complexError) && complexError.value.trim().length > 0
    }))

    const cancelHandlers = (e: Event) => {
      e.preventDefault()
      e.stopPropagation()
    }

    const setActive = (e: DragEvent, value: boolean) => {
      if (!props.disabled) {
        isActive.value = value
        cancelHandlers(e)
      }
    }

    const fileDropped = (e: DragEvent) => {
      if (props.disabled) {
        return
      }

      setActive(e, false)
      error.value = ''

      let files = Array.from(e.dataTransfer?.files ?? [])

      if (!props.multiple && files.length > 1) {
        error.value = 'Множественная загрузка файлов не поддерживается'
        files = []
      }

      if (files.length > props.maxCount) {
        error.value = `Максимальное количество загруженных файлов не более - ${props.maxCount}`
        files = []
      }

      const checkResult = checkFiles(files)
      if (checkResult !== true) {
        error.value = checkResult
        files = []
      }

      emit('input', files)
    }

    const fileAdded = (e: Event) => {
      error.value = ''
      let files = Array.from((e.target as HTMLInputElement).files ?? [])

      if (files.length > props.maxCount) {
        error.value = `Максимальное количество загруженных файлов не более - ${props.maxCount}`
        files = []
      }

      const checkResult = checkFiles(files)
      if (checkResult !== true) {
        error.value = checkResult
        files = []
      }

      emit('input', files)
    }

    watch(
      () => props.value,
      () => syncElementFiles(id.value)
    )

    return {
      id,
      complexError,
      isActive,
      label,
      classes,
      fileAdded,
      setActive,
      fileDropped
    }
  }
})
