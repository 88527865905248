import {
  AuthForm,
  DocumentsForm,
  ProfileFullForm,
  ProfileShortForm,
  RegisterForm,
  RegisterTeacherForm,
  UpdatePasswordForm,
  ClassJoinRequestForm,
} from '.'
import { ApolloService, toModel, toModels, unwrapResponse } from '~/shared/api'
import { MediaModel } from '~/shared/model'
import { Me, SocialRedirect, Statistic } from '~/entities/user/gql/query'
import {
  CheckUserExists,
  EditFullProfile,
  EditShortProfile,
  GenerateAgreementFile,
  Login,
  RegisterStudent,
  RegisterTeacher,
  SocialLogin,
  UpdatePassword,
  UploadAgreementsDocuments,
  UploadPhoto,
  CreateClassJoinRequest,
} from '~/entities/user/gql/mutation'
import {
  AuthorizedUserModel,
  DownloadFile,
  SocialRedirectModel,
  ApplicationModel,
  AuthModel,
} from '~/entities/user/model'
import { CheckUserExistsParams, GenerateAgreementForm, SocialAuthenticateInput } from '~/entities/user/interfaces'
import { isUnknownObject } from '~/shared/utils/guards'
import { SocialProviderEnum } from '~/entities/user/enums'
import { messages } from '~/shared/const'
import type { StatisticCounters } from '~/shared/interface'

export class UserService {
  private apolloService: ApolloService

  constructor(apolloService: ApolloService) {
    this.apolloService = apolloService
  }

  getAuthorizedUser(): Promise<AuthorizedUserModel> {
    return new Promise((resolve, reject) => {
      const token = this.apolloService.getToken()
      if (!token) {
        reject(new Error('Пользователь не определен'))
        return
      }

      this.apolloService
        .getQuery(Me)
        .then((data) => {
          if (isUnknownObject(data) && isUnknownObject(data.me)) {
            resolve(new AuthorizedUserModel(data.me))
          } else {
            reject(new Error('Пользователь не определен'))
          }
        })
        .catch((err) => reject(err))
    })
  }

  editProfile(form: ProfileShortForm | ProfileFullForm): Promise<AuthorizedUserModel> {
    const mutation = form instanceof ProfileShortForm ? EditShortProfile : EditFullProfile
    return this.apolloService
      .executeMutation(mutation, { input: form.getInputData() })
      .then(toModel(AuthorizedUserModel, 'Не удалось получить данные пользователя'))
  }

  uploadStudentDocuments(form: DocumentsForm, userId?: string): Promise<Array<MediaModel>> {
    const input = form.getInputData()
    if (userId) {
      input.studentId = userId
    }

    return this.apolloService.executeMutation(UploadAgreementsDocuments, input).then(toModels(MediaModel))
  }

  generateAgreementFile(form: GenerateAgreementForm): Promise<Array<DownloadFile>> {
    return this.apolloService
      .executeMutation(GenerateAgreementFile, { agreementGenerateDataInput: form })
      .then(({ data }) => {
        if (isUnknownObject(data) && Array.isArray(data.generateAgreementFile)) {
          return data.generateAgreementFile.map((f) => new DownloadFile(f))
        }
        return []
      })
  }

  checkUserExists(params: CheckUserExistsParams): Promise<void> {
    return new Promise((resolve, reject) =>
      this.apolloService.getQuery(CheckUserExists, { input: params }).then((data) => {
        if (isUnknownObject(data)) {
          if (!data.checkUserExists) {
            resolve()
          } else {
            reject(new Error('User is duplicate'))
          }
        }

        reject(new Error('User is duplicate'))
      })
    )
  }

  authenticate(form: AuthForm): Promise<string> {
    return this.apolloService
      .executeMutation(Login, { input: form.getInputData() })
      .then(toModel(AuthModel, messages.errors.authorization))
      .then((data) => {
        if (data.token) {
          return data.token
        }

        throw new Error(messages.errors.authorization)
      })
  }

  register(form: RegisterForm | RegisterTeacherForm): Promise<string> {
    // instanceof и constructor.name не работают
    const mutation = form.constructorName === 'RegisterTeacherForm' ? RegisterTeacher : RegisterStudent
    return this.apolloService
      .executeMutation(mutation, { input: form.getInputData() })
      .then(toModel(AuthModel, messages.errors.registration))
      .then((data) => {
        if (data.token) {
          return data.token
        }

        throw new Error(messages.errors.registration)
      })
  }

  getSocialRedirect(): Promise<Array<SocialRedirectModel>> {
    return this.apolloService.getQuery(SocialRedirect).then(toModels(SocialRedirectModel))
  }

  socialAuthenticate(input: SocialAuthenticateInput): Promise<string> {
    return this.apolloService
      .executeMutation(SocialLogin, { input })
      .then(toModel(AuthModel, messages.errors.VkAuthenticate))
      .then((data) => {
        if (data.token) {
          return data.token
        }

        throw new Error(messages.errors.VkAuthenticate)
      })
  }

  VkSocialAuthenticate(code: string) {
    return this.socialAuthenticate({ code, provider: SocialProviderEnum.vk })
  }

  updatePassword(form: UpdatePasswordForm): Promise<void> {
    return this.apolloService.executeMutation(UpdatePassword, { input: form.getInputData() }).then(({ data }) => {
      if (
        isUnknownObject(data) &&
        isUnknownObject(data.updatePassword) &&
        data.updatePassword.status === 'PASSWORD_UPDATED'
      ) {
        return undefined
      }

      throw new Error('Произошла ошибка при изменении пароля.')
    })
  }

  uploadPhoto(image: File): Promise<MediaModel> {
    return this.apolloService.executeMutation(UploadPhoto, { image }).then(toModel(MediaModel, 'Ошибка загрузки файла'))
  }

  createApplication(form: ClassJoinRequestForm): Promise<ApplicationModel> {
    return this.apolloService
      .executeMutation(CreateClassJoinRequest, { input: form.getInputData() })
      .then(toModel(ApplicationModel, messages.errors.create))
  }

  getStatistic(counters: Iterable<StatisticCounters>): Promise<Record<string, number>> {
    return this.apolloService.getQuery(Statistic(counters)).then((data) => {
      const values: Record<string, number> = {}
      if (isUnknownObject(data)) {
        Object.keys(data).forEach((key) => {
          const unwrapData = unwrapResponse(data[key])
          if (isUnknownObject(unwrapData) && typeof unwrapData.count === 'number') {
            values[key] = unwrapData.count
          }
        })
      }

      return values
    })
  }
}
