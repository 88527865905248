import { requiredRule, emailRule } from '~/shared/utils/validation'

export default class AuthForm {
  email: string = ''
  password: string = ''
  _invalid: boolean = true

  getInputData() {
    return {
      email: this.email.toLowerCase(),
      password: this.password,
    }
  }

  static rules() {
    return {
      email: { ...requiredRule, ...emailRule },
      password: { ...requiredRule },
    }
  }
}
