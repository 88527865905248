import { ApolloService, toModels } from '~/shared/api'
import { Categories } from '~/entities/category/gql/query'
import { CategoryModel } from '~/entities/category/model'

export default class CategoryService {
  private apolloService: ApolloService

  constructor(apolloService: ApolloService) {
    this.apolloService = apolloService
  }

  getCategories(): Promise<Array<CategoryModel>> {
    return this.apolloService.getQuery(Categories).then(toModels(CategoryModel))
  }
}
