export const useEventObserver = <F extends (...args: any) => void>() => {
  const observers: Set<F> = new Set()

  const subscribe = (func: F) => {
    observers.add(func)
  }

  const unsubscribe = (func: F) => {
    observers.delete(func)
  }

  const broadcast = (...args: Parameters<F>) => {
    observers.forEach((f) => {
      f.apply(null, args)
    })
  }

  return { broadcast, subscribe, unsubscribe }
}
