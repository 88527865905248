// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { AuthorizedUserModel } from '~/entities/user/model'

@Module({ namespaced: true, stateFactory: true, name: 'user' })
export default class extends VuexModule {
  private _authorizedUser: AuthorizedUserModel | null = null

  @Mutation
  private mutationSetAuthorizedUser(user: AuthorizedUserModel): void {
    this._authorizedUser = user
  }

  @Mutation
  private mutationClearUser(): void {
    this._authorizedUser = null
  }

  @Action
  public setAuthorizedUser(user: AuthorizedUserModel): void {
    this.context.commit('mutationSetAuthorizedUser', user)
  }

  @Action
  public clearUser(): void {
    this.context.commit('mutationClearUser')
  }

  get authorizedUser(): AuthorizedUserModel | null {
    return this._authorizedUser
  }

  get userFullName(): string {
    if (!this._authorizedUser) return ''

    return `${this._authorizedUser.profile.surName} ${this._authorizedUser.profile.name} ${this._authorizedUser.profile.middleName}`
  }

  get userPhoto(): string {
    if (!this._authorizedUser) return ''

    return this._authorizedUser.profile.photo.path
  }
}
