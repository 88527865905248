import { BaseModel } from '../../../shared/model'
import { fillOwnProperties } from '~/shared/utils/helpers'
import { isUnknownObject } from '~/shared/utils/guards'
import { UserModel } from '~/entities/user/model'

export default class Comment extends BaseModel {
  id: number = 0
  author: UserModel | null = null
  createdAt: string = ''
  message: string = ''

  constructor(data: unknown) {
    super()
    if (isUnknownObject(data)) {
      fillOwnProperties(this, data, ['author'])

      if (isUnknownObject(data.author)) {
        this.author = new UserModel(data.author)
      }
    }
  }
}
