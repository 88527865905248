export enum PositionEnum {
  top = 'TOP',
  bottomLeft = 'BOTTOM_LEFT',
  bottomCenter = 'BOTTOM_CENTER',
  bottomRight = 'BOTTOM_RIGHT',
}

export const isPosition = (value: unknown): value is PositionEnum => {
  return (
    typeof value === 'string' &&
    [
      PositionEnum.top.toString(),
      PositionEnum.bottomCenter.toString(),
      PositionEnum.bottomLeft.toString(),
      PositionEnum.bottomRight.toString(),
    ].includes(value)
  )
}
