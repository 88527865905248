
import { defineComponent, PropType, computed } from '@nuxtjs/composition-api'
import { SizesEnum, Sizes } from '@svoi-ui/interfaces/loader'

export default defineComponent({
  name: 'SvoiLoader',
  props: {
    label: {
      type: String,
      default: 'Загрузка...'
    },
    size: {
      type: String as PropType<Sizes>,
      default: SizesEnum.m,
      validator: (value: unknown) => {
        return (
          typeof value === 'string' &&
          [
            SizesEnum.xs.toString(),
            SizesEnum.s.toString(),
            SizesEnum.m.toString(),
            SizesEnum.l.toString(),
            SizesEnum.xl.toString(),
            SizesEnum.xxl.toString()
          ].includes(value)
        )
      }
    }
  },
  setup(props) {
    const classes = computed(() => ({
      [`-size-${props.size}`]: true
    }))

    return { classes }
  }
})
