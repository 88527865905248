import type { BreadcrumbItem as BreadcrumbItemKit } from '@svoi-ui/interfaces/breadcrumbs'
import { isString } from '~/shared/utils/guards'
import { singleton } from '~/shared/utils/decorators' // eslint-disable-line @typescript-eslint/no-unused-vars

type BreadcrumbItem = { key?: string | number } & BreadcrumbItemKit

@singleton
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class BreadcrumbsState {
  visible = false
  private _changed = false
  private _items: Array<BreadcrumbItem> = []

  constructor() {
    this.init()
  }

  static getInstance() {
    return new BreadcrumbsState()
  }

  private init() {
    this.visible = false
    this._changed = false
    this._items = [
      {
        title: 'Главная',
        link: '/',
      },
    ]
  }

  get changed() {
    return this._changed
  }

  get items() {
    return this._items.filter((i) => i.title)
  }

  show(): BreadcrumbsState {
    this.visible = true
    return this
  }

  reset(): BreadcrumbsState {
    this.init()
    return this
  }

  add(value: string | BreadcrumbItem): BreadcrumbsState {
    this._changed = true

    this._items.push(isString(value) ? { title: value } : value)
    return this
  }

  update(key: BreadcrumbItem['key'], value: BreadcrumbItem['title'], link?: BreadcrumbItem['link']): BreadcrumbsState {
    const find = this._items.find((i) => i.key === key)
    if (find) {
      find.title = value
      find.link = link
    }
    return this
  }
}
