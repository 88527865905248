
import { computed, defineComponent, onUnmounted, PropType, watchEffect } from '@nuxtjs/composition-api'
import { SvoiIcon } from '@svoi-ui/components/icon'

export default defineComponent({
  name: 'Modal',
  components: { SvoiIcon },
  props: {
    isShow: {
      default: false,
      type: Boolean,
    },
    size: {
      type: String as PropType<'medium'>,
      default: 'medium',
      validator: (val: string) => {
        return ['medium'].includes(val)
      },
    },
  },
  emits: ['onClose', 'on-close'],
  setup(props, { emit }) {
    const type = computed(() => {
      if (process.browser) {
        return window.innerWidth < 769 ? 'curtain' : 'modal'
      }
    })

    const toggleScroll = (value: 'hidden' | 'auto') => {
      if (process.browser) {
        window.document.body.style.overflow = value
      }
    }

    watchEffect(() => {
      if (props.isShow) {
        toggleScroll('hidden')
      } else {
        toggleScroll('auto')
      }
    })

    const classes = computed(() => ({
      [`-type-${type.value}`]: true,
      [`-size-${props.size}`]: true,
    }))

    const isModal = computed(() => type.value === 'modal')

    const onClose = () => {
      emit('onClose', false)
      emit('on-close', false)

      toggleScroll('auto')
    }

    onUnmounted(() => {
      toggleScroll('auto')
    })

    return { classes, isModal, onClose }
  },
})
