
import { defineComponent, PropType, computed } from '@nuxtjs/composition-api'
import { SizesEnum, Sizes } from '@svoi-ui/interfaces/icon'

export default defineComponent({
  name: 'SvoiIcon',
  props: {
    icon: {
      type: String,
      required: true,
      default: ''
    },
    size: {
      type: String as PropType<Sizes>,
      default: SizesEnum.m,
      validator(value: string) {
        return [
          SizesEnum.xs.toString(),
          SizesEnum.s.toString(),
          SizesEnum.m.toString(),
          SizesEnum.l.toString(),
          SizesEnum.full.toString()
        ].includes(value)
      }
    }
  },
  emits: ['click'],
  setup(props) {
    const iconData = computed(() => {
      const data = { sprite: '', icon: '' }
      const chunks = (props.icon as string).split('/')

      if (chunks.length === 2) {
        data.sprite = require(`@svoi-ui/assets/icons/${chunks[0]}.svg`)
        data.icon = chunks[1]
      }

      return data
    })

    return { iconData }
  }
})
