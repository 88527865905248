import { gql } from 'graphql-tag'
import { RatingEntityEnum } from '~/entities/rating/enums'

export default (entity: RatingEntityEnum) => {
  const on = `${entity.at(0) + entity.slice(1).toLowerCase()}Type`
  return gql`
    mutation unRateEntity($input: UnRateEntityInput!) {
      unRateEntity(input: $input) {
        ... on ${on} {
          rating
        }
      }
    }
  `
}
