import { email, helpers, minLength, required, maxLength } from '@vuelidate/validators'
import { validationMessages } from '~/shared/const'
import type { JSONContent } from '~/interfaces/editor'
import { replaceParams } from '~/shared/utils/helpers'

export { helpers } from '@vuelidate/validators'

export const requiredRule = {
  required: helpers.withMessage(validationMessages.required, required),
}

export const requiredContentEditor = {
  required: helpers.withMessage(
    validationMessages.required,
    (value: JSONContent) => !!value?.content?.find((el) => el?.content)
  ),
}

export const onlyCyrillicRule = {
  onlyCyrillic: helpers.withMessage(validationMessages.onlyCyrillic, helpers.regex(/^[а-яА-ЯёЁ]+$/)),
}

export const emailRule = {
  email: helpers.withMessage(validationMessages.email, email),
}

export const phoneRule = {
  phone: helpers.withMessage(validationMessages.phone, helpers.regex(/^\d \(\d{3}\) \d{3}-\d{2}-\d{2}$/)),
}

export const passwordRule = {
  password: helpers.withMessage(validationMessages.password, minLength(8)),
}

export const checkedRule = {
  required: helpers.withMessage(validationMessages.required, (value: unknown) => value === true),
}

export const passportSeriesRule = {
  phone: helpers.withMessage(validationMessages.passportSeries, helpers.regex(/^\d{4}$/)),
}

export const passportNumberRule = {
  phone: helpers.withMessage(validationMessages.passportNumber, helpers.regex(/^\d{6}$/)),
}

export const passportDepartmentRule = {
  phone: helpers.withMessage(validationMessages.passportDepartment, helpers.regex(/^\d{3}-\d{3}$/)),
}

export const websiteRule = {
  website: helpers.withMessage(
    validationMessages.websiteUrl,
    helpers.regex(
      /^http(s)?:\/\/(www\.)?(([-a-zA-Z0-9@:%_+.~#?&/=]{2,256}\.[-a-z0-9]{2,})|([-0-9а-яА-Я@:%_+.~#?&/=]{2,256}\.[а-я0-9]{2,}))(\/[-a-zA-Z0-9@:%_+.~#?&/=]*)?$/gi
    )
  ),
}

export function sameAsRule<T extends object>(target: T, field: keyof T, message: string) {
  return {
    sameAs: helpers.withMessage(message, (value: unknown) => target[field] === value),
  }
}

export function requiredIfRule<T extends object>(target: T, field: keyof T) {
  return {
    requiredIf: helpers.withMessage(validationMessages.required, (value: unknown) => {
      if (!target[field]) {
        return true
      }

      if (typeof value === 'string') {
        value = value.trim()
      }

      return Array.isArray(value) ? value.length > 0 : !!value
    }),
  }
}

export function maxLengthListRule(max: number) {
  return {
    maxLength: helpers.withMessage(replaceParams(validationMessages.maxLengthList, { max }), maxLength(max)),
  }
}

export const emptyRule = {
  emptyRule: helpers.withMessage('', () => true),
}
