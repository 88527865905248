import { gql } from 'graphql-tag'
import { SubjectMainFields } from '~/entities/subject/gql/fragments'

export default gql`
  ${SubjectMainFields('LearningKnowledgeBaseActiveSubjectType')}
  query getReferenceSubjects($first: Int = 9999) {
    learningKnowledgeBaseActiveSubjects(first: $first, sort: { column: TITLE, order: ASC }) {
      data {
        ...SubjectMainFields
        educationalProgram {
          id
          value
        }
      }
    }
  }
`
