import { gql } from 'graphql-tag'

export default gql`
  mutation generateAgreementFile($agreementGenerateDataInput: agreementGenerateDataInput!) {
    generateAgreementFile(input: $agreementGenerateDataInput) {
      content
      filename
      mimetype
    }
  }
`
