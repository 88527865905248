import type { Notification as AbstractNotification } from '@svoi-ui/interfaces/notification'
import { uuid } from '~/shared/utils/helpers'

export default class Notification implements AbstractNotification {
  id: string
  title: string
  text?: string
  componentContent?: AbstractNotification['componentContent']
  type?: AbstractNotification['type']
  collapse?: boolean
  timeout?: number
  buttons?: AbstractNotification['buttons']

  constructor(params: Omit<AbstractNotification, 'id'>) {
    this.id = uuid()
    this.title = params.title
    this.text = params.text
    this.type = params.type
    this.collapse = params.collapse
    this.buttons = params.buttons
    this.componentContent = params.componentContent
    this.timeout = 'timeout' in params ? params.timeout : 5000
  }
}
