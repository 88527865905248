import { gql } from 'graphql-tag'
import { LessonMainFields } from '~/entities/lesson/gql/fragments'

export default gql`
  ${LessonMainFields('LearningProgramLessonType')}
  mutation createLessonByTemplate($input: CreateLearningProgramLessonByTemplateInput!) {
    createLearningProgramLessonByTemplate(input: $input) {
      ...LessonMainFields
    }
  }
`
