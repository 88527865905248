import { RegisterTeacherClassNameForm } from './'
import {
  checkedRule,
  emailRule,
  emptyRule,
  onlyCyrillicRule,
  passwordRule,
  phoneRule,
  requiredRule,
  sameAsRule,
  websiteRule,
} from '~/shared/utils/validation'
import { singleton } from '~/shared/utils/decorators' // eslint-disable-line @typescript-eslint/no-unused-vars
import { CompanyModel } from '~/entities/dadata/model'
import type { ProfileSubjectModel } from '~/entities/user/model'
import type { RegisterTeacherInput } from '~/entities/user/interfaces'
import { ClassLitterValue } from '~/entities/class/enums'

@singleton
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default class RegisterTeacherForm {
  readonly constructorName = 'RegisterTeacherForm'
  name: string = ''
  surname: string = ''
  middleName: string = ''
  birthDate: string = ''
  email: string = ''
  password: string = ''
  passwordConfirmation: string = ''
  phone: string = ''
  schoolCompany: CompanyModel | null = null
  schoolWebSait: string = ''
  schoolId: string = ''
  profileSubjects: Array<ProfileSubjectModel> = []
  experience: string = ''
  classes: Array<RegisterTeacherClassNameForm> = [new RegisterTeacherClassNameForm()]
  isConfirm: boolean = false

  getInputData(): RegisterTeacherInput {
    const institution: RegisterTeacherInput['institution'] = { link: this.schoolWebSait }
    if (this.schoolId) {
      institution.id = this.schoolId
    } else if (this.schoolCompany) {
      institution.organization = JSON.stringify(this.schoolCompany.dadata)
    }

    return {
      email: this.email.toLowerCase(),
      phone: this.phone,
      password: this.password,
      password_confirmation: this.passwordConfirmation,
      profile: {
        name: this.name,
        surName: this.surname,
        middleName: this.middleName,
        dateOfBirth: this.birthDate,
        experience: this.experience,
        classifiers: {
          profile_subjects: this.profileSubjects.map((i) => i.id),
        },
      },
      institution,
      classes: this.classes.map(({ litter, educationalProgram, name }) => ({
        postfix: litter ? litter.postfix : ClassLitterValue.emptyLitter,
        educationalProgramLevel: educationalProgram ? [educationalProgram.id] : [],
        name: name || '',
      })),
    }
  }

  static getInstance() {
    return new RegisterTeacherForm()
  }

  static rules() {
    const self = this.getInstance()
    return {
      email: { ...requiredRule, ...emailRule },
      password: { ...requiredRule, ...passwordRule },
      passwordConfirmation: { ...requiredRule, ...sameAsRule(self, 'password', 'Пароли не совпадают') },
      name: { ...requiredRule, ...onlyCyrillicRule },
      surname: { ...requiredRule, ...onlyCyrillicRule },
      middleName: onlyCyrillicRule,
      birthDate: requiredRule,
      phone: { ...requiredRule, ...phoneRule },
      schoolCompany: requiredRule,
      schoolWebSait: { ...requiredRule, ...websiteRule },
      schoolId: { ...emptyRule },
      profileSubjects: { ...emptyRule },
      experience: { ...emptyRule },
      isConfirm: checkedRule,
      classes: requiredRule,
    }
  }

  errorFieldsMap(): Map<string, keyof typeof RegisterTeacherForm.prototype> {
    return new Map(
      Object.entries({
        email: 'email',
        phone: 'phone',
        password: 'password',
        password_confirmation: 'passwordConfirmation',
        'institution.organization': 'schoolCompany',
        'institution.link': 'schoolWebSait',
        'profile.name': 'name',
        'profile.surName': 'surname',
        'profile.middleName': 'middleName',
        'profile.dateOfBirth': 'birthDate',
        'profile.experience': 'experience',
        'profile.classifiers.profile_subjects': 'profileSubjects',
      })
    )
  }
}
