// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/fonts/Golos-Regular.woff2");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../../assets/fonts/Golos-Regular.woff");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../../assets/fonts/Golos-Regular.ttf");
var ___CSS_LOADER_URL_IMPORT_3___ = require("../../../assets/fonts/Golos-Medium.woff2");
var ___CSS_LOADER_URL_IMPORT_4___ = require("../../../assets/fonts/Golos-Medium.woff");
var ___CSS_LOADER_URL_IMPORT_5___ = require("../../../assets/fonts/Golos-Medium.ttf");
var ___CSS_LOADER_URL_IMPORT_6___ = require("../../../assets/fonts/Golos-Bold.woff2");
var ___CSS_LOADER_URL_IMPORT_7___ = require("../../../assets/fonts/Golos-Bold.woff");
var ___CSS_LOADER_URL_IMPORT_8___ = require("../../../assets/fonts/Golos-Bold.ttf");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
var ___CSS_LOADER_URL_REPLACEMENT_8___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_8___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{font-family:\"Golos\";font-display:swap;src:local(\"Golos Regular\"),local(\"Golos-Regular\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ") format(\"truetype\");font-weight:400;font-style:normal}@font-face{font-family:\"Golos\";font-display:swap;src:local(\"Golos Medium\"),local(\"Golos-Medium\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ") format(\"truetype\");font-weight:500;font-style:normal}@font-face{font-family:\"Golos\";font-display:swap;src:local(\"Golos Bold\"),local(\"Golos-Bold\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_6___ + ") format(\"woff2\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_7___ + ") format(\"woff\"),url(" + ___CSS_LOADER_URL_REPLACEMENT_8___ + ") format(\"truetype\");font-weight:700;font-style:normal}*{box-sizing:border-box}*,body{font-family:\"Golos\",sans-serif}body{font-size:16px;line-height:20px;background:#f5f7f9}a,body{color:#101e2c}a{text-decoration:none;transition:all .3s ease}.page{flex-grow:1}.base-dropdown{position:relative;height:100%;background:transparent}.base-dropdown,.base-dropdown>.content{width:-moz-fit-content;width:fit-content}.base-dropdown>.content{min-width:100%;position:absolute;background:#fff;border-radius:8px;box-shadow:0 4px 20px 0 rgba(0,0,0,.08);overflow:hidden;z-index:999}.base-dropdown>.activator{height:100%}.base-dropdown>.content_right{left:100%;right:auto}.base-dropdown>.content_left{right:100%;left:auto}.base-dropdown>.content_center{right:auto;left:auto}.base-dropdown>.content_top{bottom:calc(100% + 4px);top:auto}.base-dropdown>.content_bottom{top:calc(100% + 4px);bottom:auto}.v-enter-active,.v-leave-active{transition:opacity var(--6d58b5bc)}.v-enter,.v-leave-to{opacity:0}", ""]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___;
