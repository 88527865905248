import { gql } from 'graphql-tag'

export default gql`
  query GetSchoolsInstitutions($first: Int = 9999, $filter: SchoolsInstitutionsFilterInput) {
    schoolsInstitutions(filter: $filter, first: $first) {
      data {
        id
        organization {
          name {
            short
            full
          }
        }
        classes {
          name
          id
        }
      }
    }
  }
`
