
import { defineComponent } from '@nuxtjs/composition-api'
import { SvoiHint } from '@svoi-ui/components/hint'
import { SvoiIcon } from '@svoi-ui/components/icon'
import { BaseInputComponent } from './BaseInput'

export default defineComponent({
  name: 'SvoiInput',
  components: { SvoiHint, SvoiIcon },
  extends: BaseInputComponent
})
